import React from "react";
import { Helmet } from "react-helmet";

function SEOComponnet(url) {
  const defaultTitle = "Talent Pool Academy";
  const defaultDescription =
    "Join our expert-led training programs in CAD automation, AI/ML, Full Stack Development, Computer Vision, Large Language Models, Data Science, and Generative AI. Specializing in CATIA, NX, CREO, Autodesk, Solid Edge, Windchill, Teamcenter, 3DEXPERIENCE, Enovia, TensorFlow, PyTorch, and modern web technologies, we offer comprehensive courses to enhance your engineering and technological skills, productivity, and innovation.";
  const defaultKeywords = `
    Best CAD and PLM Training Institute,
    Best CAD and PLM Training Institute online,
    Best CAD and PLM Training Institute Offline,
    Best CAD Automation and Customization Training Institute,
    Best AI/ML Training Institute,
    Best Full Stack Development Training,
    Best Computer Vision Training,
    Best Large Language Model Training,
    Best Data Science Training,
    Best Generative AI Training,
    CAD Customization Courses,
    CAD Design Training,
    CAD Automation Course,
    AI/ML Courses,
    Computer Vision Courses,
    Large Language Models Training,
    Data Science Programs,
    Generative AI Classes,
    Full Stack Development Bootcamp,
    Web Development Training,
    CAE Training,
    CAE Analysis Training,
    CATIA Design Basic Training,
    CATIA Advanced Basic Training,
    3DEXPERIENCE Training,
    Interior and Exterior Plastic Trims,
    BIW Design Training,
    Sheetmetal Design Training,
    CATIA Design Basic Training,
    CATIA Advanced Basic Training,
    CREO Design Training,
    SolidWorks Design Training,
    Solid Edge Design Training,
    AutoCAD Design Training,
    Autodesk CAD Training,
    Advanced CAD Training,
    CATIA Customization Training,
    CATIA Automation Training,
    EKL Training,
    3DEXPERIENCE Customization Training,
    NX CAD Customization Training,
    CREO Automation Classes,
    Solid Edge Customization Training,
    PLM Training,
    Windchill PLM Courses Training,
    Teamcenter Customization Training,
    3DEXPERIENCE Platform Training,
    Enovia Customization Courses,
    TensorFlow Training,
    PyTorch Training,
    Natural Language Processing Courses,
    Deep Learning Training,
    Professional CAD Solutions,
    CAD System Integration,
    Full Stack AI Development,
    Practical AI/ML Projects,
    Practical CAD Projects,
    Practical Web Development Projects,
    CAD Design Internship,
    AI/ML Internship,
    Corporate CAD Training,
    Corporate AI/ML Training,
    Corporate Full Stack Training,
    Individual CAD Training,
    Individual AI/ML Training,
    Individual Full Stack Training,
    Online CAD Courses,
    Online AI/ML Courses,
    Online Full Stack Courses,
    Offline CAD Classes,
    Offline AI/ML Classes,
    Offline Full Stack Classes,
    CAD Tools Development,
    AI/ML Tools Development,
    Full Stack Tools Development,
    CAD Software Development,
    AI Software Development,
    Machine Learning in CAD,
    AI/ML in Engineering,
    Full Stack Engineering,
    20+ Years CAD Experience,
    Advanced AI/ML Experience,
    Industry-leading Full Stack Development
`;
  const defaultUrl = "https://talentpool-academy.in/";

  return (
    <Helmet>
      <title>{defaultTitle}</title>
      <meta name="description" content={defaultDescription} />
      <meta name="keywords" content={defaultKeywords} />
      <link rel="canonical" href={url || defaultUrl} />
      <script type="application/ld+json">
        {`
                {
                    "@context": "https://schema.org",
                    "@type": [
                        "EducationalOrganization",
                        "TrainingCenter",
                        "CADTrainingCenter",
                        "PLMTrainingCenter",
                        "EngineeringTrainingHub"
                    ],
                    "name": "Best CAD Training Institute",
                    "alternateName": [
                        "PLM Training Center",
                        "CAD Automation Academy",
                        "Engineering Training Hub",
                        "Best CAD and PLM Training Institute",
                        "Best CAD and PLM Training Institute online",
                        "Best CAD and PLM Training Institute Offline",
                        "Best CAD Automation and Customization Training Institute",
                        "CAD Customization Courses",
                        "CAD Design Training",
                        "CAD Automation Course",
                        "CAE Training",
                        "CAE Analysis Training",
                        "CATIA Design Basic Training",
                        "CATIA Advanced Basic Training",
                        "3DExperince Training",
                        "Interior and Exterior Plastic Trims",
                        "BIW Design Training",
                        "Sheetmetal Design Training",
                        "CREO Design Training",
                        "SOLD Works Design Training",
                        "Solid Edge Design Training",
                        "Auto CAD Design Training",
                        "Autodesk CAD Training",
                        "Advanced CAD Training",
                        "CATIA Customization Training",
                        "CATIA Automation Training",
                        "EKL Training",
                        "3DExperince Customization Training",
                        "NX CAD Customization Training",
                        "CREO Automation Classes",
                        "Solid Edge Customization Training",
                        "PLM Training",
                        "Windchill PLM Courses Training",
                        "Teamcenter Customization Training",
                        "3DEXPERIENCE Platform Training",
                        "Enovia Customization Courses",
                        "Professional CAD Solutions",
                        "CAD System Integration",
                        "CAD Design Internship",
                        "Corporate CAD Training",
                        "Individual CAD Training",
                        "Practical CAD Projects",
                        "Online CAD Courses",
                        "Offline CAD Classes",
                        "CAD Tools Development",
                        "Full Stack CAD Development",
                        "Machine Learning in CAD",
                        "20+ Years CAD Experience"
                    ],
                    "url": "${url || defaultUrl}",
                    "logo": "https://talentpool-academy.in//TalentPoolAcademy_logo.png",
                    "sameAs": [
                        "https://www.facebook.com/your-profile",
                        "https://www.linkedin.com/in/talent-pool-academy"
                    ]
                }
                `}
      </script>
    </Helmet>
  );
}

export default SEOComponnet;
