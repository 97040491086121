import React from "react";
import {
  Autocomplete,
  TextField,
  Modal,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";

function InterviewQuestionFilterModal({
  open,
  handleClose,
  handleClickFilter,
  handleDesignationChange,
  designations,
  skills,
  companies,
  handleSkillsChange,
  selectedDesignation,
  selectedCompany,
  handleCompanyChange,
  selectedSkills,
  sortOrder,
  handleSortOrderChange,
}) {
  return (
    <div>
      {/* ______________________Modal_____________________________ */}
      <Modal
        open={open}
        close={handleClose}
        className="modal-outer-border-remove"
      >
        <div className="modalContent-all-course">
          <div className="modal-border-remove-conatiner">
            <div className="modalHeader-all-course">
              <div
                className="clear-filter-section-more-review-button1"
                onClick={handleClickFilter}
              >
                {/* _______________header__________________________ */}
                <FilterListIcon className="buttonicons-reset-filter1" />
                &nbsp;Reset Filters
              </div>
              <div>
                <Tooltip title="Close" arrow>
                  <CloseIcon
                    className="hamburg-closeIcon"
                    onClick={handleClose}
                  />
                </Tooltip>
              </div>
            </div>

            <div className="interview-question-section-filter-section-modal-filter">
              {/* Designation Filter */}
              <div className="all-coures-sub-heading-section">Designation</div>
              <div className="all-course-section-filter-options">
                <Autocomplete
                  options={designations}
                  value={selectedDesignation}
                  onChange={handleDesignationChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Designation" />
                  )}
                />
              </div>

              {/* Company Filter */}
              <div className="all-coures-sub-heading-section">Company</div>
              <div className="all-course-section-filter-options">
                <Autocomplete
                  options={companies}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Company" />
                  )}
                />
              </div>

              {/* Skills Filter */}
              <div className="all-coures-sub-heading-section">Skills</div>
              <div className="all-course-section-filter-options">
                <Autocomplete
                  multiple
                  options={skills}
                  value={selectedSkills}
                  onChange={handleSkillsChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Skills" />
                  )}
                />
              </div>

              {/* Sort Order Filter */}
              <div className="all-coures-sub-heading-section">Posted on</div>
              <div className="all-course-section-filter-options">
                <FormControl fullWidth>
                  <Select value={sortOrder} onChange={handleSortOrderChange}>
                    <MenuItem value="latest">Latest to Oldest</MenuItem>
                    <MenuItem value="oldest">Oldest to Latest</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InterviewQuestionFilterModal;
