import React,{useState} from "react";
import Fade from "react-reveal/Fade";
import professionalImage from "./CorporateImages/CorporateHero1.jpg";
import "./Corporate.css";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";

function CorporateHeroSection() {
  const [open,setOpen]=useState(false);
  //--------------------------------------------------------------------------------
  // Function to open the enquiry
  //--------------------------------------------------------------------------------
  const handleClick=()=>{
    setOpen(true)
  }
  return (
    <div className="hero_section_main">
      <div className="hero-container">
        <Fade left>
          <div className="hero-text">
            <h1 className="hero-title" >Corporate Training</h1>
            <p className="hero-subtitle">
              Our training programs go beyond just imparting knowledge; they
              inspire innovation, boost morale, and cultivate a culture of
              continuous learning. By investing in your team's development,
              you're not only enhancing their individual capabilities but also
              empowering your entire organization to achieve greater success.
            </p>
            <button className="corporate-button" onClick={handleClick}>Enquire now</button>
          </div>
        </Fade>
        <Fade right>
          <div className="hero-image">
            <img src={professionalImage} alt="Corporate Training" />
          </div>
        </Fade>
      </div>
      {/* ______________________Enquiry form _______________ */}
      <EnquiryFormModal open={open} setOpen={setOpen} EnquiryHeading='Corporate Training'/>
      {/* ______________________end_________________________ */}
    </div>
  );
}

export default CorporateHeroSection;
