import React, { useRef, useEffect, useState } from "react";
import "./TeachUSonTPA.css";
import teach2 from "./TeachUSonTPA_Images/teach2.png";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";

function TOT_Component_4() {
  const imageRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [open,setOpen]=useState(false);
  //--------------------------------------------------------------------------------
  // Function to open the enquiry
  //--------------------------------------------------------------------------------
  const handleClick=()=>{
    setOpen(true)
  }

  //-------------------------------------------------------------------------
  //Image Animation
  //-------------------------------------------------------------------------

  useEffect(() => {
    const imageElement = imageRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          imageElement.classList.add("image-loading");
          setHasAnimated(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (imageElement) {
      observer.observe(imageElement);
    }

    return () => {
      if (imageElement) {
        observer.unobserve(imageElement);
      }
    };
  }, [hasAnimated]);

  return (
    /*________________________________________fourth_component_main_div_______________________________________*/
    <div className="component_4_outside_div">
      {/*________________________________________fourth_component_inside_div_______________________________________*/}
      <div className="component_4_inside_div">
        {/*________________________________________fourth_component_grid_div_______________________________________*/}
        <div className="component_4_grid_div">
          {/*________________________________________fourth_component_first_grid_div_______________________________________*/}
          <div className="component_4_first_grid">
            {/*________________________________________fourth_component_first_grid_heading________________________________________*/}
            <h2 className="component_4_first_grid_heading">Our Values</h2>
            {/*________________________________________fourth_component_first_grid_paragraph________________________________________*/}
            <p className="component_4_first_grid_paragraph">
              At TPA, we value dedication, creativity, and teamwork. We believe
              in fostering a culture of inclusivity, respect, and continuous
              learning, and we're committed to helping our educators grow both
              personally and professionally.
            </p>
            {/*________________________________________Apply Now button_______________________________________*/}
            <button className="apply_button" onClick={handleClick}>Apply Now</button>
          </div>
          {/*________________________________________fourth_component_card_div_______________________________________*/}
          <div className="component_4_cards">
            <div className="comp_4_img">
              <img
                ref={imageRef}
                className="glassImage image-loading"
                src={teach2}
              />
            </div>
          </div>
        </div>
        {/* __________________call the Modal______ */}
        <EnquiryFormModal open={open} setOpen={setOpen} EnquiryHeading={'Teach on TPA'}/>
      </div>
    </div>
  );
}

export default TOT_Component_4;
