import React from "react";
import "./headersmallbar.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";

function HeaderSmallBar() {
  return (
    <div className="header-small-top-bar-background-color">
      {/* _________________linked in section__________ */}
      <div className="header-small-top-bar-width-conatiner">
        <a href="https://www.linkedin.com/in/talent-pool-academy/">
          <LinkedInIcon className="social-media-icons-top-small-bar" />
        </a>
        {/* ______________instagram section__________________ */}
        <a href="https://www.instagram.com/talent_pool_academy09/">
          <InstagramIcon className="social-media-icons-top-small-bar" />
        </a>
        {/* ________________________Facebook section__________________ */}
        <a href="https://www.facebook.com/people/Tpa-Bang/pfbid05xx5juXrqtFZRBhCFW7Yi2yy8e1HBcmzHa1pVM3LGFt3FJKT4AP1KH8W5MoNjz9Zl/?mibextid=ZbWKwL%2F">
          <FacebookIcon className="social-media-icons-top-small-bar" />
        </a>
        {/* _____________________mailinfo section______________________ */}
        <div className="mail-info-container">
          <MailIcon className="social-media-icons-top-small-bar-mail" />
          <span className="mail-info-text">info@talentpool-academy.in</span>
        </div>
      </div>
    </div>
  );
}

export default HeaderSmallBar;
