import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InstructorCardTemplate from "../InstructorTemplate/InstructorCardTemplate";
import "./coursetemplate2.css";

const CourseTemplate2OInstructor = ({ selectedInstructors }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1100 },
      items: 1, // 1 card per slide
    },
    desktop: {
      breakpoint: { max: 1100, min: 860 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 860, min: 550 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {/* ________________instructor heading____________________ */}
      <div className="coursetemplete2-title-instructor">Instructor</div>
    
        {/* Carousel with one card per slide */}
        <Carousel
          showDots={true}
          renderDotsOutside={false}
          autoPlaySpeed={1500}
          loop={true}
          autoPlay={true}
          infinite={true}
          responsive={responsive}
        >
          {selectedInstructors &&
            selectedInstructors.map((instructor, index) => (
              <div key={index} className="home-course-select-card-click-render">
                <InstructorCardTemplate instructorData={instructor} />
              </div>
            ))}
        </Carousel>
    
    </>
  );
};

export default CourseTemplate2OInstructor;

