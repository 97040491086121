import React from "react";
import "./Footer.css";

function FooterComponent2() {
  return (
    /*_______________________________________Footer content 2nd component____________________________*/
    <div className="footer_content">
      {/*_______________________________________Footer content 2nd component heading____________________________*/}
      <div className="footer-1st-paragraph-component-header">
        <div className="footer-heading">Our Products</div>
      </div>
      {/*_______________________________________Footer content 2nd component list ____________________________*/}
      <div>
        <div className="footer-component2-grid-conatiner">
          <div className="footer-1st-paragraph-component">
            <a className="anchor-tag-border-remove" href="/home">
              Home
            </a>
          </div>
          <div className="footer-1st-paragraph-component">
            <a className="anchor-tag-border-remove" href="/internship">
              Internship
            </a>
          </div>
          <div className="footer-1st-paragraph-component">
            <a
              className="anchor-tag-border-remove"
              href="job_portal"
            >
              Job Portal
            </a>
          </div>
          <div className="footer-1st-paragraph-component">
            <a className="anchor-tag-border-remove" href="/Corporate">
              Corporate Training
            </a>
          </div>
          <div className="footer-1st-paragraph-component">
            <a className="anchor-tag-border-remove" href="/teachontpa">
              Teach on TPA
            </a>
          </div>
        </div>
        {/* _________________________________end__________________________________ */}
      </div>
    </div>
  );
}

export default FooterComponent2;
