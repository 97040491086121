// ResumeHeader.js
import React, { useState } from 'react';
import '../Resume.css';
import TPA from '../../Home/ComapyLogos/CompanyLogo/TPA.png'

const ResumeHeader = ({ name, title,showLogo }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (field) => {
    setIsEditing(field);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="resume-header-container">
      {/* _____________________Header Name_______________________ */}
      <div className="header-text">
      <div className="header-name editable"
        onClick={() => handleEditClick('name')}
        onBlur={handleBlur}
        contentEditable={isEditing === 'name'}
        suppressContentEditableWarning={true}>
        <h2>{name}</h2>
      </div>

      {/* _____________________Header Designation_______________________ */}
      <div className="header-title editable"
        onClick={() => handleEditClick('title')}
        onBlur={handleBlur}
        contentEditable={isEditing === 'title'}
        suppressContentEditableWarning={true}>
        <h3>{title}</h3>
      </div>
    </div>

     {/* _____________________Company Logo_______________________ */}
     {showLogo && (
        <div className="header-logo">
          <img src={TPA} alt="Company Logo" className="company-logo" />
        </div>
      )}
    </div>
  );
};

export default ResumeHeader;
