import React, { useState, useEffect } from "react";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import AboutCom1 from "./AboutCom1";
import Mission from "./Mission";
import AboutTPA from "./AboutTPA";
import BackToTop from "../BacktoTop/BackToTop";
import PageLoading from "../PageLoading/PageLoading";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";

function AboutMain() {
  const [isLoading, setIsLoading] = useState(true);

  //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <div>
          <HeaderSmallBar/>
          <HeaderMain />
          <AboutCom1 />
          <AboutTPA />
          <Mission />
          <FooterMain />
          <BackToTop/>
        </div>
      )}
    </div>
  );
}

export default AboutMain;
