import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import '../Resume.css';

const EducationSection = ({ education, cloneEducationItem, handleDeleteEducation }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (index) => {
    setIsEditing(index);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="sidebar-section">
      <h3 className="education-title">EDUCATION</h3>
      {education.map((edu, index) => (
        <div
          key={index}
          className="education-item editable"
          onClick={() => handleEditClick(index)}
          onBlur={handleBlur}
          contentEditable={isEditing === index ? "true" : "false"}
          suppressContentEditableWarning={true}
        >
          <p>{edu.year}</p>
          <p>{edu.institution}</p>
          <li>{edu.degree}</li>
          <AddCircleOutlineIcon
            className="addItem"
            onClick={() => cloneEducationItem(edu.id)}
          />
          <DeleteIcon
            className="deleteItem"
            onClick={() => handleDeleteEducation(edu.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default EducationSection;
