import React from "react";
import {
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./allcourse.css";

function AllCourseSectionModal({
  setSelectedDuration,
  selectedDuration,
  handlePriceChange,
  priceFilter,
  handleSubcategoryChange,
  FilterListIcon,
  selectedSubcategory,
  subcategories,
  categories,
  selectedCategory,
  handleCategoryChange,
  open,
  handleClose,
  handleClickFilter,
  durationOptions,
  ratingFilter,
  handleRatingChange,
}) {
  return (
    <div>
      {/* ______________________Modal_____________________________ */}
      <Modal
        open={open}
        close={handleClose}
        className="modal-outer-border-remove"
      >
        <div className="modalContent-all-course">
          <div className="modal-border-remove-conatiner">
            <div className="modalHeader-all-course">
              <div
                className="clear-filter-section-more-review-button1"
                onClick={handleClickFilter}
              >
                {/* _______________header__________________________ */}
                <FilterListIcon className="buttonicons-reset-filter1" />
                &nbsp;Reset Filters
              </div>
              <div>
                <Tooltip title="Close" arrow>
                  <CloseIcon
                    className="hamburg-closeIcon"
                    onClick={handleClose}
                  />
                </Tooltip>
              </div>
            </div>

            {/* <div className="all-coures-sub-heading-section">Sections</div>
    <div className="all-course-section-filter-options">
      <Autocomplete
        fullWidth
        options={sectionOptions}
        value={selectedSection}
        onChange={handleSectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Select Duration"
          />
        )}
      />

    </div> */}
            {/* _______________________category section____________________ */}
            <div className="course-fileter-sections-modal">
              <div className="all-coures-sub-heading-section">Category</div>
              <div className="all-course-section-filter-options">
                <Autocomplete
                  options={categories}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Select Category"
                    />
                  )}
                />
              </div>
              {/* _____________________subcategory section___________________ */}
              <div className="all-coures-sub-heading-section">Subcategory</div>
              <div className="all-course-section-filter-options">
                <Autocomplete
                  options={subcategories}
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Select SubCategory"
                    />
                  )}
                />
              </div>
              {/* ____________________price section_______________________ */}
              <div className="all-coures-sub-heading-section">Price</div>
              <div className="all-course-section-filter-options">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="free"
                      checked={priceFilter.free}
                      onChange={handlePriceChange}
                    />
                  }
                  label="Free"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="paid"
                      checked={priceFilter.paid}
                      onChange={handlePriceChange}
                    />
                  }
                  label="Paid"
                />
              </div>
              {/* _____________________Duration section_____________________________ */}
              <div className="all-coures-sub-heading-section">Duration</div>
              <div className="duration-flex-container">
                <TextField
                  placeholder="Duration"
                  label=""
                  type="number"
                  value={selectedDuration.value}
                  onChange={(event) =>
                    setSelectedDuration((prev) => ({
                      ...prev,
                      value: event.target.value,
                    }))
                  }
                  fullWidth
                />
                <Autocomplete
                  options={durationOptions}
                  value={selectedDuration.unit}
                  onChange={(event, newValue) =>
                    setSelectedDuration((prev) => ({
                      ...prev,
                      unit: newValue,
                    }))
                  }
                  renderInput={(params) => <TextField {...params} label="" />}
                  disableClearable
                  fullWidth
                />
              </div>
              {/* _______________________rating section___________________ */}
              <div className="all-coures-sub-heading-section">Rating</div>
              <div className="all-course-section-filter-options">
                {[4.5, 4.0, 3.5, 3.0].map((rating) => (
                  <FormControlLabel
                    key={rating}
                    control={
                      <Checkbox
                        value={rating}
                        checked={ratingFilter.includes(rating)}
                        onChange={handleRatingChange}
                      />
                    }
                    label={`${rating} Star and Up`}
                  />
                ))}
                {/* ________________________End____________________ */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AllCourseSectionModal;
