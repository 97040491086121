// ResumeMainContent.js
import React from 'react';
import ResumeHeader from './ResumeHeader';
import ResumeProfile from './ResumeProfile';
import ResumeWorkExperience from './ResumeWorkExperience';
import ResumeReferences from './ResumeReferences';
import '../Resume.css';

const ResumeMainContent = ({ name, title,showLogo, profile, workExperience, references }) => {
  return (
    <div className="main-content">
      <ResumeHeader name={name} title={title} showLogo={showLogo}/>
      <ResumeProfile profile={profile} />
      <ResumeWorkExperience workExperience={workExperience} />
      <ResumeReferences references={references} />
    </div>
  );
};

export default ResumeMainContent;
