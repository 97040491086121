import React from "react";
import "./testimol.css";
import google from "./TestimolImages/google1.png";
import StarIcon from "@mui/icons-material/Star";
// import provenExpert from "./TestimolImages/provenExpert.png";

function RatingSection() {
  return (
    <div className="rating-section-background">
      <div className="rating-section-width-container">
        <div className="rating-section-display-container">
          <div className="rating-google-container">
            {/*_______google testinomial section________________ */}
            <div className="rating-number-container">5</div>
            <div className="rating-star-icon-container">
              <StarIcon className="rating-star-icon-alignment" />
            </div>
            <div className="rating-google-image-conatiner">
              <img src={google} alt="google-company-icon-image" />
            </div>
          </div>

          {/* _________________________End__________________ */}
          {/* ______________________________proven expert section___________________ */}
          {/* <div className="rating-google-container">
            <div className="rating-number-container">4.9</div>
            <div className="rating-star-icon-container">
              <StarIcon className="rating-star-icon-alignment" />
            </div>
            <div className="rating-google-image-conatiner">
              <img src={provenExpert} alt="google-company-icon-image" />
            </div>
          </div> */}
          {/* ___________________________end_________________________________ */}
        </div>
      </div>
    </div>
  );
}

export default RatingSection;
