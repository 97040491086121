// ResumeProfile.js
import React, { useState } from 'react';
import '../Resume.css';

const ResumeProfile = ({ profile }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (field) => {
    setIsEditing(field);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="section">
      <h3 className="profile-title">PROFILE</h3>
      <p className={`editable ${isEditing === 'profile' ? 'is-editing' : ''}`}
         onClick={() => handleEditClick('profile')}
        onBlur={handleBlur}
        contentEditable={isEditing === 'profile'}
        suppressContentEditableWarning={true}>
        {profile}
      </p>
    </div>
  );
};

export default ResumeProfile;
