import React, { useState } from "react";
import "./NewScrollspy.css";
import PmTab2Resume from "./PmTab2Resume";
import PmTab3ResumeHeadline from "./PmTab3ResumeHeadline";
import PmTab4KeySkills from "./PmTab4KeySkills";
import Stscroll9ProfileSummary from "./PmTab9ProfileSummary";
import PmTab5Employment from "./PmTab5Employment";
import PmTab6Education from "./PmTab6Education";
import PmTab7ITSkills from "./PmTab7ITSkills";
import PmTab8Projects from "./PmTab8Projects";
import PmTab11CareerProfile from "./PmTab11CareerProfile";
import PmTab12PersonalDetails from "./PmTab12PersonalDetails";

const PmScrollspy = ({ callPercentage }) => {
  const sections = [
    {
      id: "sec-1",
      title: " Resume",
      content: <PmTab2Resume callPercentage={callPercentage} />,
    },
    {
      id: "sec-2",
      title: " Resume Headline",
      content: <PmTab3ResumeHeadline callPercentage={callPercentage} />,
    },
    {
      id: "sec-3",
      title: " Keyskills",
      content: <PmTab4KeySkills callPercentage={callPercentage} />,
    },
    {
      id: "sec-4",
      title: " Employment",
      content: <PmTab5Employment callPercentage={callPercentage} />,
    },
    {
      id: "sec-5",
      title: " Education",
      content: <PmTab6Education callPercentage={callPercentage} />,
    },
    {
      id: "sec-6",
      title: " IT Skills",
      content: <PmTab7ITSkills callPercentage={callPercentage} />,
    },
    {
      id: "sec-7",
      title: " Projects",
      content: <PmTab8Projects callPercentage={callPercentage} />,
    },
    {
      id: "sec-8",
      title: " Profile Summary",
      content: <Stscroll9ProfileSummary callPercentage={callPercentage} />,
    },
    {
      id: "sec-9",
      title: " Career Profile",
      content: <PmTab11CareerProfile callPercentage={callPercentage} />,
    },
    {
      id: "sec-10",
      title: " Personal details",
      content: <PmTab12PersonalDetails callPercentage={callPercentage} />,
    },
  ];
  //---------------------------------------------------------------------
  //  State Handles
  //---------------------------------------------------------------------

  const [activeTab, setActiveTab] = useState("sec-1");

  //---------------------------------------------------------------------
  //  Handle Active Tab
  //---------------------------------------------------------------------
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="mainContainerNewScroll"
    >
      {/*  ____________________________________ Left Side Scroll Tabs _________________________________ */}
      <nav className="scrollspyTabLeft "
      >
        <div className="scrUL">
          {sections.map((section) => (
            <li key={section.id} className="scrollspyTabItem">
              <span onClick={() => handleTabClick(section.id)}>
                <a
                  href={`#${section.id}`}
                  className={
                    activeTab === section.id
                      ? "profile_Scroll_Active "
                      : "profile_Scroll "
                  }
                >
                  {section.title}
                </a>
              </span>
            </li>
          ))}
        </div>
      </nav>

      {/*  ____________________________________ Right Side Scroll contents ____________________________  */}
      <div className="scrollspyContentSectionRight"
       >
        {sections.map((section) => (
          <section id={section.id} key={section.id}>
            <div
              className={
                section.id === activeTab
                  ? "profileScrollTabsNew pmActiveContent"
                  : "profileScrollTabsNew"
              }
            >
              {section.content}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default PmScrollspy;
