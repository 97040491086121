import React from 'react'
import AllCourse from './AllCourseImage/AllCoursePage.png'
import './allcourse.css'

function AllCourseHeadeSection() {
  return (
    <div className="allcourse-section-header-backgroundcolor">
      <div className="allcourse-section-width-container">

        {/* _____________Heading and content section____________ */}
        <div className="allcourse-section-flex-container">

        <div className="allcourse-section-content-content-section">
            <h1>
            Our Course Collection
            </h1>
            <p className='text-alignment-tagline'>
            Explore our courses and find the right path for you. Start learning today and reach your goals with guidance from our expert instructors.
            </p>
        </div>
        {/* ___________________________end______________________ */}
        {/* _________________________Image section______________ */}
        <div className="allcourse-section-image-container">
          <img src={AllCourse} alt="allcourse_image" />
        </div>
        {/* _______________________end__________________________ */}
        </div>
      </div>
    </div>
  )
}

export default AllCourseHeadeSection