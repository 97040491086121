import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import { TextField, Button, Autocomplete } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";
import { locationsPreferred } from "./PmCommonFunction";
import UpdateIcon from '@mui/icons-material/Update';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function Stscroll11CareerProfileModal({
  togglingModal,
  fetchData,
  callPercentage,
  buttonName,
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [currentIndustry, setCurrentIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [roleCategory, setRoleCategory] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [desiredJobType, setDesiredJobType] = useState("");
  const [desiredEmploymentType, setDesiredEmploymentType] = useState("");
  const [preferredShift, setPreferredShift] = useState("");
  const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  //--------------------------------------------------------------------------------
  // Dropdown Options
  //--------------------------------------------------------------------------------

  const industryOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];
  const departmentOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];
  const roleCategoryOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${baseURL}/getCareerProfile/${userEmail}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCurrentIndustry(data.currentIndustry || "");
          setDepartment(data.department || "");
          setRoleCategory(data.roleCategory || "");
          setJobRole(data.jobRole || "");

          setDesiredJobType(data.desiredJobType || "");
          setDesiredEmploymentType(data.desiredEmploymentType || "");
          setPreferredShift(data.preferredShift || "");
          // setPreferredWorkLocation(data.preferredWorkLocation || "");

          // Parse preferredWorkLocation if it's a JSON string
          const parsedPreferredWorkLocation = data.preferredWorkLocation
            ? JSON.parse(data.preferredWorkLocation)
            : [];

          setPreferredWorkLocation(parsedPreferredWorkLocation);
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Sending the Data TO API
  //--------------------------------------------------------------------------------
  async function saveProfileData() {
    try {
      const sendData = {
        email: userEmail, // going

        currentIndustry: currentIndustry,
        department: department,
        roleCategory: roleCategory,
        jobRole: jobRole,
        desiredJobType: desiredJobType,
        desiredEmploymentType: desiredEmploymentType,
        preferredShift: preferredShift,
        preferredWorkLocation: preferredWorkLocation,
      };

      // Define required fields for validation
      const requiredFields = [
        "currentIndustry",
        "department",
        "roleCategory",
        "jobRole",
        "desiredJobType",
        "desiredEmploymentType",
      ];

      // Validate required fields
      const newInvalidFields = {};
      for (const field of requiredFields) {
        if (!sendData[field]) {
          newInvalidFields[field] = true;
        }
      }

      if (Object.keys(newInvalidFields).length > 0) {
        setInvalidFields(newInvalidFields);
        return;
      }
      setInvalidFields({});

      const response = await fetch(`${baseURL}/updateCareerProfile`, {
        method: "POST",
        body: JSON.stringify(sendData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        console.log(" Career Profile updated success");
        customAlert("", "Item Updated", "success").then(() => {
          togglingModal();
          fetchData();
          callPercentage();
        });
      } else {
        console.error("Error updating Career Profile:", response.statusText);
      }
    } catch (error) {
      console.error("Error while enter savi button:", error);
    }
  }

  return (
    <div>
      <div className="pmresume-heading-container">
      <div className="pmTabHeadings2">Career Profile </div>
      </div>
      <div className="pmModalContainer-carrer-profile">
        
        {/* ___________________________________ Tab Heading _______________________________ */}
        {/* ___________________________________  Career Profile ____________________________ */}
        <div className="pmmodal-career-profile-container" >
          <p className="pmModParaText">
            It is the first thing recruiters notice in your profile. Write
            concisely what makes you unique and the right person for the job you
            are looking for.
          </p>

          {/* ___________________________________  Current Industry ____________________________ */}
          <div className="pmmodElement">
            <Autocomplete
              fullWidth
              options={industryOptions}
              getOptionLabel={(option) => option}
              value={currentIndustry}
              onChange={(event, newValue) => setCurrentIndustry(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Current Industry"
                  fullWidth
                  value={currentIndustry || ""}
                  onChange={(e) => setCurrentIndustry(e.target.value)}
                  error={invalidFields.currentIndustry}
                  helperText={
                    invalidFields.currentIndustry ? "* mandatory" : ""
                  }
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>
          {/* ___________________________________  Department ____________________________ */}
          <div className="pmmodElement">
            <Autocomplete
              fullWidth
              options={departmentOptions}
              getOptionLabel={(option) => option}
              value={department}
              onChange={(event, newValue) => setDepartment(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  fullWidth
                  value={department || ""}
                  onChange={(e) => setDepartment(e.target.value)}
                  error={invalidFields.department}
                  helperText={invalidFields.department ? "* mandatory" : ""}
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>

          {/* ___________________________________  Role Category ____________________________ */}
          <div className="pmmodElement">
            <Autocomplete
              fullWidth
              options={roleCategoryOptions}
              getOptionLabel={(option) => option}
              value={roleCategory}
              onChange={(event, newValue) => setRoleCategory(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role Category"
                  fullWidth
                  value={roleCategory || ""}
                  onChange={(e) => setRoleCategory(e.target.value)}
                  error={invalidFields.roleCategory}
                  helperText={invalidFields.roleCategory ? "* mandatory" : ""}
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>
          {/* ___________________________________  Job Role ____________________________ */}
          <div className="pmmodElement">
            <Autocomplete
              fullWidth
              options={roleCategoryOptions}
              getOptionLabel={(option) => option}
              value={jobRole}
              onChange={(event, newValue) => setJobRole(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Role"
                  fullWidth
                  value={jobRole || ""}
                  onChange={(e) => setJobRole(e.target.value)}
                  error={invalidFields.jobRole}
                  helperText={invalidFields.jobRole ? "* mandatory" : ""}
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>

          {/* ___________________________________  Desired Job Type (Radio) ____________________________ */}
          <div className="pmLabel-and-container-gap1">
            <label className="pmLabel">Desired Job Type</label>
            <div className="PMradio_group">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={desiredJobType}
                onChange={(e) => setDesiredJobType(e.target.value)}
                className="PmRadioView"
              >
                <FormControlLabel
                  value="Permanent"
                  control={<Radio />}
                  label="Permanent"
                  className="PmRadioItem"
                />
                <FormControlLabel
                  value="Contractual"
                  control={<Radio />}
                  label="Contractual"
                  className="PmRadioItem"
                />
              </RadioGroup>
            </div>
          </div>

          {/* ___________________________________  Desired Emp Type (all select check) ____________________________ */}
          <div className="pmLabel-and-container-gap1">
            <label className="pmLabel">Desired Employment Type</label>
            <div className="PMradio_group">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={desiredEmploymentType}
                onChange={(e) => setDesiredEmploymentType(e.target.value)}
                className="PmRadioView"
              >
                <FormControlLabel
                  value="FullTime"
                  control={<Radio />}
                  label="Full Time"
                  className="PmRadioItem"
                />
                <FormControlLabel
                  value="PartTime"
                  control={<Radio />}
                  label="Part Time"
                  className="PmRadioItem"
                />
              </RadioGroup>
            </div>
          </div>

          {/* ___________________________________  Desired Preferred shift (Radio) _____________________ */}
          <div className="pmLabel-and-container-gap1">
            <label className="pmLabel">Desired Preferred shift</label>
            <div className="PMradio_group1">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={preferredShift}
                onChange={(e) => setPreferredShift(e.target.value)}
                className="PmRadioView"
              >
                
                <FormControlLabel
                  value="Day"
                  control={<Radio />}
                  label="Day"
                  className="PmRadioItem"
                />
                <FormControlLabel
                  value="Night"
                  control={<Radio />}
                  label="Night"
                  className="PmRadioItem"
                />
                <FormControlLabel
                  value="Flexible"
                  control={<Radio />}
                  label="Flexible"
                  className="PmRadioItem"
                />
              </RadioGroup>
            </div>
          </div>

          {/* ___________________________________  Preferred Location __________________________ */}
          <div className="pmmodElement">
            <div>
              {/*  ____________________  Template call  _______________ */}
              <CustomMultiSelect
                // label="Preferred Location"
                options={locationsPreferred}
                selectedOption={preferredWorkLocation}
                setSelectedOption={setPreferredWorkLocation}
                chipClassName="jobPostLocationtag"
                chipDeleteClassName="jobPostLocationDeleteX"
                placeholder="Preferred Locations"
              />
            </div>
          </div>
        </div>
      </div>

      {/* _____________________________     Save  &  Cancel   _______________________ */}
      <div className="createteslayoutmodel6-resume-headline">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveProfileData}
        >
       {buttonName==='Update' ? < UpdateIcon className="buttonicons" /> : <SaveIcon className="buttonicons" />}
          &nbsp;{buttonName}
        </Button>
      </div>
    </div>
  );
}

export default Stscroll11CareerProfileModal;
