import React from "react";
import "./Footer.css";

function FooterBottomBar() {
  return (
    /*_______________________________________Footer bottom bar____________________________*/
    <div className="footer-bottombar-background-color">
      <div className="bottom">
        <div className="bottom_bar">
          <p>&copy; 2023 Talent Pool Academy. All rights reserved</p>
        </div>
      </div>
    </div>
    /*__________________________end____________________________________ */
  );
}

export default FooterBottomBar;
