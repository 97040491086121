import React, { useState } from "react";
import "./HeaderMain.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import InfoIcon from "@mui/icons-material/Info";
import ArticleIcon from "@mui/icons-material/Article";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Avatar from "@mui/material/Avatar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

function MeatDot() {
  //-------------------------------------------------------------------------
  //Hooks states
  //-------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const LoginEmail = localStorage.getItem("HTES_user_name");
  const LoginBasedCharShowned =
    (LoginEmail && LoginEmail.charAt(0).toUpperCase()) || "";
  //-------------------------------------------------------------------------
  //Meat dot menu open
  //-------------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //-------------------------------------------------------------------------
  //Meat dot menu close
  //-------------------------------------------------------------------------
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    /*___________________________________Meatdot________________________*/
    <div className="">
      {/*______________________Meatdot Icon______________________*/}
      {LoginEmail ? (
        <Tooltip title={LoginEmail} arrow>
          <Avatar
            className="home-page-login-menu-icon-avatar"
            alt="TPA"
            onClick={handleClick}
          >
            {LoginBasedCharShowned}
          </Avatar>
          {/* &nbsp;<span className='three-dot-name-overflow'>{LoginEmail.charAt(0).toUpperCase() + LoginEmail.slice(1).toLowerCase()}</span> */}
        </Tooltip>
      ) : (
        <IconButton onClick={handleClick}>
          <MoreVertIcon className="moreverticon-color" />
        </IconButton>
      )}
      {/*______________________Menu______________________________*/}
      <Menu
        className="Home-metadot-icon-menu-width"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*______________________Login avatar___________________________*/}
        {/* {LoginEmail && (
          <MenuItem className="customMenuItem">
            <Avatar className="home-page-login-menu-icon-avatar" alt="TPA">
              {LoginBasedCharShowned}
            </Avatar>
            &nbsp;<span className='three-dot-name-overflow'>{LoginEmail.charAt(0).toUpperCase() + LoginEmail.slice(1).toLowerCase()}</span>

          </MenuItem>
        )} */}
        {/*______________________Login and Register___________________________*/}
        {!LoginEmail && (
          <a href="/signin" className="customLink">
            <MenuItem className="customMenuItem">
              <LoginIcon className="menuItemIcon loginIcon" />
              Login
            </MenuItem>
          </a>
        )}
        {!LoginEmail && (
          <a href="/register" className="customLink">
            <MenuItem className="customMenuItem">
              <AppRegistrationIcon className="menuItemIcon registerIcon" />
              Register
            </MenuItem>
          </a>
        )}

        {/*_________________________Profile___________________________*/}
        {LoginEmail && (
          <a href="/update_profile" className="customLink">
            <MenuItem onClick={handleClose} className="customMenuItem">
              <AssignmentIndIcon className="menuItemIcon profileIcon" />
              Profile Update
            </MenuItem>
          </a>
        )}

        <a href="/interview_questions" className="customLink">
          <MenuItem onClick={handleClose} className="customMenuItem">
            <QuestionAnswerIcon className="menuItemIcon aboutUsIcon" />
            InterView Questions
          </MenuItem>
        </a>

        {/*_________________________Blog___________________________*/}
        <a href="/blog" className="customLink">
          <MenuItem onClick={handleClose} className="customMenuItem">
            <ArticleIcon className="menuItemIcon blogIcon" />
            Blog
          </MenuItem>
        </a>

        {/*__________________________About us________________________*/}
        <a href="/about" className="customLink">
          <MenuItem onClick={handleClose} className="customMenuItem">
            <InfoIcon className="menuItemIcon aboutUsIcon" />
            About Us
          </MenuItem>
        </a>

        {/*________________________Contact us_______________________*/}
        <a href="/contact" className="customLink">
          <MenuItem onClick={handleClose} className="customMenuItem">
            <ContactMailIcon className="menuItemIcon contactIcon" />
            Contact Us
          </MenuItem>
        </a>

        {/*________________________LogOut_______________________*/}
        {LoginEmail && (
          <a href="/signout" className="customLink">
            <MenuItem onClick={handleClose} className="customMenuItem">
              <LogoutIcon className="menuItemIcon contactIcon" />
              LogOut
            </MenuItem>
          </a>
        )}
      </Menu>
    </div>
  );
}

export default MeatDot;
