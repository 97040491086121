import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobDetails from './JobDetails';
import HeaderMain from './../Header/HeaderMain';
import FooterMain from '../Footer/FooterMain';
import axios from 'axios';
import './JobPortal.css';
import { baseURL } from "../../http";
import Job_MainCard from './Job_MainCard';
import JobHeader from './JobHeader';
import HeaderSmallBar from './../Home/HeaderTopSmallBar/HeaderSmallBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const JobDetailsPage = () => {

  //----------------------------------------------------------------
  //State handle
  //----------------------------------------------------------------
  const { id } = useParams();
  const jobId = parseInt(id, 10);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  // Function to update the window width state on resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //----------------------------------------------------------------
  //Fetch specific individual job cards using api
  //----------------------------------------------------------------
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/jobPost_fetch_specfic/${jobId}`);
        setJob(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching job details:', error);
        setError('Error fetching job details');
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleBackClick = () => {
    navigate('/job_portal'); // Navigate back to the main page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <HeaderSmallBar />
      <HeaderMain />
      <div className="job-details-page">
        <div className="back-arrow-container" onClick={handleBackClick}>
          <ArrowBackIcon className="back-arrow-icon" />
        </div>
        {job ? (
          <>
            {windowWidth <= 850 && (
              <div className="job-main-card-container">
                <Job_MainCard job={job} showLaunchIcon={false} />
              </div>
            )}
            <div className="job-detail-card-container">
              <JobDetails job={job} showLaunchIcon={false} showCloseIcon={false} />
            </div>
          </>
        ) : (
          <p>Job not found</p>
        )}
      </div>
      <FooterMain />
    </div>
  );
};

export default JobDetailsPage;
