import React from "react";

import { TextField, Button, IconButton, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  universityOptions,
  educationSpecializationOptions,
  educationCourseOptions,
  educationOptions,
} from "./PmCommonFunction";
import UpdateIcon from "@mui/icons-material/Update";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function Stscroll6EducationAddModal({
  togglingModal,
  fetchEducList,
  selectedEducation,
  setSelectedEducation,
  University,
  setUniversity,
  selectedCourse,
  setSelectedCourse,
  selectedOptionSpecialization,
  setSelectedOptionSpecialization,
  courseType,
  setCourseType,
  selectedCourseStartYear,
  setSelectedCourseStartYear,
  selectedCourseEndYear,
  setSelectedCourseEndYear,
  editItemID,
  handleItemDelete,
  save,
  mode,
  invalidFields,
}) {
  return (
    <div className="profileModal ">
      {/* ___________________________________ Tab Heading _______________________________ */}
      <span className="pmTabHeadings2">{mode} Education</span>
      <IconButton
        id="button-zoom"
        className="modal-close-iconButton-right-top-corner"
        onClick={togglingModal}
      >
        <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
      </IconButton>

      {mode == "Edit" && (
        <Tooltip title="Delete">
          <IconButton
            variant="contained"
            color="error"
            onClick={() => handleItemDelete(editItemID)}
            style={{ position: "absolute", top: 20, right: 30 }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      <div className="pmModalContainer pmModalBox2">
       
          {/* ___________________________________ Select Education ____________________________ */}
        <div>
            <Autocomplete // correct
              fullWidth
              options={educationOptions}
              getOptionLabel={(option) => option}
              value={selectedEducation}
              onChange={(event, newValue) => setSelectedEducation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label='Education'
                  value={University || ""}
                  onChange={(e) => setSelectedEducation(e.target.value)}
                  error={invalidFields.education}
                  helperText={invalidFields.education ? "* mandatory" : ""}
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
        </div>
       
        {/* ___________________________________ Type University ____________________________ */}
        <div>
            <Autocomplete // correct
              fullWidth
              options={universityOptions}
              getOptionLabel={(option) => option}
              value={University}
              onChange={(event, newValue) => setUniversity(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=' University/Institute'
                  fullWidth
                  value={University || ""}
                  onChange={(e) => setUniversity(e.target.value)}
                  error={invalidFields.university}
                  helperText={invalidFields.university ? "* mandatory" : ""}
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
        </div>

      
        {/* ___________________________________ Select Course ____________________________ */}
          <div>
            <Autocomplete
              fullWidth
              options={educationCourseOptions}
              getOptionLabel={(option) => option}
              value={selectedCourse}
              onChange={(event, newValue) => setSelectedCourse(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=' Course'
                  fullWidth
                  value={selectedCourse || ""}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  error={invalidFields.course}
                  helperText={invalidFields.course ? "* mandatory" : ""}
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>
       
        {/* ___________________________________ Select Specialization ____________________________ */}
          <div>
            <Autocomplete
              fullWidth
              options={educationSpecializationOptions}
              getOptionLabel={(option) => option}
              value={selectedOptionSpecialization}
              onChange={(event, newValue) =>
                setSelectedOptionSpecialization(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=' Specialization'
                  fullWidth
                  value={selectedOptionSpecialization || ""}
                  onChange={(e) =>
                    setSelectedOptionSpecialization(e.target.value)
                  }
                  error={invalidFields.specialization}
                  helperText={invalidFields.specialization ? "* mandatory" : ""}
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>
    
        {/* ___________________________________ Radio  Course Type ____________________________ */}
        <div className="EmploymentModal_section">
          <label
            className={`pmLabel ${
              invalidFields.courseType ? "error_Pmlabel" : ""
            }`}
          >
            Course Type
          </label>
       
          <div className="PMradio_group">
          <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={courseType}
              onChange={(e) =>
                setCourseType( e.target.value )}
                className="PmRadioView"
            >
              <FormControlLabel value="Full-Time" control={<Radio />} label="Full-Time" className="PmRadioItem"/>
              <FormControlLabel value="Part-Time" control={<Radio />} label="Part-Time"  className="PmRadioItem"/>
              <FormControlLabel value="Correspondance/Distance" control={<Radio />} label="Correspondance/Distance"  className="PmRadioItem"/>
            </RadioGroup>
          </div>
        </div>

        {/* ___________________________________ Select Course Duration  ______________________ */}
        <div className="experience-container">
          <div className="experience-fields">
            <div className="pmBasFields">
              <TextField
                required
                  label='Course From'
                type="date"
                fullWidth
                value={selectedCourseStartYear}
                onChange={(e) => setSelectedCourseStartYear(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                        className: "input_Custom_Style",
                        inputProps: {
                    style: { textTransform: "uppercase" },
                    max: new Date().toISOString().split("T")[0],
                  }, // Ensure the start date cannot be after the end date
                }}
                error={invalidFields.courseStart}
                helperText={invalidFields.courseStart ? "* mandatory" : ""}
              />
            </div>
            <div className="pmBasFields">
              <TextField
                  label='Course Till'
                required
                type="date"
                fullWidth
                value={selectedCourseEndYear}
                onChange={(e) => setSelectedCourseEndYear(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                        className: "input_Custom_Style",
                        inputProps: {
                    style: { textTransform: "uppercase" },
                    min: selectedCourseStartYear,
                  }, // Ensure the start date cannot be after the end date
                }}
                error={invalidFields.courseEnd}
                helperText={invalidFields.courseEnd ? "* mandatory" : ""}
              />
            </div>
          </div>
       
        </div>
     
      </div>
      {/* ___________________________________ Save & Cancel buttons  ______________________ */}

      <div className="createteslayoutmodel6-add-employment">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={save}
        >
          {mode == "Edit" ? (
            <>
              <UpdateIcon className="buttonicons" /> Update
            </>
          ) : (
            <>
              <SaveIcon className="buttonicons" /> Save
            </>
          )}
        </Button>
      </div>
    </div>
  );
}

export default Stscroll6EducationAddModal;
