import React, { useState, useEffect } from "react";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import "./LandingPage.css";
import CarouselComponent from "../Carousel/CarouselComponent";
import PageLoading from "../PageLoading/PageLoading";
import HomeCourseCardReder from "./HomeCourseCardRender/HomeCourseCardReder";
import axios from "axios";
import { baseURL } from "../../http";
import EnquirySection from "../EnquirySection/EnquirySection";
import BackToTop from "../BacktoTop/BackToTop";
import FeatureLandingPage from "./FeaturesCards/FeatureLandingPage";
import CompanyLogosLandingPage from "./ComapyLogos/CompanyLogosLandingPage";
import AchivementSection from "./AchivementsSection/AchivementSection";
import TestimolLandingPage from "./TestimolsSection/TestimolLandingPage";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";

function LandingPage() {
  const [structuredData, setStructuredData] = useState({});

  //-------------------------------------------------------------------------
  //Call the function api to collect the coursename
  //-------------------------------------------------------------------------

  useEffect(() => {
    axios
      .get(baseURL + "/getcourse")
      .then((response) => {
        const data = response.data;

        if (data && Array.isArray(data)) {
          const filteredData = data.filter((item) => item.isPublished === "1");

          const categoryMap = {};

          filteredData.forEach((item) => {
            if (!categoryMap[item.Category]) {
              categoryMap[item.Category] = {};
            }
            if (!categoryMap[item.Category][item.SubCategory]) {
              categoryMap[item.Category][item.SubCategory] = [];
            }
            categoryMap[item.Category][item.SubCategory].push(item.CourseName);
          });

          setStructuredData(categoryMap);
        } else {
          console.error("Unexpected response data:", data);
          setStructuredData({});
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setStructuredData({});
      });
  }, []);
  //-------------------------------------------------------------------------
  //Hook States
  //-------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);

  //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    /*________________________________Page Loading_________________________*/
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        /*_____________________________Landing Page Items________________________*/
        <>
          <HeaderSmallBar />
          <HeaderMain
            setStructuredData={setStructuredData}
            structuredData={structuredData}
          />
          <CarouselComponent />
          <FeatureLandingPage />
          <HomeCourseCardReder structuredData={structuredData} />
          <AchivementSection />
          <CompanyLogosLandingPage />
          <TestimolLandingPage />
          <EnquirySection />
          <FooterMain />
          <BackToTop />
        </>
      )}
    </div>
  );
}

export default LandingPage;
