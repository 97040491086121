import React, { useEffect, useRef } from "react";
import "./AboutUS.css";
import AboutUsImg from "./AboutImages/AboutUsImg.png";

const AboutTPA = () => {
  const imageRef = useRef(null);

  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          imageRef.current.classList.add("animate");
          observer.unobserve(imageRef.current);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    /*_______________________________________About content outside____________________________*/
    <div className="about-outside">
      {/*_______________________________________About content container____________________________*/}
      <div className="about-container">
        {/*_______________________________________About content ____________________________*/}
        <div className="about-content">
          {/*_______________________________________About content heading ____________________________*/}
          <h2>About TPA</h2>
          {/*_______________________________________About content paragraph ____________________________*/}
          <p>
            Welcome to TPA, your partner in professional engineering training
            and development. Our excellence and innovation make us a leader in
            technical education.
          </p>
          {/*_______________________________________About content paragraph ____________________________*/}
          <p>
            With years of industry experience, our expert instructors provide
            comprehensive, up-to-date training programs tailored to the evolving
            needs of students and industry partners.
          </p>
          {/*_______________________________________About content paragraph ____________________________*/}
          <p>
            At TPA, we empower individuals with the skills to excel in their
            careers. Whether you're a student, a professional, or a company
            seeking workforce training, TPA has the right solution for you.
          </p>
        </div>
        {/*_______________________________________About content image ____________________________*/}
        <div className="about-image" ref={imageRef}>
          <img src={AboutUsImg} alt="About TPA" />
        </div>
      </div>
    </div>
  );
};

export default AboutTPA;
