import React, { useState } from "react";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function PmTab5EmploymentAddModal({
  togglingModal,
  fetchStudentEmpList,
  employmentData,
  setEmploymentData,
  keySkillOptions,
  ClearAddData,
  callPercentage,
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  const [displayYear, setDisplayYear] = useState(null);
  const [displayMonth, setDisplayMonth] = useState(null);

  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const addEmpDetails = () => {
    const sendData = {
      email: userEmail,
      curEmp: employmentData.curEmp,
      empType: employmentData.empType,
      fullExpYears:
        employmentData.empType === "Full-Time"
          ? employmentData.fullExpYears
          : "",
      fullExpMonths:
        employmentData.empType === "Full-Time"
          ? employmentData.fullExpMonths
          : "",
      currentCompanyname: employmentData.currentCompanyname,
      currentDesignation: employmentData.currentDesignation,
      internshipLocation:
        employmentData.empType === "Internship"
          ? employmentData.internshipLocation
          : "",
      internshipWorkFrom: employmentData.internshipWorkFrom,
      internshipWorkTill: employmentData.internshipWorkTill,
      monthlyStipend:
        employmentData.empType === "Internship"
          ? employmentData.monthlyStipend
          : "",
      fulltimeWorkFrom: employmentData.fulltimeWorkFrom,
      fulltimeWorkTill: employmentData.fulltimeWorkTill,
      jobProfile:
        employmentData.empType === "Full-Time" ? employmentData.jobProfile : "",
      monthlyStipendCurrency: employmentData.monthlyStipendCurrency,
      skillsUsed: selectedOptionsKeySkills.map((option) => option.value),
      curSal: employmentData.curSal,
      internshipDepartment: employmentData.internshipDepartment,
    };
    console.log("enter");
    // Fields to validate
    // Define base required fields
    let requiredFields = [
      "curEmp",
      "empType",
      "currentCompanyname",
      "currentDesignation",
    ];

    // Conditionally add fields based on empType
    if (employmentData.empType === "Full-Time") {
      requiredFields = [...requiredFields, "fulltimeWorkFrom"];
    }
    if (employmentData.empType === "Internship") {
      requiredFields = [...requiredFields, "internshipWorkFrom"];
    }
    if (
      employmentData.empType === "Internship" &&
      employmentData.curEmp === "No"
    ) {
      requiredFields = [...requiredFields, "internshipWorkTill"];
    }

    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
      console.log(!sendData[field]);

      if (!sendData[field]) {
        newInvalidFields[field] = true;
      }
    }
    console.log(Object.keys(newInvalidFields).length);
    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return;
    }
    setInvalidFields({});
    fetch(`${baseURL}/createStudentEmployment`, {
      method: "POST",
      body: JSON.stringify(sendData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        console.log("Response status:", response.status); // Log the response status
        if (response.status == 200) {
          customAlert("", "Item Added", "success");
          ClearAddData();
          callPercentage();
        } else {
          customAlert("", "API Error", "warning");
        }
      })
      .catch((error) => {
        console.error("Error caught:", error); // Log the error
        customAlert("", "Server Down !", "warning");
      });
    fetchStudentEmpList();
    togglingModal();
  };

  //--------------------------------------------------------------------------------
  //  Calculate the years and month duration (Automatically from datepicker)
  //--------------------------------------------------------------------------------
  const handleCalcDuration = (startDate, endDate) => {
    // if (!startDate || !endDate) return;
    if (!startDate) return;

    let start;
    let end;

    if (employmentData.curEmp == "Yes") {
      start = new Date(startDate);
      end = new Date(); // Set end date to today
    } else {
      start = new Date(startDate);
      end = new Date(endDate);
    }
    console.log(startDate + "---" + endDate);
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }
    console.log("years=", start);
    console.log("months=", end);
    console.log("years=", years);
    console.log("months=", months);
    setEmploymentData((prevData) => ({
      ...prevData,
      fullExpYears: years,
      fullExpMonths: months,
    }));

    setDisplayMonth(months);
    setDisplayYear(years);
  };

  return (
    <div className="profileModal">
      <span className="pmTabHeadings2">Add Employment</span>
      <IconButton
        id="button-zoom"
        className="modal-close-iconButton-right-top-corner"
        onClick={togglingModal}
      >
        <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
      </IconButton>

      <div className="pmModalContainer pmModalBox2">
       
        {/* ___________________________________  Current Employment_______________________________ */}
        <div className="EmploymentModal_section">
          <label
            className={`pmLabel ${invalidFields.curEmp ? "error_Pmlabel" : ""}`}
          >
            Is this your current employment ?
          </label>
          <div className="PMradio_group">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={employmentData.curEmp}
              onChange={(e) =>
                setEmploymentData({ ...employmentData, curEmp: e.target.value })}
                className="PmRadioView"
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" className="PmRadioItem"/>
              <FormControlLabel value="No" control={<Radio />} label="No"  className="PmRadioItem"/>
            </RadioGroup>
          </div>
        </div>
      
        {/* ___________________________________   Employment Type _______________________________ */}
        <div className="EmploymentModal_section">
          <label
            className={`pmLabel ${
              invalidFields.empType ? "error_Pmlabel" : ""
            }`}
          >
            Employment type
          </label>

          <div className="PMradio_group">
          <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={employmentData.empType}
              onChange={(e) =>
                setEmploymentData({ ...employmentData, empType: e.target.value })}
                className="PmRadioView"
            >
              <FormControlLabel value="Full-Time" control={<Radio />} label="Full-Time" className="PmRadioItem"/>
              <FormControlLabel value="Internship" control={<Radio />} label="Internship"  className="PmRadioItem"/>
            </RadioGroup>
          </div>
        </div>
        

        {/* ___________________________________   Current Company _______________________________ */}
        <div>
          <div>
            <div>
              <TextField
                fullWidth
                label={employmentData.curEmp === "Yes" ?'Current Company Name':'Previous Company Name'}
                value={employmentData.currentCompanyname || ""}
                onChange={(e) =>
                  setEmploymentData({
                    ...employmentData,
                    currentCompanyname: e.target.value,
                  })
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
                error={invalidFields.currentCompanyname}
                helperText={
                  invalidFields.currentCompanyname ? "* mandatory" : ""
                }
              />
            </div>
         
          </div>
        </div>

        {/*_______ Current & Previous Designation _______ */}
        <div>
          <div>
            <div className="formProfile">
              <TextField
                label={employmentData.curEmp === "Yes" ?'Current Designation':'Previous Designation'}
                fullWidth
                value={employmentData.currentDesignation || ""}
                onChange={(e) =>
                  setEmploymentData({
                    ...employmentData,
                    currentDesignation: e.target.value,
                  })
                }
                error={invalidFields.currentDesignation}
                helperText={
                  invalidFields.currentDesignation ? "* mandatory" : ""
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
            </div>
          
          </div>
        </div>

        {/*___________ Joining Date -Full Time - datePic ___________ */}
        {employmentData.empType === "Full-Time" && (
          <div className="">
            <div >
              <div  >
                <TextField
                  required
                  label='From'
                  type="date"
                  fullWidth
                  value={employmentData.fulltimeWorkFrom} // YYYY-MM-DD - format required on database
                  onChange={(e) => {
                    setEmploymentData({
                      ...employmentData,
                      fulltimeWorkFrom: e.target.value,
                    });
                    handleCalcDuration(
                      e.target.value,
                      employmentData.fulltimeWorkTill
                    );
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "uppercase" },
                      max:
                        employmentData.curEmp === "Yes"
                          ? new Date().toISOString().split("T")[0]
                          : employmentData.curEmp === "No"
                          ? employmentData.fulltimeWorkTill
                          : "",
                    },
                    className: "input_Custom_Style",
                  }}
                  error={invalidFields.fulltimeWorkFrom}
                  helperText={
                    invalidFields.fulltimeWorkFrom ? "* mandatory" : ""
                  }
                />
              </div>

              {/* ___End Date____ */}
              {employmentData.curEmp === "No" && (
                <div className="from-data-and-to-date">
                 
                  {/* <label className="pmLabel">Till </label> */}
                  <TextField
                  label='To'
                    required
                    type="date"
                    fullWidth
                    value={employmentData.fulltimeWorkTill} // YYYY-MM-DD - format required on database
                    onChange={(e) => {
                      setEmploymentData({
                        ...employmentData,
                        fulltimeWorkTill: e.target.value,
                      });
                      handleCalcDuration(
                        employmentData.fulltimeWorkFrom,
                        e.target.value
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textTransform: "uppercase" },
                        min: employmentData.fulltimeWorkFrom,
                        max: new Date().toISOString().split("T")[0],
                      }, // Ensure the start date cannot be after the end date
                      className: "input_Custom_Style",
                    }}
                  />
                </div>
              )}
            </div>

          
          </div>
        )}
        {/* ___________________________________   Employment Experience __________________________ */}
        {employmentData.empType === "Full-Time" && (
          <div className="experience-container">

            <div className="experience-fields">
              <div className="pmBasFields">
                <TextField
                  // label='Experience (Years)'
                  fullWidth
                  disabled
                  value={displayYear}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    className: "input_Custom_Style",
                    endAdornment: (
                      <InputAdornment position="end">Experience Year(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </div>

              <div className="pmBasFields">
                <TextField
                  fullWidth
                  // label='Experience (Months)'
                  disabled
                  value={displayMonth}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    className: "input_Custom_Style",
                    endAdornment: (
                      <InputAdornment position="end">Experience Month(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </div>
            </div>

           
          </div>
        )}

        {/* ___________________________________   Designation, fulltime_DatePic join ____________________________________ */}

        {employmentData.empType === "Full-Time" ? (
          <>
            {/*___________ Current Salary -(Input)  ___________ */}
            <div>
              <div>
                <div className="formProfile">
                  <TextField
                  label='Current Salary'
                    fullWidth
                    value={employmentData.curSal || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEmploymentData({
                          ...employmentData,
                          curSal: inputValue,
                        });
                      }
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                    }}
                  />
                </div>
               
              </div>
            </div>

            {/*_______ Skills used -(Multi select input) + Component Call______ */}
            <div>
              <div>
                {/* ______ Component Call with Props____ */}
                <CustomMultiSelect
                  label='Skills Used'
                  options={keySkillOptions}
                  selectedOption={selectedOptionsKeySkills}
                  setSelectedOption={setSelectedOptionsKeySkills}
                  chipClassName="jobPostLocationtag"
                  chipDeleteClassName="jobPostLocationDeleteX"
                  placeholder="Type to Select KeySkill(s)"
                />
                
              </div>
            </div>
          </>
        ) : (
          <>
            {/* ___________________________________  Location (internship) - input _______________ */}
            {employmentData.empType === "Internship" && (
              <div>
                <div>
                  <div className="formProfile">
                    <TextField
                    label='Location'
                      fullWidth
                      value={employmentData.internshipLocation || ""}
                      onChange={(e) =>
                        setEmploymentData({
                          ...employmentData,
                          internshipLocation: e.target.value,
                        })
                      }
                      InputProps={{
                        className: "input_Custom_Style",
                      }}
                    />
                  </div>
                  
                </div>
              </div>
            )}

            {/* ___________________________________  Department (internship) - input ______________ */}
            <div>
              <div>
                <div className="formProfile">
                  <TextField
                    label='Department'
                    fullWidth
                    value={employmentData.internshipDepartment || ""}
                    onChange={(e) =>
                      setEmploymentData({
                        ...employmentData,
                        internshipDepartment: e.target.value,
                      })
                    }
                    InputProps={{
                      className: "input_Custom_Style",
                    }}
                  />
                </div>
              
              </div>
            </div>

            {/* ________________________________ WorkingFrom (internship) - dropdown ______________ */}
            <div>
              <div>
                <div >
                  <TextField
                    required
                  label={'From'}
                    type="date"
                    fullWidth
                    value={employmentData.internshipWorkFrom} // YYYY-MM-DD - format required on database
                    onChange={(e) => {
                      setEmploymentData({
                        ...employmentData,
                        internshipWorkFrom: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                      inputProps: {
                        style: { textTransform: "uppercase" },
                        max:
                          employmentData.curEmp === "Yes"
                            ? new Date().toISOString().split("T")[0]
                            : employmentData.curEmp === "No"
                            ? employmentData.internshipWorkFrom
                            : "",
                      },
                    }}
                    error={invalidFields.internshipWorkFrom}
                    helperText={
                      invalidFields.internshipWorkFrom ? "* mandatory" : ""
                    }
                  />
                </div>

                {employmentData.curEmp === "No" && (
                  <div className="from-data-and-to-date">
                   
                    <TextField
                      required
                      label={'To'}
                      type="date"
                      fullWidth
                      // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
                      value={employmentData.internshipWorkTill}
                      onChange={(e) => {
                        setEmploymentData({
                          ...employmentData,
                          internshipWorkTill: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        className: "input_Custom_Style",
                        inputProps: {
                          style: { textTransform: "uppercase" },
                          min: employmentData.internshipWorkFrom,
                          max: new Date().toISOString().split("T")[0],
                        },
                      }}
                      error={invalidFields.internshipWorkTill}
                      helperText={
                        invalidFields.internshipWorkTill ? "* mandatory" : ""
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          
          </>
        )}

        {/* ________________________________       Job profile         _________________________ */}
        {employmentData.empType === "Full-Time" && (
          <div>
              <TextField
              label='Job Profile'
                fullWidth
                value={employmentData.jobProfile || ""}
                onChange={(e) =>
                  setEmploymentData({
                    ...employmentData,
                    jobProfile: e.target.value,
                  })
                }
                multiline
                minRows={3}
                maxRows={8}
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
          </div>
        )}

        {/* ________________________________       Monthly Stipend         ______________________ */}
        {employmentData.empType === "Internship" && (
          <div>
            <div>
              <div className="formProfile">
                <TextField
              label='Stipend'
                  fullWidth
                  value={employmentData.monthlyStipend || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setEmploymentData({
                        ...employmentData,
                        monthlyStipend: inputValue,
                      });
                    }
                  }}
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              </div>
            
            </div>
          </div>
        )}
      </div>

      {/* ________________________________ Save  ______________________________________________ */}
      <div className="createteslayoutmodel6-add-employment">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={addEmpDetails}
        >
          <SaveIcon className="buttonicons" />
          Save
        </Button>
      </div>
    </div>
  );
}

export default PmTab5EmploymentAddModal;
