import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import Modal from "@mui/material/Modal";
import PmTab6EducationAddModal from "./PmTab6EducationAddModal";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { IconButton,  } from "@mui/material";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";

function PmTab6Education({callPercentage}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";

  const [educList, setEducList] = useState([]); // from DB
  const [addModalStatus, setAddModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);

  const [selectedCourseStartYear, setSelectedCourseStartYear] = useState(null);
  const [selectedCourseEndYear, setSelectedCourseEndYear] = useState(null);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedOptionSpecialization, setSelectedOptionSpecialization] =
    useState(null);
  const [University, setUniversity] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [invalidFields, setInvalidFields] = useState({});

  const [editItemID, setEditItemID] = useState(null);
  const [mode, setMode] = useState(null);

  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  function toggleAddModal(e) {
    setMode('Add');
    setEditItemID(0)
    setAddModalStatus(!addModalStatus);
    setSelectedEducation(null);
    setUniversity(null);
    setSelectedCourse(null);
    setSelectedOptionSpecialization(null);
    setCourseType(null);
    setSelectedCourseStartYear(null);
    setSelectedCourseEndYear(null);
  }

  const toggleEditModal = (itemID) => {
    setMode('Edit');

    setAddModalStatus(!addModalStatus);

    const selectedItem = educList.find((item) => item.id === itemID);

    console.log("edit modal" + JSON.stringify(selectedItem));
    if (selectedItem) {
      setEditItemID(itemID);

      setSelectedEducation(selectedItem.education);
      setUniversity(selectedItem.university);
      setSelectedCourse(selectedItem.course);
      setSelectedOptionSpecialization(selectedItem.specialization);
      setCourseType(selectedItem.courseType);
      setSelectedCourseStartYear(selectedItem.courseStart);
      setSelectedCourseEndYear(selectedItem.courseEnd);
    }
  };
  //--------------------------------------------------------------------------------
  // Fetching the Student All Employment LIst
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchStudentEducList();
  }, []);
  const fetchStudentEducList = () => {
    const requestBody = {
      email: userEmail,
    };

    fetch(`${baseURL}/getAllStudentEducation`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setEducList(data || "");
      })
      .catch((error) => {
        console.error("Error fetching Emp_details:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // API - Edit Education Item
  //--------------------------------------------------------------------------------
  const saveApi = () => {
    const updates = {
      id: editItemID,
      email: userEmail,

      education: selectedEducation,
      university: University,
      course: selectedCourse,
      specialization: selectedOptionSpecialization,
      courseType: courseType,
      courseStart: selectedCourseStartYear,
      courseEnd: selectedCourseEndYear,
    };

    const requiredFields = [
      'education',
      'university',
      'course',
      'specialization',
      'courseType',
      'courseStart',
      'courseEnd',
    ];
    
    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
      if (!updates[field]) {
        newInvalidFields[field] = true;
      }
    }
  
    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return; 
    }
  
    setInvalidFields({});
  
    const api=  editItemID==0?`createStudentEducation`:`updateStudentEducation/${editItemID}`
    const status= editItemID==0?`Added`:`Edited`
    fetch(`${baseURL}/${api}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updates),
    })
      .then((response) => {
        if (response.ok) {
          setEditModalStatus(false);
          setAddModalStatus(false);
          fetchStudentEducList();
          callPercentage();
          customAlert("", `Item ${status}`, "success");
        } else {
          customAlert("", "API - Error", "warning");
        }
      })
      .catch((error) => {
        customAlert("", "Server Down", "warning");
        console.error("Item updating error:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // API - Delete Education Item
  //--------------------------------------------------------------------------------
  const handleItemDelete = (itemId) => {
    const updatedEducList = educList.filter((item) => item.id !== itemId);
    setEducList(updatedEducList);

    axios
      .put(`${baseURL}/deleteStudentEducation/${itemId}`)
      .then((response) => {
        if (response.status === 200) {
          customAlert("", "Item Deleted", "success");
          fetchStudentEducList();
          callPercentage();
          setEditModalStatus(false);
        } else {
          customAlert("", "Item Delete Failed", "warning");
        }
      })
      .catch((error) => {
        customAlert("", "Server Down -Failed", "warning");
      });
  };


 //--------------------------------------------------------------------------------
// Helper function to format date
//--------------------------------------------------------------------------------
const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  }; 

  return (
    <div>
      {/* ___________________________________ Tab Heading _______________________________ */}
    
      <div className="pmEmpOuterContainer">
        <span className="pmTabHeadings2"> Education </span>
        <span onClick={toggleAddModal} className="pmAddEmpBtn">
          Add Education
        </span>
      </div>
    

      {/* ___________________________________ Render Data Mapping____________________________ */}
      <div>
        {educList.map((data) => (
          <div key={data.id}>
            <span className="pmRemderListTitle">{data.course}</span>{" "}
            <Tooltip title="Edit" arrow>
              <IconButton onClick={() => toggleEditModal(data.id)}>
                <EditIcon className="pmEditIcon" />
              </IconButton>
            </Tooltip>
            &nbsp;
            <br></br>
            <span>{data.university}</span>
            <br></br>
            <div className="pmProlifeUserData">
            <span className="pmDimDuration " >
              {data.courseType}
              {" "}  | {"  "}
              {formatDate(data.courseStart)} - {" "}
              {formatDate(data.courseEnd)} 
            </span>
              </div>
            <br></br>
            <span className="pmProlifeUserData">{data.jobProfile}</span>
            <hr/>
          </div>
        ))}
      </div>
      {/* ___________________________________ Render Data Mapping Ends____________________________ */}

      {/* ___________________________________ Add & Edit Modal code starts____________________________ */}

      <Modal
        open={addModalStatus}
        onClose={setAddModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          {/* _____________ Component call _____________ */}
          <PmTab6EducationAddModal
            togglingModal={toggleAddModal}
            fetchEducList={fetchStudentEducList}
            selectedCourseStartYear={selectedCourseStartYear}
            setSelectedCourseStartYear={setSelectedCourseStartYear}
            selectedCourseEndYear={selectedCourseEndYear}
            setSelectedCourseEndYear={setSelectedCourseEndYear}
            selectedEducation={selectedEducation}
            setSelectedEducation={setSelectedEducation}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            selectedOptionSpecialization={selectedOptionSpecialization}
            setSelectedOptionSpecialization={setSelectedOptionSpecialization}
            University={University}
            setUniversity={setUniversity}
            courseType={courseType}
            setCourseType={setCourseType}
            mode={mode}
            editItemID={editItemID}
            save={saveApi}
            handleItemDelete={handleItemDelete}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
          />
        </div>
      </Modal>

      {/* ___________________________________ Add & Edit Modal code Ends____________________________ */}

   
    </div>
  );
}

export default PmTab6Education;
