import React, { useState } from 'react';
import '../Resume.css';

const LanguagesSection = ({ languages }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="sidebar-section">
      <h3 className="languages-title">LANGUAGES</h3>
      <ul className={`editable ${isEditing ? 'is-editing' : ''}`}
        onClick={handleEditClick}
        onBlur={handleBlur}
        contentEditable={isEditing}
        suppressContentEditableWarning={true}>
        {languages.map((language, index) => (
          <li key={index}>{language}</li>
        ))}
      </ul>
    </div>
  );
};

export default LanguagesSection;
