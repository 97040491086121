import React from 'react';
import './LoadingPage.css'; // Import your CSS file

const PageLoading = ({ image }) => {
  return (
    <div className="make-container-center">
      <div className="page-loading">
        <div className="spinner"></div>
        <img src={image} alt="logo"  />
      </div>
    </div>
  );
};

export default PageLoading;
