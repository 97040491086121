import React, { useState, useEffect } from "react";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import { baseURL } from '../../http';
import { getDeviceInfo ,
    getCurrentDateTime,
    GoogleSignin,
    getLocationInfo} from "../Common/Utilities_Function";

function TrackWebsiteMain() {
  const [visitData, setVisitData] = useState({
    ip: '',
    userAgent: '',
    urlPage: '', // Use the current page URL
    device_name:'',
    UTC_Time:  getCurrentDateTime(),
    latitude: null,
    longitude: null,
    PinCode: null,
    Current_Location: null,
  });

  useEffect(() => {
    const trackVisit = async () => {
      try {
        if (visitData.longitude == null) {
                    // Get user's IP address
            const ipResponse = await fetch('https://api.ipify.org?format=json');
            const ipData = await ipResponse.json();
            visitData.ip = ipData.ip;
            visitData.device_name =  getDeviceInfo();
            visitData.userAgent = navigator.userAgent;
            visitData.UTC_Time= getCurrentDateTime();
            visitData.urlPage= window.location.href
            const location = await getLocationInfo();
            if (location != null) {
                visitData.latitude = location.latitude;
                visitData.longitude = location.longitude;
                visitData.PinCode = location.pincode;
                visitData.Current_Location = location.locationName;
            }
          }

        axios
        .post(baseURL + '/websiteTrack', visitData)
        .then((response) => {
          // customAlert(" ", "websiteTrack Success", "success");
        })
        .catch((error) => {
          console.log(error);
          // customAlert("", "Server Down", "error");
        });

      } catch (error) {
        console.error("Website track Fail", error);
      }
    };

    trackVisit();
  }, []);

  return null; // No UI needed
}

export default TrackWebsiteMain;
