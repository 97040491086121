import React from "react";
import "./featurecard.css";

function FeatureCard2({ Image, Heading, Content }) {
  return (
    <div className="featurecard2-container">
      {/* ______________________icon__________________ */}
      <div className="feature-card2-icon-conatiner">
      <div className="icon-container">{<img src={Image} />}</div>
        {/* _______________heading__________________________ */}
        <div className="feature-card2-heading">{Heading}</div>
      </div>
      {/* _____________________content______________________ */}
      <div className="feature-card2-content">{Content}</div>
    </div>
  );
}

export default FeatureCard2;
