import React, { useEffect, useState } from "react";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import InterviewQuestionAddQuestion from "./InterviewQuestionAddQuestion";
import BackToTop from "../BacktoTop/BackToTop";
import InterviewQuestionRenderSectionFilter from "./InterviewQuestionRenderSectionFilter";
import PageLoading from "../PageLoading/PageLoading";

function InterViewQuestionLandingPage() {
  //-------------------------------------------------------------------------
  //Hook States
  //-------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);

  //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
          <HeaderSmallBar />
          <HeaderMain />
          <InterviewQuestionAddQuestion />
          {/* <InterViewQuestionHeaderSection/> */}
          <InterviewQuestionRenderSectionFilter />
          <BackToTop />
          <FooterMain />
        </>
      )}
    </div>
  );
}

export default InterViewQuestionLandingPage;
