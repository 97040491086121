import React, { useState, useEffect, useRef } from "react";
import "./coursetemplate2.css";
import CourseTemplate2OverView from "./CourseTemplate2OverView";
import CourseTemplate2Description from "./CourseTemplate2Description";
import Coursetemplate2Content from "./Coursetemplate2Content";
import CourseTemplate2Instructor from "./CourseTemplate2Instructor";

import CourseInclude from "./CourseInclude";
import CourseTemplateFaq from "./CourseTemplateFaq";

function Coursetemplate2Tabs({
  isChecked,
  selectedFAQs,
  selectedInstructors,
  setSelectedInstructors,
  courseContent,
  setCourseContent,
  description,
  setDescription,
  learnersecontent,
  setLearnersContent,
  requirementscontent,
  setRequirementsContent,
  skillscontent,
  setSkillsContent,
  objectivecontent,
  setObjectiveContent,
}) {
  const [activeTab, setActiveTab] = useState("Objective");
  const observer = useRef(null);
  const [zIndex, setZIndex] = useState(0);

  //--------------------------------------------------------------------------------
  // Adjust scroll-margin-top for each section
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const sections = document.querySelectorAll(
      ".objctive-background-color,#Objective, #Skills, #Requirements, #Recommended, #Description, #Content, #Instructor, #FAQ "
    );
    sections.forEach((section) => {
      section.style.scrollMarginTop = "80px"; // Adjust this value based on your sticky navbar height
    });

    // Intersection Observer to change active tab based on scroll
    const options = {
      root: null,
      rootMargin: "-80px 0px 0px 0px", // Adjust this based on your sticky navbar height
      threshold: 0.9, // Trigger when 25% of the section is visible
    };

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    // Cleanup observer on unmount
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  //--------------------------------------------------------------------------------
  // Clikc the tab to open the active one
  //--------------------------------------------------------------------------------

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    document.getElementById(tab).scrollIntoView({ behavior: "smooth" });
  };

  //--------------------------------------------------------------------------------
  // Add sticky class to the container when it reaches the top
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".coursetemplate2-tabs-navbar");
      const container = document.querySelector(".coursetemplate2-tabs-navbar");
      if (navbar && container) {
        const offset = navbar.getBoundingClientRect().top;
        if (offset <= 4) {
          container.classList.add("sticky");
        } else {
          container.classList.remove("sticky");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


    //--------------------------------------------------------------------------------
  // Handle scroll to change z-index
  //--------------------------------------------------------------------------------
  
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(
        ".coursetemplate2-tabs-navbar"
      );
      if (element) {
        const topPosition = element.getBoundingClientRect().top;
        setZIndex(topPosition <= 0 ? 10 : 0); // Change z-index based on scroll position
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="coursetemplate2-tabs-container">
        {/* __________________Tabs Sections__________________ */}
        <div className="coursetemplate2-tabs-navbar" style={{ zIndex }}>
          {[
            "Objective",
            "Skills",
            "Requirements",
            "Recommended",
            "Description",
            "Content",
            "Instructor",
            "FAQ",
          ].map((tab) => (
            <div
              key={tab}
              className={`coursetemplate2-each-tabs ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </div>
          ))}
        </div>

        {/* __________________Tabs Sections__________________ */}

        {/* __________________What you will learn_________________ */}
        <div className="objctive-background-color">
          <div id="Objective">
            <CourseTemplate2OverView
              
              title="Objective"
              setContent={setObjectiveContent}
              content={objectivecontent}
            />
          </div>
        </div>

        {/* _________________________end__________________________ */}

        {/* __________________What you will learn_________________ */}
        <div className="Skills-background-color">
          <div id="Skills">
            <CourseTemplate2OverView
              
              title="Skills You Will Gain?"
              content={skillscontent}
              setContent={setSkillsContent}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}

        {/* __________________Needed skills for the course?_________________ */}
        <div className="Requirements-background-color">
          <div id="Requirements">
            <CourseTemplate2OverView
              
              title="Skills Required for This Course?"
              content={requirementscontent}
              setContent={setRequirementsContent}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}

        {/* __________________Who can benefit from this course?_________________ */}
        <div className="Learners-background-color">
          <div id="Recommended">
            <CourseTemplate2OverView
              
              title="Who Should Take This Course?"
              content={learnersecontent}
              setContent={setLearnersContent}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}

        {/* __________________Description_________________ */}
        <div className="Description-background-color">
          <div id="Description">
            <CourseTemplate2Description
              
              title="Description"
              content={description}
              setContent={setDescription}
            />
          </div>
        </div>

        {/* _________________________end__________________________ */}

        {/* __________________Content_________________ */}
        <div className="Content-background-color">
          <div id="Content">
            <Coursetemplate2Content
              
              title="Course Content"
              courseContent={courseContent}
              setCourseContent={setCourseContent}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}
         {/* __________________CourseInclude_________________ */}
         <div className="CourseInclude-background-color">
          <div>
            <CourseInclude courseContent={courseContent} />
          </div>
        </div>
        {/* _________________________end__________________________ */}

        {/* __________________Instructor_________________ */}
        <div className="Instructor-background-color">
          <div id="Instructor">
            <CourseTemplate2Instructor
              
              title="Instructor"
              selectedInstructors={selectedInstructors}
              setSelectedInstructors={setSelectedInstructors}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}

        {/* __________________Instructor_________________ */}
        <div className="Instructor-background-color">
          <div id="FAQ">
            <CourseTemplateFaq
              faqData={selectedFAQs}
              headerName={"Course FAQs"}
            />
          </div>
        </div>
        {/* _________________________end__________________________ */}

       
      </div>
     
    </>
  );
}

export default Coursetemplate2Tabs;
