import React, { useState } from "react";
import "./HireTalent.css";
import HeroImage from "./HireTalentImages/HireTalent.png";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";


function HireTalentHero() {
  const [open,setOpen]=useState(false);
  //--------------------------------------------------------------------------------
  // Function to open the enquiry
  //--------------------------------------------------------------------------------
  const handleClick=()=>{
    setOpen(true)
  }
  return (
    <div className="hire-hero-main">
      <div className="hire-hero-section">
        <div className="hire-hero-left">
          <h1>Hire Top Talents</h1>
          <p>In today's competitive market, the success of your business depends
            on having the right people in the right roles. At Talent Pool
            Academy, we connect you with top-tier talent that not only meets
            your technical requirements but also aligns with your company’s
            culture and values.</p>
          <button className="cta-button" onClick={handleClick}>Get Started</button>
        </div>
        <div className="hire-hero-right">
          <img src={HeroImage} alt="Talent Pool" />
        </div>
      </div>
      {/* _________________call the Form section___________ */}
      <EnquiryFormModal open={open} setOpen={setOpen} EnquiryHeading={'Hire Talent'}/>
    </div>
  );
}

export default HireTalentHero;
