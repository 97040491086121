import React from "react";
import "./HireTalent.css";
import { FaAward, FaUsers, FaChalkboardTeacher } from "react-icons/fa";

const UniqueSellingPoints = () => {
  return (
    <div className="usp-main">
      <div className="usp-section">
        <h2>What Sets Us Apart</h2>
        <div className="usp-cards">
          <div className="usp-card">
            <FaAward className="usp-icon" />
            <h3>Expert Trainers</h3>
            <p>Industry experts with years of experience.</p>
          </div>
          <div className="usp-card">
            <FaUsers className="usp-icon" />
            <h3>Customized Solutions</h3>
            <p>Tailored training for your needs.</p>
          </div>
          <div className="usp-card">
            <FaChalkboardTeacher className="usp-icon" />
            <h3>Proven Track Record</h3>
            <p>Numerous satisfied clients.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueSellingPoints;
