import React from "react";
import FooterComponent1 from "./FooterComponent1";
import FooterComponent2 from "./FooterComponent2";
import FooterComponent3 from "./FooterComponent3";
import FooterBottomBar from "./FooterBottomBar";
import "./Footer.css";

function FooterMain() {
  return (
    <div className="footer-bottombar-background-color">
      <div className="footer-width-control-container">
        <div className="footer_container">
          {/* __________________________footer component 1,2,3________________ */}
          <div>
            <FooterComponent1 />
          </div>
          <div>
            <FooterComponent2 />
          </div>
          <div>
            <FooterComponent3 />
          </div>
        </div>
        {/* _______________________________end___________________________________ */}
      </div>
      {/* ____________________________Footer bottom bar__________________ */}
      <div className="footer_divider"></div>
      <FooterBottomBar />
    </div>
    // ____________________________end_______________________________________//
  );
}

export default FooterMain;
