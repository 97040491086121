import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./progressbar.css";

function ProcessProgressCircle({RequiredProgressName}) {
  return (
    <div className="process-progress-circle-container">
      <div className="circle-background-color">
        
        <React.Fragment>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </React.Fragment>
        <div className="text-conataienalignment-progrees-bar">{RequiredProgressName} Please wait</div>
      </div>
    </div>
  );
}

export default ProcessProgressCircle;
