import React,{useState} from "react";
import "./AboutUS.css";
import About_img from "./AboutImages/About_img.png";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";

function AboutCom1() {
  const [open,setOpen]=useState(false);
  //--------------------------------------------------------------------------------
  // Function to open the enquiry
  //--------------------------------------------------------------------------------
  const handleClick=()=>{
    setOpen(true)
  }
  return (
    /*_______________________________________About comp1____________________________*/
    <div className="comp_1">
      <div className="comp1_outside">
        <div className="comp1_wrapper">
          \
          {/*_______________________________________About comp container____________________________*/}
          <div className="comp1_container">
            <h5 className="comp1_heading">Welcome to TPA</h5>
            <h2 className="comp1_paragraph">Where Your Dreams Take Flight</h2>
            {/* <button className="about-button">Get Started</button> */}
          </div>
        </div>
        {/*_______________________________________About comp image____________________________*/}
        <img src={About_img} className="comp1_img" alt="about image" />
      </div>
      {/* _________________enquiry function____________ */}
      <EnquiryFormModal open={open} setOpen={setOpen} EnquiryHeading={'About Us'}/>
    </div>
  );
}

export default AboutCom1;
