// ResumeWorkExperience.js
import React, { useState } from 'react';
import '../Resume.css';

const ResumeWorkExperience = ({ workExperience }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (field) => {
    setIsEditing(field);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="section">
      <h3 className="work-experience-title">WORK EXPERIENCE</h3>
      {workExperience.map((job, index) => (
        <div key={index} className="work-experience">

          {/* _________Company name, duration,designation,projects________ */}
          <div className="work-header editable"
            onClick={() => handleEditClick(`job-${index}`)}
            onBlur={handleBlur}
            contentEditable={isEditing === `job-${index}`}
            suppressContentEditableWarning={true}>
            <h4>{job.companyName}</h4>
            <p className="duration">{job.duration}</p>
          </div>

           {/* _________Designation________ */}
          <h5 className={`editable ${isEditing === `designation-${index}` ? 'is-editing' : ''}`}
            onClick={() => handleEditClick(`designation-${index}`)}
            onBlur={handleBlur}
            contentEditable={isEditing === `designation-${index}`}
            suppressContentEditableWarning={true}>
            {job.designation}
          </h5>

          {/*_______Projects_____*/}
          <ul className={`editable ${isEditing === `projects-${index}` ? 'is-editing' : ''}`}
            onClick={() => handleEditClick(`projects-${index}`)}
            onBlur={handleBlur}
            contentEditable={isEditing === `projects-${index}`}
            suppressContentEditableWarning={true}>
            {job.projects.map((project, idx) => (
              <li key={idx}>{project}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ResumeWorkExperience;
