import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import PmTab13PersonalDetailModal from "./PmTab13PersonalDetailModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function PmTab12PersonalDetails({ callPercentage }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [careerBreak, setCareerBreak] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [differentlyAbled, setDifferentlyAbled] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [hometown, setHometown] = useState("");
  const [pincode, setPincode] = useState("");

  const [languageCount, setLanguageCount] = useState(0); // added New

  const [languageArray, setLanguageArray] = useState([]);
  const [renderLanguageArray, setRenderLanguageArray] = useState([]);

  const [modalStatus, setModalStatus] = useState(false);
  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  function toggleModal(e) {
    setModalStatus(!modalStatus);
  }

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const requestBody = {
        email: userEmail,
      };
      const response = await fetch(`${baseURL}/getprofile`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGender(data.gender || "");
        setMaritalStatus(data.maritalStatus || "");
        setCareerBreak(data.careerBreak || "");
        setCategory(data.personalCategory || "");
        setAddress(data.permanentAddress || "");
        setDifferentlyAbled(data.differentlyAbled || "");
        setPincode(data.pincode || "");
        setHometown(data.hometown || "");
        setMoreInfo(data.moreInfo || "");
        setDateOfBirth(data.dateOfBirth || "");

        //  added New
        setLanguageArray(data.languageArray || []);
        setRenderLanguageArray(data.languageArray || "");
      } else {
        console.error("Failed to fetch item");
      }
    } catch (error) {
      console.error("Error getting api:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Formatting the language Array to check the valid item
  //--------------------------------------------------------------------------------
  function formatLanguageArray() {
    const validLanguages = [];
    const excludedLanguages = [];

    languageArray.forEach((language) => {
      if (
        language.language !== null &&
        (language.read || language.write || language.speak)
      ) {
        validLanguages.push(language);
      } else {
        excludedLanguages.push(language);
      }
    });

    // setLanguageArray(validLanguages);
    return validLanguages;
  }
  //--------------------------------------------------------------------------------
  // Sending the Data TO API
  //--------------------------------------------------------------------------------
  const addPersDetail = () => {
    const excludedLanguages = formatLanguageArray();
    const sendData = {
      email: userEmail,
      gender: gender,
      moreInfo: moreInfo,
      maritalStatus: maritalStatus,
      dateOfBirth: dateOfBirth,
      personalCategory: category,
      differentlyAbled: differentlyAbled,
      careerBreak: careerBreak,
      permanentAddress: address,
      hometown: hometown,
      pincode: pincode,
      dateOfBirth: dateOfBirth,
      //
      // languageArray:languageArray
      languageArray: excludedLanguages,
    };
    fetch(`${baseURL}/createStudentPersonalDetails`, {
      method: "POST",
      body: JSON.stringify(sendData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert("", "Item Updated", "success");
          setModalStatus(false);
          fetchUserData();
          callPercentage();
        } else {
          console.error("inside API error ");
        }
      })
      .catch((error) => {
        console.error("API call error-outside:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // Helper Function - Formate the Date to render
  //--------------------------------------------------------------------------------
const dateOfBirth_formatted = dateOfBirth
  ? new Date(dateOfBirth).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
  : null;


  //--------------------------------------------------------------------------------
  //  Function - To add extra language
  //--------------------------------------------------------------------------------
  function handleLanguageCount() {
    setLanguageCount(languageCount + 1);
  }

  //--------------------------------------------------------------------------------
  // Parsing the Language array for render
  //--------------------------------------------------------------------------------
  useEffect(() => {
    try {
      const parsedArray = JSON.parse(languageArray);
      if (Array.isArray(parsedArray)) {
        setRenderLanguageArray(parsedArray);
      } else {
        console.error("Parsed data is not an array");
      }
    } catch (e) {
      console.error("Failed to parse renderLanguageArrayString", e);
    }
  }, [renderLanguageArray]);

  //--------------------------------------------------------------------------------
  // Helper Function - Render tick and cross icons for Language known
  //--------------------------------------------------------------------------------
  const renderIcon = (value) => {
    if (value) {
      return <CheckCircleOutlineIcon style={{ color: "green" }} />;
    } else {
      return <CancelOutlinedIcon style={{ color: "grey" }} />;
    }
  };

  return (
    <div>
      {/* ___________________________________ Render Personal individual datas ___________ */}
      <>
          {/* ___________________________________ Tab Heading _______________________________ */}
        <div className="pmTabHeadings" >
        Personal Details{" "}
        <Tooltip title="Edit" arrow>
          <IconButton onClick={toggleModal}>
            <EditIcon className="pmEditIcon" />
          </IconButton>
        </Tooltip>
      </div>

        {/* ____________________ Marrital status & Career break ___________________________ */}
        <div className="PMCarProfileRender" >
          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Personal</label>
            <br></br>
            <span className="pmDataLabel">
              {gender || ""}
              {maritalStatus !== "" && ", "}

              {maritalStatus || ""}
            </span>
          </div>

          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Career break</label>
            <br></br>
            <span className="pmDataLabel">{careerBreak || ""}</span>
          </div>
        </div>

        {/* ____________________ Date of birth & Differently abled ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Date of birth</label>
              <br />
              <span className="pmDataLabel">
              {dateOfBirth_formatted}
               
                </span>
          </div>

          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Differently abled</label>
              <br></br>
              <span className="pmDataLabel">{differentlyAbled || ""}</span>
          </div>
        </div>
      
        {/* ____________________ Category & Address ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Category</label>
              <br></br>
              <span className="pmDataLabel">{category || ""}</span>
          </div>

          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Address</label>
              <br></br>
              <span className="pmDataLabel">{address || ""}</span>
          </div>
        </div>

        <div>
         

      
        </div>
      </>

      {/* ___________________________________ Render Language Table datas ________________ */}
      <div className="tableContainer">
        <table className="tableData">
          {/* ___________________________________ Language Table Heading ___________________________ */}
          <thead>
            <tr className="tableinnerData pmtableHeaderonly">
              <th className="tableinner_first_child"> Language</th>
              <th> Read</th>
              <th> Write</th>
              <th> Speak</th>
            </tr>
          </thead>
          {/* ___________________________________ Language Table Render Data Map ___________________ */}
          <tbody>
            {renderLanguageArray.length > 0 ? (
              renderLanguageArray.map((lang) => (
                <tr key={lang.id} className="tableinnerData">
                  {lang.language != null &&
                    (lang.read || lang.write || lang.speak) && (
                      <>
                        <td className="tableinner_first_child">{lang.language}</td>
                        <td>{renderIcon(lang.read)}</td>
                        <td>{renderIcon(lang.write)}</td>
                        <td>{renderIcon(lang.speak)}</td>
                      </>
                    )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* ___________________________________ Modal code starts____________________________ */}
      <Modal
        open={modalStatus}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          <div>
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={toggleModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
          </div>


          {/* _________________________________    Component Call  _________________________ */}
          <PmTab13PersonalDetailModal
            gender={gender}
            setGender={setGender}
            category={category}
            setCategory={setCategory}
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            moreInfo={moreInfo}
            setMoreInfo={setMoreInfo}
            dateOfBirth={dateOfBirth}
            setDateOfBirth={setDateOfBirth}
            differentlyAbled={differentlyAbled}
            setDifferentlyAbled={setDifferentlyAbled}
            careerBreak={careerBreak}
            setCareerBreak={setCareerBreak}
            address={address}
            setAddress={setAddress}
            hometown={hometown}
            setHometown={setHometown}
            pincode={pincode}
            setPincode={setPincode}
            toggleModal={toggleModal}
            addPersDetail={addPersDetail}
            //  to be write
            handleLanguageCount={handleLanguageCount}
            languageCount={languageCount}
            setLanguageCount={setLanguageCount}
            languageArray={languageArray}
            setLanguageArray={setLanguageArray}
          />
        </div>
      </Modal>
      {/* ___________________________________ Modal code Ends    ____________________________ */}
    </div>
  );
}

export default PmTab12PersonalDetails;
