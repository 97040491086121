import React, { useState } from "react";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Tooltip } from "@mui/material";

function InterViewQuestionCard({ question = {} }) {
  const [isExpanded, setIsExpanded] = useState(false);
  //-------------------------------------------------------------------------
  // Utility function to calculate days ago
  //-------------------------------------------------------------------------

  const getDaysAgo = (interviewDate) => {
    const interviewDateObj = new Date(interviewDate); // Convert string to Date object
    const currentDate = new Date(); // Current date

    const timeDifference = currentDate - interviewDateObj;

    // Convert time difference from milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`;
    } else {
      return `${Math.abs(daysDifference)} day${
        Math.abs(daysDifference) > 1 ? "s" : ""
      } from now`;
    }
  };

  //-------------------------------------------------------------------------
  // Remove surrounding quotes and brackets
  //-------------------------------------------------------------------------
  const parseSkills = (skillsString) => {
    try {
      const cleanString = skillsString
        .replace(/^\[|\]$/g, "")
        .replace(/'/g, '"');
      // Parse the cleaned string into an array
      const skillsArray = JSON.parse(`[${cleanString}]`);
      return skillsArray;
    } catch (error) {
      console.error("Error parsing skills string:", error);
      return [];
    }
  };

  //-------------------------------------------------------------------------
  // Ensure question.Skills is a string before parsing
  //-------------------------------------------------------------------------

  const skillsArray =
    typeof question.Skills === "string" ? parseSkills(question.Skills) : []; // Default to empty array if not a string

  // Convert array to a comma-separated string
  const skillsString = skillsArray.map((skill) => skill.trim()).join(", ");

  //-------------------------------------------------------------------------
  //Expan the card
  //-------------------------------------------------------------------------
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={`interview-question-card ${
        isExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div
        className={`interview-question-container ${
          isExpanded ? "expanded" : "collapsed"
        }`}
      >
        {question.Question || ""}
      </div>
      <div className="interview-answer-container">
        <div
          className={`interview-answer-container-description ${
            isExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: question.Answer || "" }} />
          {/* {question.Answer} */}
        </div>
        <div className="read-more-less-text-alignment">
          <button onClick={toggleExpand} className="expand-button">
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
        <div className="interview-answer-image-container"></div>
        <div className="interview-answer-codesnap-container"></div>
      </div>
      <div className="interview-footer-container">
        {question.InterviewDate && (
          <div className="footer-each-container1">
            <Tooltip title="Interview Date" arrow>
              <CalendarMonthOutlinedIcon className="footer-each-conttainer-icons" />
            </Tooltip>
            &nbsp;
            <span className="details-information-span">
              {getDaysAgo(question.InterviewDate)}
            </span>
          </div>
        )}
        {question.Designation && (
          <div className="footer-each-container">
            <Tooltip title="Designation" arrow>
              <BadgeOutlinedIcon className="footer-each-conttainer-icons" />
            </Tooltip>
            &nbsp;
            <span className="details-information-span">
              {question.Designation || ""}
            </span>
          </div>
        )}
        {skillsString && (
          <div className="footer-each-container">
            <Tooltip title="skills" arrow>
              <WorkspacePremiumOutlinedIcon className="footer-each-conttainer-icons" />{" "}
            </Tooltip>
            &nbsp;
            <span className="details-information-span">
              {skillsString || ""}
            </span>
          </div>
        )}
        {question.Company && (
          <div className="footer-each-container">
            <Tooltip title="Company" arrow>
              <ApartmentOutlinedIcon className="footer-each-conttainer-icons" />
            </Tooltip>
            &nbsp;
            <span className="details-information-span">
              {question.Company || ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterViewQuestionCard;
