import React, { useState } from 'react';
import '../Resume.css';

const SkillsSection = ({ skills }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="sidebar-section">
      <h3 className="skills-title">SKILLS</h3>
      <ul className={`editable ${isEditing ? 'is-editing' : ''}`}
        onClick={handleEditClick}
        onBlur={handleBlur}
        contentEditable={isEditing}
        suppressContentEditableWarning={true}>
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </div>
  );
};

export default SkillsSection;
