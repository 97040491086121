import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./coursetemplate2.css";

const Coursetemplate2Content = ({ title, courseContent }) => {
  const [expanded, setExpanded] = useState([]); // State to track expanded items
  const [expand, setExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Toggle expand/collapse (subaccordion)
  //--------------------------------------------------------------------------------

  const handleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      if (newExpanded.includes(index)) {
        newExpanded.splice(newExpanded.indexOf(index), 1);
      } else {
        newExpanded.push(index);
      }
      return newExpanded;
    });
  };

  //--------------------------------------------------------------------------------
  //Toggle the expand the container
  //--------------------------------------------------------------------------------

  const handleExpandMain = () => {
    setExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            expand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpandMain}
            >
              <div className="coursetemplete2-title">{title}</div>
              <IconButton>
                {expand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="course-template-expand-icon-color" />
                )}
              </IconButton>
            </div>
          </div>
          <div>
            {/* ______________________________Header textfiled and Textarea_____________ */}

            {/* ______________________________sub accrodion expand collaps_____________ */}
            {expand &&
              courseContent.map((item, index) => (
                <div
                key={index+1}
                  className={`coursetemplate2-subtitle-and-expand-container-overall ${
                    expanded.includes(index) ? "active" : ""
                  }`}
                >
                  <div
                    
                    className="coursetemplate2-title-and-expand-container"
                  >
                    <div
                      className="coursetemplate2-title-expandicon"
                      onClick={() => handleExpand(index)}
                    >
                      <div>
                        <div className="coursetemplete2-subtitle">
                          {item.header}
                        </div>
                      </div>
                      <div>
                        <IconButton>
                          {expanded.includes(index) ? (
                            <ExpandLessIcon className="course-template-expand-icon-color " />
                          ) : (
                            <ExpandMoreIcon className="course-template-expand-icon-color " />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  {/* ______________________________Text Render area container_____________ */}
                  {expanded.includes(index) && (
                    <div className="coursetemplate2-content-render-conatiner">
                      {item.points.map((point, pointIndex) => (
                        <div className="coursetemplate2-checkicon-and-points">
                          <div>
                            <FiberManualRecordIcon className="bulletpoints" />
                          </div>
                          &nbsp;
                        <div className="coursetemplate2-checkicon-and-points-content">  {point}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            {/* _______________________________end________________________ */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Coursetemplate2Content;
