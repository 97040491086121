import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { baseURL } from "../../http";

// Function to fetch Firebase configuration from backend API
async function fetchFirebaseConfig() {
  try {
    const response = await axios.get(baseURL + "/google-api"); // Adjust the URL to match your backend endpoint
    // console.log("Firebase config response:", response.data); // Log the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching Firebase configuration:", error);
    return null;
  }
}
// Initialize Firebase with fetched configuration
async function initializeFirebase() {
  const firebaseConfigjson = await fetchFirebaseConfig();
  if (firebaseConfigjson) {
    const app = initializeApp(firebaseConfigjson);
    // console.log(firebaseConfigjson);
    return getAuth(app);
  } else {
    console.error(
      "Failed to initialize Firebase: Firebase configuration not available.",
    );
    return null;
  }
}
// Call initializeFirebase function to initialize Firebase and export Authentication
const Authentication = await initializeFirebase();
export { Authentication };
