import React from "react";
import "./featurecard.css";

function FeatureCard1({ Heading, Content, Icon }) {
  return (
    <div className="feature-card1-flex-container">
      {/* __________________Imgae section_______________ */}
      <div className="icon-container">
        <Icon className="feature-icon" />
      </div>
      {/* ____________________________end_______________ */}
      {/* ______________________Heading content______________ */}
      <div className="featurecard1-content-container">
        <div className="featurecard1-heading">{Heading}</div>
        <div className="featurecard1-inside-content">{Content}</div>
      </div>
      {/* ____________________end____________________________ */}
    </div>
  );
}

export default FeatureCard1;
