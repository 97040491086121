import React, { useState, useEffect } from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Coming Soon</h1>
      <p className="coming-soon-subtitle">Our website is under construction. We'll be here soon with our new awesome site.</p>
    </div>
  );
};

export default ComingSoon;
