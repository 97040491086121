// ResumeMain.js
import React, { useState } from 'react';
import './Resume.css';
import ResumeMainContent from './ResumeRightSection.js/ResumeMainContent';
import ResumeSidebarMain from './ResumeSidebar.js/ResumeSidebarMain';

const ResumeMain = () => {
  //----------------------------------------------------------------
  // State handle
  //----------------------------------------------------------------
  const [profilePhoto, setProfilePhoto] = useState('');
  const [contactInfo, setContactInfo] = useState({
    email: "kavyagowda9008@gmail.com",
    phone: "+91 9632478497",
    location: "Bangalore",
  });
  const [education, setEducation] = useState([
    {
      id:1,
      year: "2019-2023",
      institution: "WARDIERE UNIVERSITY",
      degree: "Master of Business",
    },
    {
      id:2,
      year: "2017-2019",
      institution: "WARDIERE UNIVERSITY",
      degree: "Bachelor of Business",
    },
  ]);
  const [skills, setSkills] = useState(["JavaScript", "React", "CSS", "HTML"]);
  const [languages, setLanguages] = useState(["English", "Spanish"]);
  const [name, setName] = useState("RICHARD SANCHEZ");
  const [title, setTitle] = useState("MARKETING MANAGER");
  const [profile, setProfile] = useState("Dynamic and self-motivated, result-oriented marketing manager with more than seven years of total experience. Proven record of increasing sales by up to 50% through marketing initiatives. An active team member with excellent communication and people skills. Expert in nurturing innovative ideas and deriving success from diverse teams. Two-time winner of state-level excellence awards.");
  const [workExperience, setWorkExperience] = useState([
    {
      id: 1,
      companyName: "Borcelle Studio",
      designation: "Marketing Manager & Specialist",
      duration: "2021-2023",
      projects: [
        "Develop and execute comprehensive marketing strategies and campaigns that align with the company's goals and objectives.",
        "Lead, mentor, and manage a high-performing marketing team, fostering a collaborative and results-driven work environment.",
        "Monitor brand consistency across marketing channels and materials."
      ],
    },
    {
      id: 2,
      companyName: "Fauget Studio",
      designation: "Marketing Manager & Specialist",
      duration: "2019-2021",
      projects: [
        "Create and manage the marketing budget, ensuring efficient allocation of resources and optimizing ROI.",
        "Oversee market research to identify emerging trends, customer needs, and competitor strategies.",
        "Monitor brand consistency across marketing channels and materials."
      ],
    },
  ]);
  const [references, setReferences] = useState([
    {
      name: "John Doe",
      phone: "+91 9876543210",
      email: "johndoe@example.com"
    },
  ]);

    //----------------------------------------------------------------
  // Clone Education Item Function
  //----------------------------------------------------------------
  const cloneEducationItem = (id) => {
    const itemIndex = education.findIndex((edu) => edu.id === id);
    if (itemIndex !== -1) {
        const itemToClone = education[itemIndex];
        const newItem = {...itemToClone,id: education.length + 1};
        const updatedEducation = [
            ...education.slice(0, itemIndex + 1),
            newItem,
            ...education.slice(itemIndex + 1),
        ];
        setEducation(updatedEducation);
    }
};

const handleDeleteEducation = (id) => {
  if (education.length > 1) {
    const updatedEducation = education.filter((edu) => edu.id !== id);
    setEducation(updatedEducation);
  }
};

  return (
    <div className="resume-container">
      {/* Left Section */}
      <ResumeSidebarMain
        profilePhoto={profilePhoto}
        setProfilePhoto={setProfilePhoto}
        contactInfo={contactInfo}
        education={education}
        skills={skills}
        languages={languages}
        cloneEducationItem={cloneEducationItem}
        handleDeleteEducation={handleDeleteEducation}
      />

      {/* Right Section */}
      <ResumeMainContent
        name={name}
        title={title}
        profile={profile}
        workExperience={workExperience}
        references={references}
        showLogo={true}
      />
    </div>
  );
};

export default ResumeMain;
