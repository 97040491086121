import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import AllCourseRenderSection from "./AllCourseRenderSection";
import FilterListIcon from "@mui/icons-material/FilterList";
import { baseURL } from "../../http";
import "./allcourse.css";
import AllCourseSectionModal from "./AllCourseSectionModal";
import SearchIcon from "@mui/icons-material/Search";

//-------------------------------------------------------------------------
// Duration type options
//-------------------------------------------------------------------------
const durationOptions = ["Hours", "Weeks", "Months"];

function AllCourseSection() {
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [structuredData, setStructuredData] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  
  const [selectedDuration, setSelectedDuration] = useState({
    value: "",
    unit: "Hours",
  });
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [ratingFilter, setRatingFilter] = useState([]);
  const [priceFilter, setPriceFilter] = useState({ free: false, paid: false });
  const [sectionFilter, setSectionFilter] = useState(null);
  const [open, setOpen] = useState(false);
  const [zIndex, setZIndex] = useState(0); // State to manage the z-index

  //-------------------------------------------------------------------------
  //Open and Close the filter modal in the Mobile View
  //-------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //-------------------------------------------------------------------------
  //clear all functionality block(reset filter)
  //-------------------------------------------------------------------------
  function ClearFilter() {
    setRatingFilter([]);

    setSectionFilter(null);
    setPriceFilter({ free: false, paid: false });
    setSelectedDuration({
      value: "",
      unit: "Hours",
    });
    setSelectedCategory(null); // Reset category
    setSelectedSubcategory(null); // Reset subcategory
    setSearchQuery("");
  }
  //-------------------------------------------------------------------------
  //call the clear function
  //-------------------------------------------------------------------------
  const handleClickFilter = () => {
    ClearFilter();
  };

  //-------------------------------------------------------------------------
  //Call api for fetch all the data(coursename and courses)
  //-------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesResponse, detailsResponse] = await Promise.all([
          axios.get(`${baseURL}/getcourse`),
          axios.get(`${baseURL}/coursepage_fetch_all`),
        ]);
        const coursesData = coursesResponse.data;
        const detailsData = detailsResponse.data;
        if (coursesData && detailsData) {
          const filteredCourses = coursesData.filter(
            (item) => item.isPublished === "1"
          );
          const detailsMap = detailsData.reduce((map, item) => {
            if (!map[item.CourseName]) {
              map[item.CourseName] = item;
            }
            return map;
          }, {});

          const combinedData = filteredCourses.map((course) => ({
            ...course,
            ...detailsMap[course.CourseName],
          }));

          const categoryMap = {};
          combinedData.forEach((item) => {
            if (!categoryMap[item.Category]) {
              categoryMap[item.Category] = {};
            }
            if (!categoryMap[item.Category][item.SubCategory]) {
              categoryMap[item.Category][item.SubCategory] = [];
            }
            categoryMap[item.Category][item.SubCategory].push(item);
          });

          setStructuredData(categoryMap);
          setCategories(Object.keys(categoryMap));
          setAllCoursesData(combinedData);
        } else {
          setStructuredData({});
        }
      } catch (error) {
        setStructuredData({});
      }
    };

    fetchData();
  }, []);

  //-------------------------------------------------------------------------
  //Filter the couses based on the categories and subcategories
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (selectedCategory && structuredData[selectedCategory]) {
      setSubcategories(Object.keys(structuredData[selectedCategory]));
      setSelectedSubcategory(null);
    } else {
      setSubcategories([]);
      setSelectedSubcategory(null);
    }
  }, [selectedCategory, structuredData]);

  //-------------------------------------------------------------------------
  //Handle categories dropdown handle fuction
  //-------------------------------------------------------------------------
  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  //-------------------------------------------------------------------------
  //Handle subcategories dropdown handle fuction
  //-------------------------------------------------------------------------
  const handleSubcategoryChange = (event, newValue) => {
    setSelectedSubcategory(newValue);
  };

  //-------------------------------------------------------------------------
  //Handle Rating change handle fuction
  //-------------------------------------------------------------------------
  const handleRatingChange = (event) => {
    const value = parseFloat(event.target.value);
    setRatingFilter((prev) => {
      if (event.target.checked) {
        return [...prev, value];
      } else {
        return prev.filter((rating) => rating !== value);
      }
    });
  };

  //-------------------------------------------------------------------------
  //Handle price change handle fuction
  //-------------------------------------------------------------------------
  const handlePriceChange = (event) => {
    const { name, checked } = event.target;
    setPriceFilter((prev) => ({ ...prev, [name]: checked }));
  };



  //-------------------------------------------------------------------------
  //Handle section change handle fuction
  //-------------------------------------------------------------------------
  const parseSectionRange = (sectionFilter) => {
    const [minSections, maxSections] = sectionFilter.split("-").map((part) => {
      if (part === "+") return Infinity;
      return parseInt(part, 10);
    });
    return [minSections, maxSections];
  };

  //-------------------------------------------------------------------------
  //Handle section duration handle fuction
  //-------------------------------------------------------------------------
  const parseDurationFilter = (duration) => {
    const { value, unit } = duration;
    return { value: parseInt(value, 10), unit };
  };

  //-------------------------------------------------------------------------
  //Handle search change fuction
  //-------------------------------------------------------------------------
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  //-------------------------------------------------------------------------
  //Handle complete all filter functions
  //-------------------------------------------------------------------------
  const getFilteredCourses = () => {
    let filteredCourses = [];
    // Apply category and subcategory filters first
    if (selectedCategory) {
      if (selectedSubcategory) {
        filteredCourses =
          structuredData[selectedCategory]?.[selectedSubcategory] || [];
      } else {
        const subcategoryCourses = structuredData[selectedCategory] || {};
        filteredCourses = Object.values(subcategoryCourses).flat();
      }
    } else {
      filteredCourses = allCoursesData; // Fallback to all courses if no category selected
    }
    // Apply rating filter
    if (ratingFilter.length > 0) {
      filteredCourses = filteredCourses.filter((course) => {
        const rating = parseFloat(course.Rating);
        return ratingFilter.some(
          (filter) => rating >= filter && rating < filter + 0.5
        );
      });
    }
    // Apply price filter
    if (priceFilter.free || priceFilter.paid) {
      filteredCourses = filteredCourses.filter((course) => {
        if (priceFilter.free && course.CoursePrice === "Free") return true;
        if (priceFilter.paid && course.CoursePrice !== "Free") return true;
        return false;
      });
    }
    // Apply section filter
    if (sectionFilter) {
      const [minSections, maxSections] = parseSectionRange(sectionFilter);
      filteredCourses = filteredCourses.filter((course) => {
        try {
          const courseContent = JSON.parse(course.CourseContent);
          const courseLength = Array.isArray(courseContent)
            ? courseContent.length
            : 0;
          return courseLength >= minSections && courseLength <= maxSections;
        } catch (error) {
          console.error("Error parsing CourseContent:", error);
          return false;
        }
      });
    }
    // Apply search filter
    if (searchQuery) {
      return (filteredCourses = filteredCourses.filter((course) =>
        course.CourseName.toLowerCase().includes(searchQuery)
      ));
    }
    // Apply duration filter
    if (selectedDuration.value && selectedDuration.unit) {
      const { value: durationValue, unit } =
        parseDurationFilter(selectedDuration);
      filteredCourses = filteredCourses.filter((course) => {
        const courseDuration = course.Duration;
        if (courseDuration) {
          const [courseValue, courseUnit] = courseDuration.split(" ");
          return (
            courseUnit === unit && parseInt(courseValue, 10) >= durationValue
          );
        }
        return false;
      });
    }
    // Remove duplicate courses based on CourseName
    const uniqueCourses = [];
    const courseNames = new Set();
    for (const course of filteredCourses) {
      if (!courseNames.has(course.CourseName)) {
        uniqueCourses.push(course);
        courseNames.add(course.CourseName);
      }
    }
    return uniqueCourses;
  };

  //-------------------------------------------------------------------------
  //call filter functions
  //-------------------------------------------------------------------------
  const filteredCourses = getFilteredCourses();

  //-------------------------------------------------------------------------
  //Based on the scroll the z-index will change
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(
        ".Search-and-cleae-filter-section-container"
      );
      if (element) {
        const topPosition = element.getBoundingClientRect().top;
        if (topPosition <= 10) {
          setZIndex(5); // Set z-index to 5 when it reaches 10px from the top
        } else {
          setZIndex(0); // Reset z-index to 0 when it's not in the desired position
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="allcoursesection-background-color">
      <div className="allcourse-section-width-container">
        {/* _____________heading and Button section___________ */}
        <div className="all-course-section-main-heading">All Courses</div>
        <div className="Search-and-cleae-filter-section-container" style={{ zIndex }}>
          <div
            className="clear-filter-section-more-review-button"
            onClick={handleClickFilter}
          >
            <FilterListIcon className="buttonicons-reset-filter" />
            &nbsp;Reset Filters
          </div>
          <div
            className="clear-filter-section-more-review-button1"
            onClick={handleOpen}
          >
            <FilterListIcon className="buttonicons-reset-filter1" />
            &nbsp;Filter
          </div>
          {/* _________________________end________________________ */}
          {/* ______________________Textfileds search____________ */}
          <div className="interview-question-header-main">
            <div className="interview-question-header-content">
              <div
                // ref={searchBarRef}
                className={`interview-question-header-search`}
              >
                <SearchIcon className="interview-search-icon" />
                <input
                  type="text"
                  placeholder="Search questions here"
                  className="interview-question-no-border"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          {/* <div className="search-filed-width-control-container">
            <TextField
              variant="outlined"
              placeholder="Search Course..."
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              className="seacrch-all-course"
            />
          </div> */}
        </div>

        <div className="all-course-section-main-flex-container">
          <div className="all-course-section-filter-section">
            {/* _____________________section text filed______________ */}
            {/* <div className="all-coures-sub-heading-section">Sections</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                fullWidth
                options={sectionOptions}
                value={selectedSection}
                onChange={handleSectionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select Duration"
                  />
                )}
              />
            </div> */}
            {/* __________________________categories textfield_______________ */}
            <div className="all-coures-sub-heading-section">Category</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                options={categories}
                value={selectedCategory}
                onChange={handleCategoryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select Category"
                  />
                )}
              />
            </div>
            {/* ______________________subcategories textfiled___________ */}
            <div className="all-coures-sub-heading-section">Subcategory</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                options={subcategories}
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select SubCategory"
                  />
                )}
              />
            </div>
            {/* _______________________________price section_____________________ */}
            <div className="all-coures-sub-heading-section">Price</div>
            <div className="all-course-section-filter-options">
              <FormControlLabel
                control={
                  <Checkbox
                    name="free"
                    checked={priceFilter.free}
                    onChange={handlePriceChange}
                  />
                }
                label="Free"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="paid"
                    checked={priceFilter.paid}
                    onChange={handlePriceChange}
                  />
                }
                label="Paid"
              />
            </div>
            {/* ________________________Duration section____________ */}
            <div className="all-coures-sub-heading-section">Duration</div>
            <div className="duration-flex-container">
              <TextField
                placeholder="Duration"
                label=""
                type="number"
                value={selectedDuration.value}
                onChange={(event) =>
                  setSelectedDuration((prev) => ({
                    ...prev,
                    value: event.target.value,
                  }))
                }
                fullWidth
              />
              <Autocomplete
                options={durationOptions}
                value={selectedDuration.unit}
                onChange={(event, newValue) =>
                  setSelectedDuration((prev) => ({
                    ...prev,
                    unit: newValue,
                  }))
                }
                renderInput={(params) => <TextField {...params} label="" />}
                disableClearable
                fullWidth
              />
            </div>
            {/* _____________________rating section_____________________ */}
            <div className="all-coures-sub-heading-section">Rating</div>
            <div className="all-course-section-filter-options">
              {[4.5, 4.0, 3.5, 3.0].map((rating) => (
                <FormControlLabel
                  key={rating}
                  control={
                    <Checkbox
                      value={rating}
                      checked={ratingFilter.includes(rating)}
                      onChange={handleRatingChange}
                    />
                  }
                  label={`${rating} Star and Up`}
                />
              ))}
            </div>
          </div>
          {/* ___________________course card showing section component_____________ */}
          <div className="all-course-section-render-section">
            <AllCourseRenderSection
              courses={filteredCourses}
              structuredData={structuredData}
            />
          </div>
          {/* _______________________filter modal for Mobile view_________________ */}
          <AllCourseSectionModal
            open={open}
            handleClose={handleClose}
            handleClickFilter={handleClickFilter}
            durationOptions={durationOptions}
            ratingFilter={ratingFilter}
            handleRatingChange={handleRatingChange}
            FilterListIcon={FilterListIcon}
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            handleSubcategoryChange={handleSubcategoryChange}
            priceFilter={priceFilter}
            handlePriceChange={handlePriceChange}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
          />
          {/* ____________________________End______________________ */}
        </div>
      </div>
    </div>
  );
}

export default AllCourseSection;
