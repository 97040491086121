import React, { useState, useEffect, useRef } from "react";
import Job_MainCard from "./Job_MainCard";
import "./JobPortal.css";
import HeaderMain from "./../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import JobDetails from "./JobDetails";
import JobHeader from "./JobHeader";
import BackToTop from "./../BacktoTop/BackToTop";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../http";
import PageLoading from "../PageLoading/PageLoading";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";

const JobMain = () => {
  //----------------------------------------------------------------
  // State Handle
  //----------------------------------------------------------------
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [experience, setExperience] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const jobDetailsRef = useRef(null);
  const headerRef = useRef(null); // Ref for the header

  //----------------------------------------------------------------
  // Fetch jobs from backend API
  //----------------------------------------------------------------
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${baseURL}/jobPost_fetch_all`);
        setJobs(response.data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  //----------------------------------------------------------------
  // Function for details page
  //----------------------------------------------------------------
  const handleCardClick = (job) => {
    if (selectedJob && job.id !== selectedJob.id) {
      setLoadingDetails(true);
      setTimeout(() => {
        setSelectedJob(job);
        setLoadingDetails(false);
        scrollToTopOfDetails();
      }, 800); // Simulate loading delay
    } else {
      setSelectedJob(job);
      scrollToTopOfDetails();
    }

    if (window.innerWidth <= 850) {
      navigate(`/job/${job.id}`);
    }
  };

  //----------------------------------------------------------------
  //Scroll to top of details
  //----------------------------------------------------------------
  const scrollToTopOfDetails = () => {
    if (jobDetailsRef.current) {
      const jobDetailsTop = jobDetailsRef.current.getBoundingClientRect().top + window.scrollY;
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
      const additionalPadding = 150; // Adjust this value as needed
      const scrollToPosition = jobDetailsTop - headerHeight - additionalPadding;

      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }
  };

  //----------------------------------------------------------------
  //Search functionality
  //----------------------------------------------------------------
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setLocation('');
    setExperience('');
};

  const handleLocationChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue && inputValue[inputValue.length - 1] !== ',') {
      if (e.nativeEvent.inputType === 'insertText' && e.nativeEvent.data === ' ') {
        setLocation(inputValue.trim() + ', ');
      } else if (e.nativeEvent.inputType === 'insertParagraph') {
        setLocation(inputValue.trim() + ', ');
      } else {
        setLocation(inputValue);
      }
    } else {
      setLocation(inputValue);
    }
  };


  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  //----------------------------------------------------------------
  // Filtering the results with AND logic for all three fields
  // AND logic across fields and OR logic within Designation/Company/Keyword, and Location
  //----------------------------------------------------------------
  const filteredJobs = jobs.filter((job) => {
    const designation = job.Designation ? job.Designation.toLowerCase() : '';
    const description = job.description ? job.description.toLowerCase() : '';
    const companyName = job.CompanyName ? job.CompanyName.toLowerCase() : '';

    const jobLocations = Array.isArray(job.JobLocations)
        ? job.JobLocations.map(loc => loc.toLowerCase())
        : JSON.parse(job.JobLocations).map(loc => loc.value.toLowerCase());

    const requiredKeyskills = Array.isArray(job.RequiredKeyskills) ? job.RequiredKeyskills.map(skill => skill.toLowerCase()) : [];

    const searchTerms = searchQuery.split(',').map(term => term.trim().toLowerCase()).filter(term => term.length > 0);
    const locationTerms = location.split(',').map(loc => loc.trim().toLowerCase()).filter(loc => loc.length > 0);

    const matchesSearchQuery = searchTerms.length === 0 || searchTerms.some(term =>
        designation.includes(term) ||
        description.includes(term) ||
        requiredKeyskills.some(skill => skill.includes(term)) ||
        companyName.includes(term)
    );

    const matchesLocation = locationTerms.length === 0 || locationTerms.some(loc =>
        jobLocations.some(jobLoc => jobLoc.includes(loc))
    );

    const matchesExperience = experience
        ? parseInt(experience, 10) >= parseInt(job.RequiredMinExperience, 10) &&
          parseInt(experience, 10) <= parseInt(job.RequiredMaxExperience, 10)
        : true;

    return matchesSearchQuery && matchesLocation && matchesExperience;
});


  //----------------------------------------------------------------
  // Page Loading
  //----------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      {/* _________________________Card rendering__________________________________ */}

      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
          <HeaderSmallBar />
          <HeaderMain ref={headerRef} />
          <JobHeader
            onSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            clearSearch={clearSearch}
            location={location}
            onLocationChange={handleLocationChange}
            experience={experience}
            onExperienceChange={handleExperienceChange}
          />
          <div className="job-portal-container">
            <div className={`job-list-container ${selectedJob ? 'job-list-shrink animate-zoomIn' : ''}`}>
              {filteredJobs.map((job) => (
                <Job_MainCard
                  key={job.id}
                  job={job}
                  onClick={() => handleCardClick(job)}
                  isSelected={selectedJob && job.id === selectedJob.id}
                />
              ))}
            </div>
            {selectedJob && window.innerWidth > 850 && (
              <div className={`job-details-container ${loadingDetails ? 'loading' : 'slide'}`} ref={jobDetailsRef}>
                {loadingDetails ? (
                  <div className="loading-spinner">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <JobDetails job={selectedJob} onClose={() => setSelectedJob(null)} />
                )}
              </div>
            )}
            {selectedJob && window.innerWidth <= 850 && (
              <div className="job-details-modal">
                <JobDetails job={selectedJob} onClose={() => setSelectedJob(null)} />
              </div>
            )}
          </div>
          <FooterMain />
          <BackToTop />
        </>
      )}
    </div>
  );
};

export default JobMain;
