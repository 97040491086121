import React, { useState } from "react";
import { InView } from 'react-intersection-observer';
import "./Corporate.css";
import overviewImage from "./CorporateImages/CorporateDescription.jpg"

function CorporateDescription() {
  const [inView, setInView] = useState(false);

  const handleChange = (inView) => {
    if (inView) {
      setInView(true);
    }
  };

  return (
    <div className="overview-main">
      <div className={`overview-container ${inView ? "animate" : ""}`}>
        <div className="overview-image">
          <img src={overviewImage} alt="Corporate Training Overview" />
        </div>
        <div className="overview-text">
          <h2 className="overview-title">What Our Corporate Training Offers</h2>
          <p className="overview-description">
            Our corporate training programs are designed to enhance the skills
            and productivity of your team. We offer specialized training
            tailored to the unique needs of corporate environments, focusing on
            both technical and soft skills. Our expert trainers bring real-world
            experience to deliver practical, hands-on training that can be
            immediately applied in the workplace. Whether you are looking to
            improve leadership capabilities, technical proficiency, or team
            collaboration, we have a program that fits your needs.
          </p>
        </div>
        <InView as="div" onChange={handleChange} threshold={0.3} />
      </div>
    </div>
  );
}

export default CorporateDescription;
