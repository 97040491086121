import React from "react";
import "./Corporate.css"; // Import the CSS file
import onlineTrainingImage from "./CorporateImages/online_mode.png"; // Ensure to use your own image paths
import onSiteTrainingImage from "./CorporateImages/onsite_mode.png";
import selfPacedVideosImage from "./CorporateImages/selfpaced_mode.png";

const CorporateTrainingModes = () => {
  return (
    <div className="modes-main">
      <div className="modes-container">
        <div className="modes-heading">
          <h2>Corporate Training Modes</h2>
          <p>Choose the training mode that best suits your needs</p>
        </div>
        <div className="modes-content">
          <div className="mode online-mode">
            <img src={onlineTrainingImage} alt="Online Training" />
            <h3>Online Training</h3>
            <p>Learn from anywhere with our live online training sessions.</p>
          </div>
          <div className="mode onsite-mode">
            <img src={onSiteTrainingImage} alt="On-site Training" />
            <h3>On-site Training</h3>
            <p>
              Get hands-on training at your location by our expert trainers.
            </p>
          </div>
          <div className="mode selfpace-mode">
            <img src={selfPacedVideosImage} alt="Self-paced Videos" />
            <h3>Self-paced Videos</h3>
            <p>Access our training videos and learn at your own pace.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTrainingModes;
