import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faCalendarAlt,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import "./Corporate.css";

const CorporateTrainingFeatures = () => {
  const features = [
    {
      text: "Customized Training",
      subText: "Tailored to your needs",
      icon: faCogs,
    },
    {
      text: "Flexible Scheduling",
      subText: "Convenient for your team",
      icon: faCalendarAlt,
    },
    {
      text: "Expert Instructors",
      subText: "Learn from the best",
      icon: faChalkboardTeacher,
    },
  ];

  return (
    <div className="corporate-features-main">
      <div className="corporate-training">
        <h2>Corporate Training Features</h2>
        <p>
          Enhance your team's skills with our comprehensive training programs.
        </p>
        <div className="features-cards">
          {features.map((feature, index) => (
            <div key={index} className="card">
              <FontAwesomeIcon icon={feature.icon} className="corporate-feature-icon" />
              <h3>{feature.text}</h3>
              <p className="sub-heading">{feature.subText}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CorporateTrainingFeatures;
