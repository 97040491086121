import React from "react";
import FAQ_image from "../TeachUSonTPA/TeachUSonTPA_Images/FAQ_image.jpg";
import FAQ_AccordionTemplate from "../Templates/FAQ_AccordionTemplate";
import "./coursetemplate2.css";

function CourseTemplateFaq({ faqData, headerName }) {
  //--------------------------------------------------------------------
  //  Initializing the Array Data for Accordion
  //--------------------------------------------------------------------

  return (
    <div className="course-template-Faq-section-background-color">
      <div className="course-template-Faq-section-conatiner-width-conatiner">
        <div className="course-template-Faq-section-heading-alignemnt">{headerName}</div>

        {/* _________________FAQ Template Calling with Props Start_______________ */}
        <div className="course-template-Faq-section-flex-container">
          <div className="course-template-Faq-section-right-sction">
            <img src={FAQ_image} alt='Faq_fetch_section_image'/>
          </div>
          <div className="course-template-Faq-section-left-sction">
            <FAQ_AccordionTemplate faqData={faqData} />
          </div>
        </div>

        {/* _________________FAQ Template Calling with Props End_________________ */}
      </div>
    </div>
  );
}

export default CourseTemplateFaq;
