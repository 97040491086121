import React, { useState,useEffect } from "react";
import StudentProfilePage from "./Pm_StudentProfilePage";
import './1ProfileManagement.css';
import PmScrollspy from "./NewScrollspy";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import HeaderMain from "../Header/HeaderMain";
import PageLoading from "../PageLoading/PageLoading";

function ProfileManagement() {

  const [PercentageUpdate, setPercentageUpdate] = useState(false);

  //--------------------------------------------------------------------------------
  // Fetching the Profile Percentage when changes in each tabs
  //--------------------------------------------------------------------------------
  const callPercentage = () => {
    setPercentageUpdate((prev) => !prev);
  }

   //-------------------------------------------------------------------------
  //Hook States
  //-------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);

   //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
     {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
     <HeaderSmallBar/>
     <HeaderMain/>
    <div  className="proflieContainer-background-color">
     <div className="profile-container-widh-container">
      <StudentProfilePage 
      PercentageUpdate={PercentageUpdate}
      />
      <PmScrollspy 
      callPercentage={callPercentage}
      />
      </div>
    </div>
    </>
      )}
    </>
  );
}

export default ProfileManagement;
