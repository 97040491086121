import React, { useEffect, useRef } from "react";
import "./AboutUS.css";
import missionImage from "./AboutImages/missionImage.png";

const Mission = () => {
  const missionRef = useRef(null);

  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          missionRef.current.classList.add("animate");
          observer.unobserve(missionRef.current);
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

    return () => {
      if (missionRef.current) {
        observer.unobserve(missionRef.current);
      }
    };
  }, []);

  return (
    /*_______________________________________our mission ____________________________*/
    <div className="mission-outside" ref={missionRef}>
      {/*_______________________________________our mission container ____________________________*/}
      <div className="mission-container">
        {/*_______________________________________our mission image ____________________________*/}
        <div className="mission-image">
          <img src={missionImage} alt="Our Mission" />
        </div>
        {/*_______________________________________our mission content ____________________________*/}
        <div className="mission-content">
          {/*_______________________________________our mission content heading ____________________________*/}
          <h2>Our Mission</h2>
          {/*_______________________________________our mission content paragraph ____________________________*/}
          <p>
            Our mission at TPA is to bridge the gap between theory and practice
            in engineering and technology. We provide the skills needed for
            success, fostering creativity and critical
          </p>
          {/*_______________________________________our mission content paragraph ____________________________*/}
          <p>
            We promote continuous learning to keep our students and partners at
            the forefront of technological advancements. TPA equips individuals
            with the skills to enhance their careers and contribute to their
            organizations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
