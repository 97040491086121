import React from "react";
import { useInView } from "react-intersection-observer";
import "./Internship.css";
import { FaChalkboardTeacher, FaUsers, FaTools } from "react-icons/fa";

const BenefitCard = ({ icon: Icon, title, description }) => {
  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    /*_______________________________________ Internship benefits____________________________*/
      <div ref={ref} className={`benefit-card ${inView ? "fade-in" : ""}`}>
        {/*_______________________________________ Internship benefits icon ____________________________*/}
        <Icon className="benefit-icon" />
        {/*_______________________________________ Internship benefits heading ____________________________*/}
        <h3>{title}</h3>
        {/*_______________________________________ Internship benefits paragraph ____________________________*/}
        <p>{description}</p>
      </div>
  );
};

const Benefits = () => (
  <div className="benefits_internship">
  {/*_______________________________________ Internship benefits section ____________________________*/}
  <section className="benefits-section">
    {/*_______________________________________ Internship benefits heading ____________________________*/}
    <h2>Benefits of Interning at TPA</h2>
    {/*_______________________________________ Internship benefits cards ____________________________*/}
    <div className="benefits-cards">
      {/*_______________________________________ Internship benefits 1st card ____________________________*/}
      <BenefitCard
        icon={FaChalkboardTeacher}
        title="Professional Development"
        description="Learn from industry experts."
      />
      {/*_______________________________________ Internship benefits 2nd card ____________________________*/}
      <BenefitCard
        icon={FaUsers}
        title="Networking Opportunities"
        description="Connect with professionals and peers."
      />
      {/*_______________________________________ Internship benefits 3rd card ____________________________*/}
      <BenefitCard
        icon={FaTools}
        title="Hands-On Experience"
        description="Work on real projects and gain practical skills."
      />
    </div>
  </section>
  </div>
);

export default Benefits;
