// ContactSection.js
import React, { useState } from 'react';
import '../Resume.css';

const ContactSection = ({ contactInfo }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (index) => {
    setIsEditing(index);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="sidebar-section">
      <h3 className="contact-title">CONTACT</h3>
      {['email', 'phone', 'location'].map((field, index) => (
        <p
          key={index}
          className={`editable ${isEditing === index ? 'is-editing' : ''}`}
          onClick={() => handleEditClick(index)}
          onBlur={handleBlur}
          contentEditable={isEditing === index}
          suppressContentEditableWarning={true}
        >
          {contactInfo[field]}
        </p>
      ))}
    </div>
  );
};

export default ContactSection;
