import React, { useState } from "react";
import "./Internship.css";
import Intern from "./InternImages/Intern.png";
import Modal from "./Modal.js";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal.js";

const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    college: "",
    course: "",
    startDate: "",
    endDate: "",
    marks: "percentage",
  });

  const handleApplyClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    closeModal();
  };
  return (
    /*_______________________________________ Internship hero section ____________________________*/
    <div className="hero_div">
      <section className="hero-section">
        {/*_______________________________________ Internship hero section content ____________________________*/}
        <div className="hero-content">
          {/*_______________________________________ Internship hero section content heading ____________________________*/}
          <h1>Kickstart Your Career with an Internship at TPA</h1>
          {/*_______________________________________ Internship hero section content paragraph ____________________________*/}
          <p>
            Talent Pool Academy seems to be positioned as a key player in skill
            development, helping individuals
            secure high-paying job opportunities. How can I support your goals
            with this platform?
          </p>
          <button className="apply-button" onClick={handleApplyClick}>
            Apply Now
          </button>
        </div>
        {/*_______________________________________ Internship hero section image ____________________________*/}
        <div className="hero-intern-image">
          <img src={Intern} alt="Internship at TPA" />
        </div>
      </section>
      <EnquiryFormModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        EnquiryHeading="Internship"
      />
    </div>
  );
};

export default HeroSection;
