import React, { useState } from "react";
import FAQ_image from "./TeachUSonTPA_Images/FAQ_image.jpg";
import FAQ_AccordionTemplate from "../Templates/FAQ_AccordionTemplate";
import "./TeachUSonTPA.css";

function Teach3InstructorFAQ({ faqData, headerName }) {
  //--------------------------------------------------------------------
  //  Initializing the Array Data for Accordion
  //--------------------------------------------------------------------

  return (
    <div className="Faq-section-background-color">
      <div className="Faq-section-conatiner-width-conatiner">
        <div className="Faq-section-heading-alignemnt">{headerName}</div>

        {/* _________________FAQ Template Calling with Props Start_______________ */}
        <div className="Faq-section-flex-container">
          <div className="Faq-section-right-sction">
            <img src={FAQ_image} />
          </div>
          <div className="Faq-section-left-sction">
            <FAQ_AccordionTemplate faqData={faqData} />
          </div>
        </div>

        {/* _________________FAQ Template Calling with Props End_________________ */}
      </div>
    </div>
  );
}

export default Teach3InstructorFAQ;
