import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseCard from "../../CourseCard/CourseCard";
import { useNavigate } from "react-router-dom";
import "./homecoursecardrender.css";

//-------------------------------------------------------------------------
//carousel responsive card render
//-------------------------------------------------------------------------
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1100 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1100, min: 860 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 860, min: 550 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
  },
};

function HomeCourseCardReder({ structuredData }) {
  const categories = Object.keys(structuredData);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    categories.length ? Object.keys(structuredData[categories[0]])[0] : ""
  );
  const navigate = useNavigate();

  //-------------------------------------------------------------------------
  //function to Filter the course based on the category
  //-------------------------------------------------------------------------
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(Object.keys(structuredData[category])[0]);
  };

  //-------------------------------------------------------------------------
  //function to Filter the course based on the subcategory
  //-------------------------------------------------------------------------
  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  //-------------------------------------------------------------------------
  //function to Filter the course handleToRouteAllCourse
  //-------------------------------------------------------------------------
  const handleToRouteAllCourse = () => {
    navigate('/AllCourse');
  };

  //-------------------------------------------------------------------------
  //Handle function to route the Course
  //-------------------------------------------------------------------------
  const handleCourseClick = (courseName) => {
    navigate(`/course/${selectedCategory}/${courseName}`);
  };

  return (
    <div className="home-card-render-section-background-color">
      <div className="home-card-render-section-width-control-container">
        <div className="Header-and-tagline-conatiner">
          <div className="course-header-and-button">
            {/* _______________________Heading_________________________ */}
            <div className="course-card-render-header">
              Skills at Your Fingertips
            </div>
            {/* __________________all course button______________________ */}
            <div className="home-course-section-more-review-button" onClick={handleToRouteAllCourse}>
              All Courses
            </div>
          </div>
          {/* ______________________Tagline____________________________ */}
          <div className="course-card-render-tagline">
            Develop both critical and technical skills with <strong>TPA</strong>{" "}
            to achieve your professional goals
          </div>
        </div>

        {/*_____________________Categories Tabs_______________________ */}
        <div className="tab-container">
          {categories.length > 0 ? (
            categories.map((category) => (
              <div
                key={category}
                className={`tab-item ${
                  category === selectedCategory ? "active" : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </div>
            ))
          ) : (
            <div className="no-courses-message">No categories available</div>
          )}
        </div>

        {/*_____________________Subcategories Tabs___________________________ */}
        {selectedCategory && structuredData[selectedCategory] ? (
          <div className="subcategory-container">
            {Object.keys(structuredData[selectedCategory]).map(
              (subcategory) => (
                <div
                  key={subcategory}
                  className={`subcategory-item ${
                    subcategory === selectedSubcategory ? "active" : ""
                  }`}
                  onClick={() => handleSubcategoryClick(subcategory)}
                >
                  {subcategory}
                </div>
              )
            )}
          </div>
        ) : (
          <div className="no-courses-message">No subcategories available</div>
        )}

        {/*_____________________ Carousel of Course Cards_________________________ */}
        {selectedCategory &&
        structuredData[selectedCategory] &&
        structuredData[selectedCategory][selectedSubcategory] &&
        structuredData[selectedCategory][selectedSubcategory].length > 0 ? (
          <div >
            <Carousel
              showDots={true}
              renderDotsOutside={false}
              itemClass="carousel-item-padding-40-px"
              autoPlaySpeed={1500}
              loop={true}
              autoPlay={true}
              infinite={true}
              responsive={responsive}
            >
              {structuredData[selectedCategory][selectedSubcategory].map(
                (course, index) => (
                  <div className="home-course-select-card-click-render" key={index} onClick={() => handleCourseClick(course)}>
                    <CourseCard courseName={course} />
                  </div>
                )
              )}
            </Carousel>
          </div>
        ) : (
          <div className="no-courses-message">No courses available</div>
        )}
      </div>
    </div>
  );
}

export default HomeCourseCardReder;
