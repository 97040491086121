const languages = [
    { title: "Ada", value: "ada" },
    { title: "C", value: "c" },
    { title: "C#", value: "csharp" },
    { title: "C++", value: "cpp" },
    { title: "COBOL", value: "cobol" },
    { title: "CSS", value: "css" },
    { title: "Dart", value: "dart" },
    { title: "Elixir", value: "elixir" },
    { title: "Erlang", value: "erlang" },
    { title: "F#", value: "fsharp" },
    { title: "Fortran", value: "fortran" },
    { title: "Go", value: "go" },
    { title: "Groovy", value: "groovy" },
    { title: "Haskell", value: "haskell" },
    { title: "HTML", value: "html" },
    { title: "Java", value: "java" },
    { title: "JavaScript", value: "javascript" },
    { title: "Julia", value: "julia" },
    { title: "Kotlin", value: "kotlin" },
    { title: "Lisp", value: "lisp" },
    { title: "Lua", value: "lua" },
    { title: "MATLAB", value: "matlab" },
    { title: "MongoDB", value: "mongodb" },
    { title: "Node.js", value: "nodejs" },
    { title: "Objective-C", value: "objective-c" },
    { title: "OCaml", value: "ocaml" },
    { title: "Pascal", value: "pascal" },
    { title: "Perl", value: "perl" },
    { title: "PHP", value: "php" },
    { title: "Prolog", value: "prolog" },
    { title: "Python", value: "python" },
    { title: "R", value: "r" },
    { title: "React", value: "react" },
    { title: "Ruby", value: "ruby" },
    { title: "Rust", value: "rust" },
    { title: "SAS", value: "sas" },
    { title: "Scala", value: "scala" },
    { title: "Scheme", value: "scheme" },
    { title: "Scratch", value: "scratch" },
    { title: "Shell", value: "shell" },
    { title: "SQL", value: "sql" },
    { title: "Swift", value: "swift" },
    { title: "TypeScript", value: "typescript" },
    { title: "VBA", value: "vba" },
  ];

  export const ProgramLanguage=languages;