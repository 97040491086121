import React, { useState, useRef, useEffect } from 'react';
import './JobPortal.css';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

const JobHeader = ({ onSearchChange, searchQuery, clearSearch, onExperienceChange, experience, onLocationChange, location }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [filteredDesignations, setFilteredDesignations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [filteredExperiences, setFilteredExperiences] = useState([]);
    const [experienceInput, setExperienceInput] = useState('');
    const searchBarRef = useRef(null);

    const handleSearchClick = () => {
        if (searchQuery && !searchQuery.trim().endsWith(',')) {
            onSearchChange({ target: { value: `${searchQuery.trim()}, ` } });
        }
        setIsExpanded(true);
    };

    const filterLocations = (searchTerms) => {
        const filtered = locationOptions.filter((loc) =>
            searchTerms.some(term => loc.toLowerCase().includes(term))
        );
        setFilteredLocations(filtered);
    };

    const handleSearchSubmit = () => {
        const searchTerms = searchQuery.split(',').map(term => term.trim().toLowerCase()).filter(term => term.length > 0);
        filterDesignations(searchTerms);
        filterLocations(searchTerms); // Correctly call filterLocations here
        setIsExpanded(false);
    };

    const handleClickOutside = (event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        if (isExpanded) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isExpanded]);

    const designationOptions = [
        'Software Engineer',
        'Product Manager',
        'Data Scientist',
        'Designer'
    ];

    const locationOptions = [
        'Agra', 'Ajmer', 'Aligarh', 'Amravati', 'Amritsar', 'Anand', 'Asansol', 'Aurangabad', // etc...
    ];

    const experienceOptions = Array.from({ length: 26 }, (_, i) => `${i} Years`);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        onSearchChange(e);
    };

    const filterDesignations = (searchTerms) => {
        const filtered = designationOptions.filter((designation) =>
            searchTerms.some(term => designation.toLowerCase().includes(term))
        );
        setFilteredDesignations(filtered);
    };

    const handleLocationChange = (e) => {
        const value = e.target.value;
        onLocationChange(e);
        const filtered = locationOptions.filter((loc) =>
            loc.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredLocations(filtered);
    };

    const handleExperienceChange = (e) => {
        const value = e.target.value;
        setExperienceInput(value);
        const filtered = experienceOptions.filter((exp) =>
            exp.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredExperiences(filtered);
        onExperienceChange(e);
    };

    const handleDesignationClick = (designation) => {
        const updatedQuery = `${searchQuery.trim()}, ${designation}`.trim();
        onSearchChange({ target: { value: updatedQuery } });
        setFilteredDesignations([]);
    };

    const handleLocationClick = (loc) => {
        onLocationChange({ target: { value: loc } });
        setFilteredLocations([]);
    };

    const handleExperienceClick = (exp) => {
        setExperienceInput(exp);
        setFilteredExperiences([]);
        onExperienceChange({ target: { value: exp } });
    };

    return (
        <div className="header-main">
            <div className="header-content">
                <div
                    ref={searchBarRef}
                    className={`header-search ${isExpanded ? 'expanded' : ''}`}
                    onClick={handleSearchClick}
                >
                    <SearchIcon className="search-icon" />
                    <div className={`search-bar ${isExpanded ? 'expanded' : ''}`}>
                        {isExpanded ? (
                            <>
                                <div className="search-section full-width">
                                    <input
                                        type="text"
                                        placeholder="Enter Keyword/Designation/Companies"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="no-border"
                                    />
                                    {searchQuery && (
                                        <CancelIcon className="cancel-icon" onClick={clearSearch} />
                                    )}
                                    {filteredDesignations.length > 0 && (
                                        <ul className="suggestions-list">
                                            {filteredDesignations.map((designation, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleDesignationClick(designation)}
                                                >
                                                    {designation}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="vertical-line"></div>
                                <div className="search-section full-width">
                                    <input
                                        type="text"
                                        placeholder="Enter Experience"
                                        value={experienceInput}
                                        onChange={handleExperienceChange}
                                        className="no-border"
                                    />
                                    {experienceInput && (
                                        <CancelIcon className="cancel-icon" onClick={() => setExperienceInput('')} />
                                    )}
                                    {filteredExperiences.length > 0 && (
                                        <ul className="suggestions-list">
                                            {filteredExperiences.map((exp, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleExperienceClick(exp)}
                                                >
                                                    {exp}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="vertical-line"></div>
                                <div className="search-section full-width">
                                    <input
                                        type="text"
                                        placeholder="Enter Location"
                                        value={location}
                                        onChange={handleLocationChange}
                                        className="no-border"
                                    />
                                    {location && (
                                        <CancelIcon className="cancel-icon" onClick={() => onLocationChange({ target: { value: '' } })} />
                                    )}
                                    {filteredLocations.length > 0 && (
                                        <ul className="suggestions-list">
                                            {filteredLocations.map((loc, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleLocationClick(loc)}
                                                >
                                                    {loc}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </>
                        ) : (
                            <input
                                type="text"
                                placeholder="Search jobs here"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="no-border"
                            />
                        )}
                    </div>
                    <button className="search-button" onClick={handleSearchSubmit}>Search</button>
                </div>
            </div>
        </div>
    );
};

export default JobHeader;
