import React, {useEffect, useRef} from "react";
import "./Internship.css";
import about from "./InternImages/about_intern.png"

const AboutInternship = () => {

  const imageRef = useRef(null);

  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          imageRef.current.classList.add("animate");
          observer.unobserve(imageRef.current);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return(
  /*_______________________________________ Internship about ____________________________*/
  <div className="about-intern-outside">
    {/*_______________________________________About content container____________________________*/}
    <div className="about-intern-container">
      {/*_______________________________________About content image ____________________________*/}
      <div className="about-inter-image" ref={imageRef}>
        <img src={about} alt="About TPA" />
      </div>
      {/*_______________________________________About content ____________________________*/}
      <div className="about-intern-content">
        {/*_______________________________________About content heading ____________________________*/}
        <h2>About the Internship</h2>
        {/*_______________________________________About content paragraph ____________________________*/}
        <p>
          TPA internship program is designed for students or those seeking
          practical experience.
        </p>
        {/*_______________________________________About content paragraph ____________________________*/}
        <p>
          You'll work alongside professionals in a real work environment to
          apply your knowledge. Tasks may include research, administrative
          duties, or assisting with projects.
        </p>
        {/*_______________________________________About content paragraph ____________________________*/}
        <p>
          You'll receive guidance and feedback from experienced professionals to
          help you grow. Internships are valuable for gaining hands-on
          experience and exploring future career options.
        </p>
      </div>
    </div>
  </div>
  );
};

export default AboutInternship;
