// ResumeReferences.js
import React, { useState } from 'react';
import '../Resume.css';

const ResumeReferences = ({ references }) => {
  const [isEditing, setIsEditing] = useState(null);

  const handleEditClick = (field) => {
    setIsEditing(field);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <div className="section">
      <h3 className="references-title">REFERENCES</h3>
      <p className={`editable ${isEditing === 'references' ? 'is-editing' : ''}`}
        onClick={() => handleEditClick('references')}
        onBlur={handleBlur}
        contentEditable={isEditing === 'references'}
        suppressContentEditableWarning={true}>
        {references.map((ref, index) => (
          <div key={index} className="reference">
            <h4>{ref.name}</h4>
            <p>Phone: {ref.phone}</p>
            <p>Email: {ref.email}</p>
          </div>
        ))}
      </p>
    </div>
  );
};

export default ResumeReferences;
