import React from "react";
import "./ourvision.css";

function OurVision() {
  return (
    <div className="ourvision-container-background-color">
      <div className="ourvision-container">
        {/* _________________________header____________________ */}
        <div className="ourvision-heading">Why We're the Best Choice</div>
        {/* _______________________Content______________________ */}
        <div className="ourvision-content">
          Choose Talent Pool Academy for top-tier education led by industry experts, offering personalized mentorship, real-world project experience, and lifelong career support.
          Our affordable, high-quality programs are designed to meet current market demands, ensuring you’re industry-ready with the skills and network to secure high-paying jobs with ease
        </div>
        {/* ____________________end____________________________ */}
      </div>
    </div>
  );
}

export default OurVision;
