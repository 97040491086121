import React, { useState } from "react";
import Enquirysectionimage from "../EnquirySection/EnquirySectionImages/Enquirysection.png";
import "./coursetemplate2.css";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router-dom";

function CourseEnquirySection() {
  const [open, setOpen] = useState(false);
  const { courseName } = useParams();

  //-------------------------------------------------------------------------
  //Handle the Modal open function
  //-------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="course-enquiry-section-bakground-color">
        <div className="course-enquiry-section-container">
          <div className="course-enquiry-section-flex-container">
            {/* ___________enquiry section image _____________ */}
            <div className="course-enquiry-section-image-conatiner">
              <img src={Enquirysectionimage} alt="Enquriry_section" />
            </div>
            <div className="course-enquiry-section-Button-section">
              {/* _______________content_________________ */}
              <div className="course-enquiry-section-heading-section">
                Still have questions?
              </div>
              <div className="course-enquiry-section-points">
                <div className="course-enquiry-section-point-icons">
                  <CheckCircleOutlineIcon className="course-buttoniconspoints-enquiry" />
                  Get detailed information about our courses.
                </div>
                <div className="course-enquiry-section-point-icons">
                  <CheckCircleOutlineIcon className="course-buttoniconspoints-enquiry" />
                  Receive personalized assistance from our support team.
                </div>
              </div>
              {/* ______________________end_________________________ */}
              {/* ________________________Enquiry Button______________________ */}
              <div className="course-button-container" onClick={handleOpen}>
                Enquiry Now{" "}
                <QuestionMarkIcon className="course-buttonicons-common-enquiry" />
              </div>
              {/* ___________________________end___________________________ */}
            </div>
          </div>
        </div>
      </div>

      {/* ____________________________Enquiry form componet call______________ */}

      <EnquiryFormModal
        open={open}
        setOpen={setOpen}
        EnquiryHeading={courseName}
      />
      {/* ____________________________________end_______________________________ */}
    </>
  );
}

export default CourseEnquirySection;
