import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { baseURL } from "../../http";
import Stscroll11CareerProfileModal from "./PmTab11CareerProfileModal";

function Stscroll11CareerProfile({ callPercentage }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [modalStatus, setModalStatus] = useState(false);

  const [currentIndustry, setCurrentIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [roleCategory, setRoleCategory] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [desiredJobType, setDesiredJobType] = useState("");
  const [desiredEmploymentType, setDesiredEmploymentType] = useState("");
  const [preferredShift, setPreferredShift] = useState("");
  const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);
  const [buttonName, setButtonName] = useState("Save");

  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  function toggleModal() {
    setModalStatus(!modalStatus);
  }

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  async function fetchData() {
    try {
      const response = await fetch(`${baseURL}/getCareerProfile/${userEmail}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentIndustry(data.currentIndustry || "");
        setDepartment(data.department || "");
        setRoleCategory(data.roleCategory || "");
        setJobRole(data.jobRole || "");

        setDesiredJobType(data.desiredJobType || "");
        setDesiredEmploymentType(data.desiredEmploymentType || "");
        setPreferredShift(data.preferredShift || "");
        // setPreferredWorkLocation(data.preferredWorkLocation || "");
        setPreferredWorkLocation(
          data.preferredWorkLocation
            ? JSON.parse(data.preferredWorkLocation)
            : []
        );

        // setA(data.preferredWorkLocation);
        // setB(JSON.parse(data.preferredWorkLocation))
        if (data.currentIndustry == null) {
          setButtonName("Save");
        } else {
          setButtonName("Update");
        }
      } else {
        console.error("Error fetching user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
  return (
    <div>
      {/* ___________________________________ Tab Heading _______________________________ */}
      <div className="pmTabHeadings2" >
        Career Profile{" "}
        <Tooltip title="Edit" arrow>
          <IconButton onClick={toggleModal}>
            <EditIcon className="pmEditIcon" />
          </IconButton>
        </Tooltip>
      </div>


      {/* ___________________________________ Profile  render __________________________ */}
      < >
        {/* ____________________ Current Industry & Department ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Current Industry</label>
            <br></br>
            <span className="pmDataLabel">{currentIndustry || ""}</span>
          </div>

          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Department</label>
            <br></br>
            <span className="pmDataLabel">{department || ""}</span>
          </div>
        </div>

        {/* ____________________ Role Category & Job Role ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Role Category</label>
            <br />
            <span className="pmDataLabel">{roleCategory}</span>
          </div>

          <div className="pmBasFields">
            <label className="pm-personal-data-text-alignment">Job Role</label>
            <br></br>
            <span className="pmDataLabel">{jobRole || ""}</span>
          </div>
        </div>

        {/* ____________________ Desired Job type & Employment type ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Desired Job type</label>
            <br></br>
            <span className="pmDataLabel">{desiredJobType || ""}</span>
          </div>

          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Desired Employment type</label>
            <br></br>
            <span className="pmDataLabel">{desiredEmploymentType || ""}</span>
          </div>
        </div>

        {/* ____________________ Preferred Shift & Work Location ___________________________ */}
        <div className="PMCarProfileRender">
          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Preferred Shift</label>
            <br></br>
            <span className="pmDataLabel">{preferredShift || ""}</span>
          </div>

          <div className="pmBasFields">
          <label className="pm-personal-data-text-alignment">Preferred Work Location</label>
            <br></br>
            <span className="pmDataLabel">
              {/*  DB = [{"value":"Raipur","label":"Raipur"}] check in response with parsed */}
              {Array.isArray(preferredWorkLocation) &&
              preferredWorkLocation.length > 0
                ? preferredWorkLocation
                    .map((location) => location.label)
                    .join(", ")
                : ""}
            </span>
          </div>
        </div>


      </>
    

      {/* ___________________________________ Add Modal code starts   ____________________ */}
      <Modal
        open={modalStatus}
        onClose={setModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          <IconButton
            id="button-zoom"
            className="modal-close-iconButton-right-top-corner"
            onClick={toggleModal}
          >
            <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
          </IconButton>

          {/* _________________________________    Component Call  ___________________________ */}
          <Stscroll11CareerProfileModal
            togglingModal={toggleModal}
            fetchData={fetchData}
            buttonName={buttonName}
            callPercentage={callPercentage}
          />
        </div>
      </Modal>
      {/* ___________________________________ Add_Modal code Ends   ____________________________ */}
    </div>
  );
}

export default Stscroll11CareerProfile;
