import React, { useState } from "react";
import "./achivement.css";
import achivementimage from "./Achivement1.jpg";
import LanguageIcon from "@mui/icons-material/Language";
import ClassIcon from "@mui/icons-material/Class";
import { IconButton } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function AchivementSection() {
  const [startCounting, setStartCounting] = useState(false);
  //-------------------------------------------------------------------------
  //for run the couter after view of 20% section
  //-------------------------------------------------------------------------
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setStartCounting(true);
    }
  }, [inView]);

  //-------------------------------------------------------------------------
  //Genearl card function(achivement card)
  //-------------------------------------------------------------------------
  const renderCounter = (end, label) => {
    return (
      <div className="achivemet-single-container-design">
        <div className="achivement-icon-conatiner">
          <IconButton className="achivement-icon-button">
            {label === "Followers" && (
              <LanguageIcon className="achivement-icon" />
            )}
            {label === "Students" && <GroupsIcon className="achivement-icon" />}
            {label === "Classes" && <ClassIcon className="achivement-icon" />}
            {label === "Teachers" && (
              <BusinessCenterIcon className="achivement-icon" />
            )}
          </IconButton>
        </div>
        <div className="achivement-numbers">
          {startCounting ? <CountUp end={end} duration={2} suffix="+" /> : end}
        </div>
        <div className="achivement-heading">{label}</div>
      </div>
    );
  };

  return (
    <div className="achivement-section-background-color" ref={ref}>
      <div className="achivement-section-width-alignment-container">
        {/* ___________________Heading_________________________ */}
        <div className="achivement-heading-conatainer">Center Achievements</div>
        {/* _________________Tagline___________________________ */}
        <div className="achivement-render-tagline">
          Here you can review some statistics about our Education Center
        </div>
        {/* __________________Image section__________________ */}
        <div className="achivement-flex-container">
          <div className="achivement-section-image-container">
            <img src={achivementimage} alt="achivemets-excelence" />
          </div>
          {/* ______________General function call_______________ */}
          <div className="achivement-display-container">
            {renderCounter(540, "Followers")}
            {renderCounter(2124, "Students")}
            {renderCounter(2000, "Classes")}
            {renderCounter(15, "Teachers")}
          </div>
          {/* __________________end_____________________________ */}
        </div>
      </div>
    </div>
  );
}

export default AchivementSection;
