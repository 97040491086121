import React from "react";
import Avatar from "@mui/material/Avatar";
import "./reviewsection.css";

function ReviewCard({ Image, Name, Designation, Description }) {
  //--------------------------------------------------------------------------------
  // Function to extract the first character of the name
  //--------------------------------------------------------------------------------

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div className="review-card-conatiner2">
      <div className="review-avatat-name-designation-container2">
        {/* _________________Avatar section________________ */}
        <div>
          <Avatar
            alt={Name}
            src={Image}
            className="rating-avatar2"
            // style={{ backgroundColor: Image ? "transparent" : "#f0f0f0" }} // Optional: Set background color when image is not available
          >
            {!Image && getInitial(Name)} {/* Show initial if no image */}
          </Avatar>
        </div>
        {/* ____________________end_______________________ */}
        {/* _______________Name and Designation_________________ */}
        <div className="review-name-designation2">
          <div className="review-name2">{Name}</div>
          <div className="review-designation2">{Designation}</div>
        </div>
        {/* __________________end_______________________________ */}
      </div>
      {/* ________________________________content__________________________ */}
      <p className="review-description2">{Description}</p>
      {/* ________________________end______________________________ */}
    </div>
  );
}

export default ReviewCard;
