import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import axios from "axios";
import { baseURL } from "../../http";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import "./coursecard.css";

function CourseCard({ courseName }) {
  const [allCourses, setAllCourses] = useState([]);
  const [filteredCourse, setFilteredCourse] = useState(null);

  useEffect(() => {
    // Fetch all courses data once
    const fetchAllCourses = async () => {
      try {
        const response = await axios.get(`${baseURL}/coursepage_fetch_all`);
        setAllCourses(response.data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchAllCourses();
    console.log('couter')
  }, []);

  useEffect(() => {
    // Filter the course data based on courseName
    const course = allCourses.find((course) => course.CourseName === courseName);
    setFilteredCourse(course);
  }, [allCourses, courseName]);

  if (!filteredCourse) {
    return <div>Loading...</div>;
  }

  const numberOfSections = filteredCourse.CourseContent
    ? JSON.parse(filteredCourse.CourseContent).length
    : 0;


  return (
    
    <div className="couese-card-outer-container">
      {/* _________________Image section______________ */}
      <div className="course-card-image-section-container">
        <div className="course-card-best-seller-tag">{filteredCourse.StudentsEnrolled >1000 ? 'Best Seller':''}</div>
        <img src={filteredCourse.Image || ''} alt="course card" />
      </div>
      {/* _________________end______________ */}
      {/* _____________Corse Name____________ */}
      <div className="course-card-course-name-conatainer">
        {filteredCourse.CourseName || ''}	
      </div>
      {/* _________________end______________ */}
      {/* _____________course Rating ____________ */}
      {/* <div className="course-card-course-rating-container">
        <div className="course-card-course-rating-text-container">
          {" "}
          {courseData.Rating || ''}({courseData.StudentsEnrolled || ''} Review)
        </div>
        <Rating
          className="course-card-course-rating-star"
          name="simple-controlled"
          value={courseData.Rating || ''}
        />
      </div> */}
      {/* _________________end______________ */}
      {/* _____________course Hours/sections____________ */}
      <div className="course-card-course-hours-sections">
        <div className="course-card-hour">
          <AccessTimeIcon className="course-card-hour-icon" />
          &nbsp;
          <span className="course-card-hour-Text">
            {" "}
            {filteredCourse.Duration || ''} 
          </span>
        </div>
        <div className="course-card-hour">
          <MenuBookIcon className="course-card-hour-icon" />
          &nbsp;
          <span className="course-card-hour-Text">{numberOfSections} sections</span>
        </div>
      </div>
      {/* _________________end______________ */}
      {/* _____________course free/paid____________ */}
      {/* <div className="course-card-course-free-paid">
        <div className="course-price-section">{courseData.CoursePrice ?<>
              <CurrencyRupeeIcon className="course-card-rupees-icon" />
              {courseData.CoursePrice}
            </>: 'Free'}</div>
      </div> */}
      {/* _________________end______________ */}
    </div>
  );
}

export default CourseCard;
