import React from "react";
import "./HireTalent.css";
import MissionImage from "./HireTalentImages/HireTalent4.jpg";

function HireTalentMission() {
  
  return (
    <div className="mission-statement-main">
      <div className="mission-statement">
        <div className="mission-left">
          <img src={MissionImage} alt="Mission" />
        </div>
        <div className="mission-right">
          <h2>About Our Talents</h2>
          <p>
            At Talent Pool Academy, we take immense pride in nurturing
            top-tier professionals ready to excel in today's competitive
            industries. Our academy produces highly skilled individuals who are
            not only technically proficient but also adaptable, innovative, and
            equipped with a strong foundation in engineering principles and
            cutting-edge technologies. Our rigorous training programs focus on
            real-world applications, ensuring that our graduates possess the
            practical experience and problem-solving abilities required in the
            modern workplace. From CAD automation to software development, our
            talent pool is trained to tackle complex challenges with confidence
            and precision.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HireTalentMission;
