import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HeaderMain.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DropdownMenu = ({ categories, subCategories }) => {
  //-------------------------------------------------------------------------
  //States
  //-------------------------------------------------------------------------
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openSubTopics, setOpenSubTopics] = useState(null);
  const navigate = useNavigate();

  //-------------------------------------------------------------------------
  //Handle function  to route the Course
  //-------------------------------------------------------------------------
  const handleCourseClick = (course, category) => {
    navigate(`/course/${category}/${course}`);
  };

  return (
    <div className="dropdown">
      {/* _____________Drop down Button______________ */}
      <button className="dropdown-toggle">
        Categories <KeyboardArrowDownIcon className="downArrow" />
      </button>
      {/* _____________________Dropdown conatiner to display te categories________________ */}
      <div className="dropdown-menu">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`NavItem-common ${openSubMenu === category ? "active" : ""}`}
            onMouseEnter={() => setOpenSubMenu(category)}
            onMouseLeave={() => {
              setOpenSubMenu(null);
              setOpenSubTopics(null);
            }}
          >
            {category}
            <NavigateNextIcon className="navigate-next-icon" />
            {/* _________________________________End_____________________________ */}
            {/* ____________________submenu open container_______________ */}
            {openSubMenu === category && (
              <div className="sub-menu dropdown-menu">
                {Object.keys(subCategories[category]).map(
                  (subCategory, subIndex) => (
                    <div
                      key={subIndex}
                      className={`dropdown-section ${openSubTopics === subCategory ? "active" : ""}`}
                      onMouseEnter={() => setOpenSubTopics(subCategory)}
                      onMouseLeave={() => setOpenSubTopics(null)}
                    >
                      <div className="NavItem-common">
                        {subCategory}
                        <NavigateNextIcon className="navigate-next-icon" />
                      </div>
                      {/* _________________________________End_____________________________ */}
                      {/* ____________________dropdown for course name_____________________ */}
                      {openSubTopics === subCategory && (
                        <div className="sub-menu dropdown-menu">
                          {subCategories[category][subCategory].map(
                            (course, itemIndex) => (
                              <div
                                className="NavItem-common1"
                                key={itemIndex}
                                onClick={() =>
                                  handleCourseClick(course, category)
                                }
                              >
                                {course}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
              // _____________________________end___________________________________________//
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
