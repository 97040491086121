import React, { useState } from "react";
import { Modal, IconButton, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import EditorModal from "./EditorModal1";
import { Edit } from "@mui/icons-material";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import InterViewQuestionCard from "./InterViewQuestionCard1";

function InterViewQuestionAddQuestionModal({
  formData,
  setFormData,
  open1,
  handleCloseModal1,
  handleCloseModal,
  setSelectedOption,
}) {
  const [openEditor, setOpenEditor] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  //-------------------------------------------------------------------------
  // Open the editor
  //-------------------------------------------------------------------------
  const handleOpenEditor = () => {
    setOpenEditor(true);
  };

  //-------------------------------------------------------------------------
  // close editor
  //-------------------------------------------------------------------------
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setSelectedQuestion(null); // Clear selected question on close
  };

  //-------------------------------------------------------------------------
  // add or update the questions
  //-------------------------------------------------------------------------
  const addOrUpdateQuestion = (updatedQuestion) => {
    setQuestionsList((prevQuestions) => {
      const index = prevQuestions.findIndex((q) => q.id === updatedQuestion.id);
      if (index !== -1) {
        // Update existing question
        const newQuestions = [...prevQuestions];
        newQuestions[index] = updatedQuestion;
        return newQuestions;
      } else {
        // Add new question
        return [...prevQuestions, updatedQuestion];
      }
    });
  };

  //-------------------------------------------------------------------------
  // open
  //-------------------------------------------------------------------------
  const handleEditQuestion = (question) => {
    setSelectedQuestion(question); // Set the selected question for editing
    handleOpenEditor();
  };
  //-------------------------------------------------------------------------
  // save all the messages
  //-------------------------------------------------------------------------
  const handleSaveAllQuestions = async () => {
    try {
      // Prepare the data for the POST request
      const requestData = {
        Company: formData.Company,
        InterviewDate: formData.InterviewDate,
        Comment: formData.Comment,
        Designation: formData.Designation,
        Skills: formData.Skills,
        questions: questionsList.map((question) => ({
          id: question.id,
          Question: question.Question,
          Answer: question.Answer,
          HtmlContent: question.HtmlContent || "", // Add HtmlContent if needed
        })),
        UserId: localStorage.getItem("HTES_user_id"),
        IsApproved: true,
        Created_by: localStorage.getItem("HTES_user_id"),
        Created_date: new Date().toISOString(),
        Modified_by: localStorage.getItem("HTES_user_id"),
        Modified_date: new Date().toISOString(),
        UTC_Time: new Date().toISOString(),
      };

      // Validate requestData or specific fields
      if (!requestData.questions || requestData.questions.length === 0) {
        customAlert("", "Please add the Interview Questions", "warning");
        return; // Exit the function if no questions are provided
      }

      // Make the POST request with the JSON data
      const response = await axios.post(
        `${baseURL}/Interview_Questions_frontend`,
        [requestData],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check the response status
      if (response.status === 200) {
        customAlert(
          "",
          "All interview questions have been saved successfully!",
          "success"
        );
        handleCloseModal1(); // Close the modal after saving
        handleCloseModal(); // Close any other modals if needed
        // Reset the form data
        setSelectedOption([]);
        setFormData({
          Company: "",
          InterviewDate: "",
          Skills: [],
          Comment: "",
          Designation: "",
          Question: "",
          Answer: "",
        });
      } else {
        customAlert(
          "",
          response.data.error || "Error saving questions",
          "warning"
        );
      }
    } catch (error) {
      // Handle any errors that occur during the request
      customAlert("", "An unexpected error occurred", "error");
    }
  };

  return (
    <>
      <Modal
        open={open1}
        onClose={handleCloseModal1}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="interview-question-add-geninfo-modal"
      >
        <div className="interview-question-add-question-modal-container2">
          <div>
            <IconButton
              id="button-zoom"
              className="enquiry-form-addeducationalMapmodalcancel"
              onClick={handleCloseModal1}
            >
              <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          <div className="header-flex-container-modal2">
            <div className="enquiry-table-header-interview-question-add-modal">
              Add Interview Questions
            </div>
            <div className="add-question-container" id="button-zoom">
              <Button
                variant="contained"
                color="success"
                onClick={handleOpenEditor}
              >
                <AddIcon className="buttoniconcommon" />
                Add Question
              </Button>
            </div>
          </div>
          {/* ____________general inforamtion details____________ */}
          <div className="general-info-alignment-in-modal2">
            <div className="general-info-align-alihn-inside-content">
              <div className="footer-each-container-modal2">
                <div className="footer-each-conttainer-icons-modal2">
                  Interview Date:&nbsp;
                </div>
                <span className="details-information-span-modal2">
                  {formData.InterviewDate || ""}
                </span>
              </div>
              <div className="footer-each-container-modal2">
                <div className="footer-each-conttainer-icons-modal2">
                  Designation:&nbsp;
                </div>
                <span className="details-information-span-modal2">
                  {formData.Designation || ""}
                </span>
              </div>
              <div className="footer-each-container-modal2">
                <div className="footer-each-conttainer-icons-modal2">
                  Skills:&nbsp;
                </div>
                <span className="details-information-span-modal2">
                  {formData.Skills &&
                    formData.Skills.map((skill, index) => (
                      <span key={index} className="skill-item">
                        {skill}
                        {index < formData.Skills.length - 1 && ", "}
                      </span>
                    ))}
                </span>
              </div>
              <div className="footer-each-container-modal2">
                <div className="footer-each-conttainer-icons-modal2">
                  Company:&nbsp;
                </div>
                <span className="details-information-span-modal2">
                  {formData.Company || ""}
                </span>
              </div>
              <div className="footer-each-container-modal2">
                <Tooltip title="Edit GenInfo" arrow>
                  <IconButton onClick={handleCloseModal1}>
                    <Edit
                      className="buttoncommon-edit-geninfo"
                      color="primary"
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* _______________interview card_____________  */}
          <div className="add-the-question-card-render-section">
            {questionsList.map((item, index) => (
              <InterViewQuestionCard
                key={index}
                question={item}
                formData={formData}
                onEdit={() => handleEditQuestion(item)}
              />
            ))}
          </div>
          <div className="add-the-question-save-button-container">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSaveAllQuestions}
            >
              <SaveIcon className="buttoniconcommon" />
              Save All Questions
            </Button>
          </div>

          {/* Editor Modal for Adding/Editing Questions */}
          <EditorModal
            openEditor={openEditor}
            closeEditor={handleCloseEditor}
            addOrUpdateQuestion={addOrUpdateQuestion}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            handleCloseModal1={handleCloseModal1}
          />
        </div>
      </Modal>
    </>
  );
}

export default InterViewQuestionAddQuestionModal;
