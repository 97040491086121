import React, { useState, useEffect } from "react";
import "./coursetemplate2.css";
import Template2Header from "./Template2Header";
import Coursetemplate2Tabs from "./Coursetemplate2Tabs";
import axios from "axios";
import { baseURL } from "../../http";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import { useParams } from "react-router-dom";
import PageLoading from "../PageLoading/PageLoading";
import TalentPoolAcademy_logo from "../CoursePage/CourseImage/TalentPoolAcademy_logo.png";
import BackToTop from "../BacktoTop/BackToTop";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import CourseEnquirySection from "./CourseEnquirySection";


function CourseTemplateMainPage({ setCoursePage }) {
  const { courseName, category } = useParams();
  console.log('Component rendered with params:', { courseName, category });
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({category});
  const [image, setImage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [tags, setTags] = useState([]);
  const [courseDetails, setCourseDetails] = useState({
    duration: "",
    teachingMode: "",
    price: "",
    studentsEnrolled: "",
    rating: "",
  });

  const [courseTags, setCourseTags] = useState([]);
  const [objectivecontent, setObjectiveContent] = useState([]);
  const [skillscontent, setSkillsContent] = useState([]);
  const [requirementscontent, setRequirementsContent] = useState([]);
  const [learnersecontent, setLearnersContent] = useState([]);
  const [description, setDescription] = useState([]);
  const [courseContent, setCourseContent] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  console.log('loading',loading);
  //--------------------------------------------------------------------------------
  // Transform FAQs data to the desired format
  //--------------------------------------------------------------------------------

  const transformFAQs = (faqs) => {
    return faqs.map((faq) => ({
      question: faq.Question,
      answer: faq.Answer,
    }));
  };

  //--------------------------------------------------------------------------------
  // Fetch all course data on component mount
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchAllCoursesData = async () => {
      try {
        const response = await axios.get(`${baseURL}/coursepage_fetch_all`);
        const data = response.data;
        setAllCoursesData(data);
      } catch (error) {
        console.error("Error fetching all course data:", error);
      }
    };

    fetchAllCoursesData();
  }, []);

  //--------------------------------------------------------------------------------
  // Filter course data based on courseName
  //--------------------------------------------------------------------------------

  useEffect(() => {
    
    setSelectedCategory(category)
    if (allCoursesData.length > 0 && courseName) {
      const filteredCourse = allCoursesData.find(
        (course) => course.CourseName === courseName
      );
      if (filteredCourse) {
        setSelectedCourse(filteredCourse.CourseName);
        
        setImage(filteredCourse.Image);
        setCourseDetails({
          duration: filteredCourse.Duration,
          teachingMode: filteredCourse.TeachingMode,
          price: filteredCourse.CoursePrice,
          studentsEnrolled: filteredCourse.StudentsEnrolled,
          rating: filteredCourse.Rating,
        });
        setCourseTags(JSON.parse(filteredCourse.CourseTags));
        setSelectedCategories(
          JSON.parse(filteredCourse.FAQsSelectedCategories)
        );
        setObjectiveContent(JSON.parse(filteredCourse.Objective));
        setSkillsContent(JSON.parse(filteredCourse.Skills));
        setRequirementsContent(JSON.parse(filteredCourse.Requirements));
        setLearnersContent(JSON.parse(filteredCourse.learnedFromCourse));
        setDescription(JSON.parse(filteredCourse.Description));
        setCourseContent(JSON.parse(filteredCourse.CourseContent));

        // Handle files
        if (filteredCourse.UploadBrochure) {
          try {
            const parsedFiles = JSON.parse(filteredCourse.UploadBrochure);
            setFiles(parsedFiles);
          } catch (error) {
            console.error("Error parsing files data:", error);
            setFiles([]);
          }
        } else {
          setFiles([]);
        }

        // Fetch instructor data
        const fetchInstructors = async () => {
          try {
            const instructorIds = JSON.parse(filteredCourse.Instructors);
            const instructorsData = await Promise.all(
              instructorIds.map(async (id) => {
                const response = await axios.get(
                  `${baseURL}/Instructor_fetch/${id}`
                );
                return response.data;
              })
            );
            setSelectedInstructors(instructorsData);
          } catch (error) {
            console.error("Error fetching instructor data:", error);
          }
        };

        // Fetch FAQs data
        const fetchFAQs = async () => {
          try {
            const FAQsIds = JSON.parse(filteredCourse.FAQs);
            const FAQsData = await Promise.all(
              FAQsIds.map(async (id) => {
                const response = await axios.get(`${baseURL}/FAQ_fetch/${id}`);
                return response.data;
              })
            );
            setSelectedFAQs(transformFAQs(FAQsData));
          } catch (error) {
            console.error("Error fetching FAQs data:", error);
          }
        };

        fetchInstructors();
        fetchFAQs();
        setLoading(false); // Set loading to false once data is fetched
      }
    }
  }, [allCoursesData, courseName ,category]);

const[isloading,setIsLoading]=useState(true);

useEffect(()=>{
  setIsLoading(true)
  const timer = setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  return () => clearTimeout(timer);
  
},[courseName ,category])
  //  //-------------------------------------------------------------------------
  // //Page Loading
  // //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  //--------------------------------------------------------------------------------
  // Page loading
  //--------------------------------------------------------------------------------

  if (loading) {
    return <PageLoading image={TalentPoolAcademy_logo} />;
  }

  return (
   
    <div>
       
      {/* ____________Header small bar_____________ */}
      <HeaderSmallBar/>

      {/* _______________end________________________ */}
      {/* ___________Header TPA___________ */}

      <HeaderMain />
     {isloading ?  (
     <PageLoading image={TalentPoolAcademy_logo} />):(
      <>
      {/* ___________Header course page___________ */}
      <Template2Header
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        image={image}
        setImage={setImage}
        courseDetails={courseDetails}
        setCourseDetails={setCourseDetails}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        tags={tags}
        setTags={setTags}
        editData={allCoursesData}
        setCoursePage={setCoursePage}
        selectedCategory={selectedCategory}
        files={files}
      />
      {/* ___________Tabs sections ___________ */}
      <Coursetemplate2Tabs
        isChecked={isChecked}
        objectivecontent={objectivecontent}
        setObjectiveContent={setObjectiveContent}
        skillscontent={skillscontent}
        setSkillsContent={setSkillsContent}
        requirementscontent={requirementscontent}
        setRequirementsContent={setRequirementsContent}
        learnersecontent={learnersecontent}
        setLearnersContent={setLearnersContent}
        description={description}
        setDescription={setDescription}
        courseContent={courseContent}
        setCourseContent={setCourseContent}
        selectedInstructors={selectedInstructors}
        setSelectedInstructors={setSelectedInstructors}
        selectedFAQs={selectedFAQs}
        setSelectedFAQs={setSelectedFAQs}
        courseTags={courseTags}
        setCourseTags={setCourseTags}
        tags={tags}
        files={files}
        setFiles={setFiles}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <div className="enquiry-section-course-template-width-container">

      
      <CourseEnquirySection courseName={courseName}/>
      </div>
      {/* ____________TPA Footer____________ */}
      <FooterMain />
      {/* ____________end____________ */}
      {/* _____________Back To Top_________ */}
      <BackToTop/>
      {/* _________________end_____________ */}
      </>
    )}
    </div>
   

  );
 
}

export default CourseTemplateMainPage;
