import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Stscroll5EmploymentAddModal from "./PmTab5EmploymentAddModal";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import PmTab5EmploymentEditModal from "./PmTab5EmploymentEditModal";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

function PmTab5Employment({ callPercentage }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [empList, setEmpList] = useState([]); // from DB

  const [addModalStatus, setAddModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills] =
    useState(null);
  const [invalidFields, setInvalidFields] = useState({});

  const [employmentData, setEmploymentData] = useState({
    curEmp: "",
    empType: "",
    fullExpYears: "",
    fullExpMonths: "",
    currentCompanyname: "",
    currentDesignation: "",
    internshipLocation: "",
    internshipWorkFrom: "", //  changed - removed yy-mm
    internshipWorkTill: new Date().toISOString().split("T")[0], //  changed - removed yy-mm
    fulltimeWorkFrom: "", //  changed - removed yy-mm
    fulltimeWorkTill: new Date().toISOString().split("T")[0], //  changed - removed yy-mm
    jobProfile: "",
    monthlyStipend: "",
    monthlyStipendCurrency: "",
    skillsUsed: "",
    curSal: "",
    internshipDepartment: "",
  });

  const [editItemID, setEditItemID] = useState(null);
  const [editData, setEditData] = useState({
    id: "",
    empType: "",
    fullExpYears: "",
    fullExpMonths: "",
    currentCompanyname: "",
    currentDesignation: "",
    internshipLocation: "",
    internshipWorkFrom: "", //  changed - removed yy-mm
    internshipWorkTill: "", //  changed - removed yy-mm
    fulltimeWorkFrom: "", //  changed - removed yy-mm
    fulltimeWorkTill: "", //  changed - removed yy-mm
    jobProfile: "",
    monthlyStipend: "",
    monthlyStipendCurrency: "",
    skillsUsed: [],
    curSal: "",
    internshipDepartment: "",
  });
  const keySkillOptions = [
    { value: "React", label: "React" },
    { value: "CSS", label: "CSS" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "Angular", label: "Angular" },
    { value: "Catia", label: "Catia" },
    { value: "PHP", label: "PHP" },
    { value: "Laravel", label: "Laravel" },
    { value: "SAP", label: "SAP" },
    { value: "Devops", label: "Devops" },
  ];
  //--------------------------------------------------------------------------------
  //  Fucntion to toggle Modal
  //--------------------------------------------------------------------------------
  function toggleAddModal() {
    setAddModalStatus(!addModalStatus);
  }
  function ClearAddData() {
    setEmploymentData({
      id: null,
      curEmp: null,
      empType: null,
      fullExpYears: null,
      fullExpMonths: null,
      currentCompanyname: null,
      currentDesignation: null,
      internshipLocation: null,
      internshipDepartment: null,
      internshipWorkFrom: null, // changed - removed yy-mm
      internshipWorkTill: null, // changed - removed yy-mm
      monthlyStifend: null,
      fulltimeWorkFrom: null, // changed - removed yy-mm
      fulltimeWorkTill: null, // changed - removed yy-mm
      curSal: null,
      jobProfile: null,
      monthlyStipend: null,
      monthlyStipendCurrency: null,
      skillsUsed: null, // transformed skillsUsed
    });
  }

  const toggleEditModal = (itemID) => {
    const selectedItem = empList.find((item) => item.id === itemID);
    if (selectedItem) {
      let skillsArray = [];

      try {
        skillsArray = selectedItem.skillsUsed.split(",").map((skill) => {
          const trimmedSkill = skill.trim(); // Remove any extra whitespace
          return { value: trimmedSkill, label: trimmedSkill };
        });
      } catch (error) {
        console.error("Error parsing skillsUsed:", error);
      }
      setSelectedOptionsKeySkills(skillsArray);

      setEditItemID(itemID);
      setEditData({
        id: selectedItem.id,
        curEmp: selectedItem.curEmp,
        empType: selectedItem.empType,
        fullExpYears: selectedItem.fullExpYears,
        fullExpMonths: selectedItem.fullExpMonths,
        currentCompanyname: selectedItem.currentCompanyname,
        currentDesignation: selectedItem.currentDesignation,
        internshipLocation: selectedItem.internshipLocation,
        internshipDepartment: selectedItem.internshipDepartment,
        internshipWorkFrom: selectedItem.internshipWorkFrom, // changed - removed yy-mm
        internshipWorkTill: selectedItem.internshipWorkTill, // changed - removed yy-mm
        monthlyStifend: selectedItem.monthlyStifend,
        fulltimeWorkFrom: selectedItem.fulltimeWorkFrom, // changed - removed yy-mm
        fulltimeWorkTill: selectedItem.fulltimeWorkTill, // changed - removed yy-mm
        curSal: selectedItem.curSal,
        jobProfile: selectedItem.jobProfile,
        monthlyStipend: selectedItem.monthlyStipend,
        monthlyStipendCurrency: selectedItem.monthlyStipendCurrency,
        skillsUsed: skillsArray, // transformed skillsUsed
      });

      setEditModalStatus(!editModalStatus); // toggle the modal status to open/close
    }
  };

  //--------------------------------------------------------------------------------
  // Fetching the Student Employment LIst
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchStudentEmpList();
  }, []);
  const fetchStudentEmpList = () => {
    const requestBody = {
      email: userEmail,
    };

    fetch(`${baseURL}/getAllStudentEmployment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmpList(data || "");
      })

      .catch((error) => {
        console.error("Error fetching Emp_details:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // API - Delete Employment Item
  //--------------------------------------------------------------------------------
  const handleItemDelete = (itemId) => {
    const updatedEmpList = empList.filter((item) => item.id !== itemId);
    setEmpList(updatedEmpList);

    axios
      .put(`${baseURL}/deleteStudentEmployment/${itemId}`)
      .then((response) => {
        if (response.status === 200) {
          customAlert("", " Item Deleted", "success");
          fetchStudentEmpList();
          setEditModalStatus(false);
          callPercentage();
        } else {
          customAlert("", " API Error", "warning");
        }
      })
      .catch((error) => {
        customAlert("", " Server Down !", "warning");
        console.error("Item deleting error:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // API - Edit Employment Item
  //--------------------------------------------------------------------------------
  const saveEditedItem = () => {
    const updates = {
      id: editItemID,
      email: userEmail,
      curEmp: editData.curEmp,
      curSal: editData.curSal,
      empType: editData.empType,
      fullExpYears: editData.fullExpYears,
      fullExpMonths: editData.fullExpMonths,
      currentCompanyname: editData.currentCompanyname,
      currentDesignation: editData.currentDesignation,
      internshipLocation: editData.internshipLocation,
      internshipDepartment: editData.internshipDepartment,
      internshipWorkFrom: editData.internshipWorkFrom, //  changed - removed yy-mm
      internshipWorkTill: editData.internshipWorkTill, //  changed - removed yy-mm

      fulltimeWorkFrom: editData.fulltimeWorkFrom, //  changed - removed yy-mm
      fulltimeWorkTill: editData.fulltimeWorkTill, //  changed - removed yy-mm

      monthlyStifend: editData.monthlyStifend,
      jobProfile: editData.jobProfile,
      monthlyStipend: editData.monthlyStipend,
      monthlyStipendCurrency: editData.monthlyStipendCurrency,
      skillsUsed: selectedOptionsKeySkills.map((option) => option.value),
      // skillsUsed: selectedOptionsKeySkills.map((option) => option.value),
    };

    // Fields to validate
    const requiredFields = [
      "curEmp",
      "empType",
      "currentCompanyname",
      "currentDesignation",
      "fulltimeWorkFrom",
    ];
    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
      if (!updates[field]) {
        newInvalidFields[field] = true;
      }
    }

    // if (Object.keys(newInvalidFields).length > 0) {
    //   setInvalidFields(newInvalidFields);
    //   return;
    // }

    axios
      .post(`${baseURL}/updateStudentEmployment/${editItemID}`, updates)
      .then((response) => {
        if (response.status === 200) {
          const updatedList = empList.map((item) =>
            item.id === editItemID.id ? { ...item, ...editData } : item
          );
          setEmpList(updatedList);
          fetchStudentEmpList();
          customAlert("", "Item Edited", "success");
          setEditModalStatus(false);
          callPercentage();
        } else {
          customAlert("", "API Error", "warning");
          console.error("Failed to update item");
        }
      })
      .catch((error) => {
        customAlert("", "Server Down", "warning");
        console.error("Item updating error:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // Helper function to format date
  //--------------------------------------------------------------------------------
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div>
      {/* ___________________________________ Tab Heading _______________________________ */}
      <div className="pmEmpOuterContainer">
        <span className="pmTabHeadings2"> Employment </span>
        <span onClick={toggleAddModal} className="pmAddEmpBtn">
          Add Employment
        </span>
      </div>
    

      {/* ___________________________________ Render Data Mapping____________________________ */}

      <div>
        {empList.map((data) => (
          <>
            {/* ___________________ Employment Data  ___________________ */}
            <div key={data.id}>
              <span className="pmRemderListTitle">
                {data.currentDesignation}
              </span>{" "}
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => toggleEditModal(data.id)}>
                  <EditIcon className="pmEditIcon" />
                </IconButton>
              </Tooltip>
              &nbsp;
              <br></br>
              <span className="pmEmpCompanyName">
                {data.currentCompanyname}
              </span>
              <br></br>
              {data.empType === "Internship" ? (
                <div className="pmProlifeUserData">
                  <span className="pmDimDuration">
                    {data.empType} | {formatDate(data.internshipWorkFrom)} to{" "}
                    {data.curEmp == "No" ? (
                      <>{formatDate(data.internshipWorkTill)} </>
                    ) : (
                      <>
                        <span> In- Progress</span>
                      </>
                    )}
                  </span>
                  {/* <br></br> */}
                </div>
              ) : (
                <div className="pmProlifeUserData">
                  <span className="pmDimDuration ">
                    {data.empType} | {formatDate(data.fulltimeWorkFrom)} to{" "}
                    {data.curEmp == "No" ? (
                      <>
                        {formatDate(data.fulltimeWorkTill)} ({data.fullExpYears}
                        {" Year's "} {data.fullExpMonths}
                        {" Month's "})
                      </>
                    ) : (
                      <>
                        <span> In- Progress</span>
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="pmProlifeUserData">
                <span className="pmEmpCompanyName pmProlifeUserData">
                  {data.jobProfile}
                </span>
              </div>
            </div>

            {/* ___________________ Top Skills - Data  _________________ */}
            {data.skillsUsed !== null && data.empType == "Full-Time" && (
              <div className="pmProlifeUserData">
                <span className="pmEmpSkillRender ">Top Skills Used: </span>
                {data.skillsUsed}
              </div>
            )}
            <hr />
          </>
        ))}
      </div>
      {/* ___________________________________ Render Data Mapping Ends____________________________ */}

      {/* ___________________________________ Add_Modal code starts____________________________ */}

      <Modal
        open={addModalStatus}
        onClose={setAddModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          {/* _______ Employment Modal - Component ___ */}
          <Stscroll5EmploymentAddModal
            togglingModal={toggleAddModal}
            fetchStudentEmpList={fetchStudentEmpList}
            keySkillOptions={keySkillOptions}
            employmentData={employmentData}
            setEmploymentData={setEmploymentData}
            ClearAddData={ClearAddData}
            callPercentage={callPercentage}
          />
        </div>
      </Modal>

      {/* ___________________________________ Add_Modal code Ends____________________________ */}

      {/* ___________________________________ Edit_Modal code starts____________________________ */}
      <Modal
        open={editModalStatus}
        onClose={setEditModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          {/* ___________ Close Button ______________ */}
          <IconButton
            id="button-zoom"
            className="modal-close-iconButton-right-top-corner"
            onClick={() => setEditModalStatus(false)}
          >
            <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
          </IconButton>

          {/* _______ Key skill Modal - Component ___ */}
          <PmTab5EmploymentEditModal
            editData={editData}
            setEditData={setEditData}
            selectedOptionsKeySkills={selectedOptionsKeySkills}
            keySkillOptions={keySkillOptions}
            setSelectedOptionsKeySkills={setSelectedOptionsKeySkills}
            saveEditedItem={saveEditedItem}
            // saveEditedItem={()=>alert('edit')}
            handleItemDelete={handleItemDelete}
            invalidFields={invalidFields}
          />
        </div>
      </Modal>
      {/* ___________________________________ Edit_Modal code Ends _____________________________ */}
    </div>
  );
}

export default PmTab5Employment;
