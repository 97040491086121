import React, { useEffect, useRef, useState } from "react";
import teach1 from "./TeachUSonTPA_Images/Teach3.jpg";
import "./TeachUSonTPA.css";

function TOT_Component_3() {
  const imageRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  //-------------------------------------------------------------------------
  //Image Animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const imageElement = imageRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          imageElement.classList.add("image-loading");
          setHasAnimated(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (imageElement) {
      observer.observe(imageElement);
    }

    return () => {
      if (imageElement) {
        observer.unobserve(imageElement);
      }
    };
  }, [hasAnimated]);

  return (
    /*________________________________________third_component_outside_div_______________________________________*/
    <div className="comp_3">
      <div className="component_3_outside">
        {/*________________________________________third_component_grid_div_______________________________________*/}
        <div className="component_3_grid_div">
          <div className="comp_3_img">
            <img
              ref={imageRef}
              className="glassImage image-loading env_img"
              src={teach1}
            />
          </div>
          {/*________________________________________third_component_content_Outside_div_______________________________________*/}
          <div className="grid_first content_grid_3">
            {/*________________________________________first_content_div_______________________________________*/}
            <div className="content_1">
              <h2>Grow Your Career</h2>
              <p>
                Professional Development Access to ongoing training and
                development opportunities to enhance your skills and knowledge.
              </p>
            </div>
            {/*________________________________________second_content_div_______________________________________*/}
            <div className="content_1">
              <h2>Be Part of a Supportive Team</h2>
              <p>
                Collaborative Environment Work alongside a supportive team of
                educators who value collaboration and teamwork.
              </p>
            </div>
            {/*________________________________________third_content_div_______________________________________*/}
            <div className="content_1">
              <h2>Rewarding Benefits</h2>
              <p>
                Competitive Compensation Receive competitive pay and benefits
                for your dedication and hard work.
              </p>
            </div>
            {/*________________________________________third_component_second_grid_div_______________________________________*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TOT_Component_3;
