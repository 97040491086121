import React, { useState, useRef, useEffect } from "react";
import "./ContactUs.css";
import contact_us from "./ContactImage/contact_us.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import "intersection-observer";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";

const ContactForm = () => {
  const [inView, setInView] = useState(false);
  const contactFormRef = useRef(null);
  const [open,setOpen]= useState(false)

    //-------------------------------------------------------------------------
  //Enquiry open and close
  //-------------------------------------------------------------------------

  const handleOpenForm=()=>{
    setOpen(true);
  }
  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (contactFormRef.current) {
      observer.observe(contactFormRef.current);
    }

    return () => {
      if (contactFormRef.current) {
        observer.unobserve(contactFormRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`contact-form-wrapper ${inView ? "animate" : ""}`}
      ref={contactFormRef}
    >
      <div className="contact-form-outside">
        <div className="contact-info">
          <h2>Have Questions? Contact Us</h2>
          <p>Let us know how we can help you.</p>
          <button className="contact-button" onClick={handleOpenForm}>Contact Us</button>
        </div>
        <div className="contact-image-container">
          <img src={contact_us} className="contact_image" alt="Contact Us" />
        </div>
      </div>
      {/*_______________enquiry form____________ */}
      <EnquiryFormModal open={open} setOpen={setOpen} EnquiryHeading={'Contact Us'}/>
    </div>
  );
};

export default ContactForm;
