// import axios from "axios";

const URL1 = "https://tpabackend.talentpool-academy.in/api";
const URL2 = "http://localhost:8000/api";

export const baseURL = URL1;

// export default axios.create({
//   baseURL: `${baseURL}`,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
