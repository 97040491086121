import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./backtotop.css";
import { Tooltip } from "@mui/material";

function BackToTop() {
  const [visible, setVisible] = useState(false);

  //--------------------------------------------------------------------------------
  // Show the button when the user scrolls down 10px
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //--------------------------------------------------------------------------------
  // Scroll to the top of the page when clicked
  //--------------------------------------------------------------------------------

  const scrollToTop = (e) => {
    e.preventDefault(); // Prevent any default behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  return (
    <>
      {/* _____________________Button section_________________ */}
      {visible && (
        <div className="backtotop-container">
          <Tooltip title="Scroll to Top" arrow placement="left">         
            <button onClick={scrollToTop} className="back-to-top-button">
              <ArrowUpwardIcon className="back-to-top-icons" />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default BackToTop;
