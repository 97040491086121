import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./ContactUs.css";

const ContactCard = () => {
  const cardRefs = useRef([]);


  //-------------------------------------------------------------------------
  //animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, {
      threshold: 0.5
    });

    cardRefs.current.forEach(card => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRefs.current.forEach(card => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    /*_______________________________________Contact Card____________________________*/
    <div className="contact-card-outside">
      {/*_______________________________________Contact Card container____________________________*/}
      <div className="contact-card-container">
        {/*_______________________________________Contact Card card 1____________________________*/}
        <div className="contact-card" ref={el => cardRefs.current[0] = el}>
          <a href="https://www.linkedin.com/in/talent-pool-academy?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <FontAwesomeIcon icon={faLinkedin} className="icon" />
            <h3>LinkedIn Profile</h3>
            <p>Talent Pool Academy</p>
          </a>
        </div>
        {/*_______________________________________Contact Card card 2____________________________*/}
        <div className="contact-card" ref={el => cardRefs.current[1] = el}>
          <FontAwesomeIcon icon={faPhone} className="icon" />
          <h3>Call Us Anytime</h3>
          <p>+91 7483868010</p>
        </div>
        {/*_______________________________________Contact Card card 3____________________________*/}
        <div className="contact-card" ref={el => cardRefs.current[2] = el}>
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <h3>Send An Email</h3>
          <p>info@talentpool-academy.in</p>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
