import React, { useState, useEffect } from "react";
import axios from "axios";
import ReviewCard from "./ReviewCard2";
import { baseURL } from "../../http";

function ReviewMoreCardSection() {
  const [reviews, setReviews] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);

  //--------------------------------------------------------------------------------
  // Fetch reviews data when component mounts
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(baseURL + "/review_fetch_all");
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
    };

    fetchReviews();
  }, []);

  //--------------------------------------------------------------------------------
  // Load more reviews
  //--------------------------------------------------------------------------------

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  return (
    <div className="reviewmorecard-section-background-color">
      <div className="review-more-card-width-container">
        <div className="review-more-card-container">
          {/* ________________Heading________________ */}
          <div className="review-more-card-heading">See What Others Think</div>
          {/* _______________________end________________ */}
          {/* ____________________Card render section_____________ */}
          <div className="review-more-card-section-render">
            {reviews && reviews.slice(0, visibleCount).map((review, index) => (
              <ReviewCard
                key={index}
                Image={review.Image}
                Name={review.Name}
                Designation={review.Designation}
                Description={review.Description}
              />
            ))}
          </div>
          {/* _______________________end___________________________ */}
          {/* __________________all course button______________________ */}
          {visibleCount < reviews.length && (
            <div className="review-more-load-more-card-section">
              <div
                className="review-more-section-more-review-button"
                onClick={loadMore}
              >
                Load More
              </div>
            </div>
          )}
          {/* ___________________________end______________________________ */}
        </div>
      </div>
    </div>
  );
}

export default ReviewMoreCardSection;
