import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselComponent.css";

const CarouselComponent = () => {
  //-------------------------------------------------------------------------
  //Hook State
  //-------------------------------------------------------------------------
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentIndex, setCurrentIndex] = useState(0);

  //-------------------------------------------------------------------------
  //Responsive use effect
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //-------------------------------------------------------------------------
  //Carousel data
  //-------------------------------------------------------------------------
  const getCarouselData = () => {
    const baseStyles = {
      slide: {
        position: "relative",
        width: "100%",
        height: "500px",
        overflow: "hidden",
      },
      img: {
        width: "100%",
        height: "100%",
        objectFit: "fill",
        objectPosition: "center",
      },
      textOverlay: {
        position: "absolute",
        top: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center",
      },
      controlPrev: { marginLeft: "10px" },
      controlNext: { marginRight: "10px" },
    };

    //-------------------------------------------------------------------------
    //Carousel data mobile view
    //-------------------------------------------------------------------------
    const mobileStyles = {
      ...baseStyles,
      slide: { ...baseStyles.slide, height: "300px" },
      textOverlay: { ...baseStyles.textOverlay, top: "20px" },
      controlPrev: { ...baseStyles.controlPrev, marginLeft: "5px" },
      controlNext: { ...baseStyles.controlNext, marginRight: "5px" },
    };

    const styles = isMobile ? mobileStyles : baseStyles;

    //-------------------------------------------------------------------------
    //Carousel items
    //-------------------------------------------------------------------------
    const carouselItems = [
      {
        imgSrc: "carousel6.jpg",
        imgAlt: "Slide 1",
        heading: "Learn Anytime, Anywhere",
        description: "Access our courses on the go, from any device.",
      },
      {
        imgSrc: "carousel7.jpg",
        imgAlt: "Slide 2",
        heading: "Expert Instructors",
        description: "Learn from industry experts with real-world experience.",
      },
      {
        imgSrc: "carousel1.jpg",
        imgAlt: "Slide 3",
        heading: "Interactive Learning",
        description: "Engage in hands-on projects and real-time feedback.",
      },
      {
        imgSrc: "carousel15.png",
        imgAlt: "Slide 4",
        heading: "Career Advancement",
        description: "Gain skills that will boost your career prospects.",
      },
      {
        imgSrc: "carousel11.jpg",
        imgAlt: "Slide 5",
        heading: "Join Our Community",
        description: "Become part of a global network of learners.",
      },
    ];

    return { styles, carouselItems };
  };

  //-------------------------------------------------------------------------
  //Assigning Variable
  //-------------------------------------------------------------------------
  const { styles, carouselItems } = getCarouselData();

  const onChange = (index) => {
    setCurrentIndex(index);
  };


  return (
    /*________________________________________Importing Carouse_______________________________________*/
    <div className="carousel">
      <div className="carousel_inside">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={true}
          interval={3000}
          onChange={onChange}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-prev"
                style={styles.controlPrev}
              ></button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-next"
                style={styles.controlNext}
              ></button>
            )
          }
        >
          {/*_______________________________Importing carousel Data____________________________*/}
          {carouselItems.map((item, index) => (
            <div key={index} style={styles.slide}>
              <img src={item.imgSrc} alt={item.imgAlt} style={styles.img} />
              <div className={`text-overlay ${
                  currentIndex === index ? "fade-in" : ""
                }`}>
                <h2>{item.heading}</h2>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselComponent;
