import React, { useEffect, useRef, useState } from "react";
import "./TeachUSonTPA.css";
import teach from "./TeachUSonTPA_Images/teach.jpg";

function Teach_with_us() {
  const imageRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  //-------------------------------------------------------------------------
  //Image Animation
  //-------------------------------------------------------------------------
  useEffect(() => {
    const imageElement = imageRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          imageElement.classList.add("image-loading");
          setHasAnimated(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (imageElement) {
      observer.observe(imageElement);
    }

    return () => {
      if (imageElement) {
        observer.unobserve(imageElement);
      }
    };
  }, [hasAnimated]);

  return (
    /*________________________________________first_component_outside_div_______________________________________*/
    <div className="comp_2">
      <div className="first_Component_Outside">
        {/*_______________________________first_component_inside_div____________________________*/}
        <div className="first_Component_Inside">
          {/*_______________________________Second grid____________________________*/}
          <div className="second_grid">
            {/*_______________________________second_grid_inside_div____________________________*/}
            <div className="grid_first">
              {/*_______________________________first_content_div____________________________*/}
              <div className="content_1 content2">
                <h2>Why Teach with Us?</h2>
                <p>
                  Our mission is to empower passionate educators to inspire and
                  shape the future generation.
                </p>
              </div>
              {/*_______________________________second_content_div____________________________*/}
              <div className="content_1 content2">
                <h2>Competitive Compensation</h2>
                <p>
                  We offer attractive and competitive compensation packages to
                  reward your unwavering dedication and commitment.
                </p>
              </div>
              {/*_______________________________third_content_div____________________________*/}
              <div className="content_1 content2">
                <h2>Professional Development</h2>
                <p>
                  Join a dynamic and collaborative environment that fosters
                  continuous growth, creativity, and innovation.
                </p>
              </div>
            </div>
          </div>
          {/*_______________________________first_grid_image____________________________*/}
          <img
            ref={imageRef}
            className="image-loading teach-image"
            src={teach}
          />
        </div>
      </div>
    </div>
  );
}

export default Teach_with_us;
