import React, { useState, useEffect } from "react";
import CourseCard from "../CourseCard/CourseCard";
import NoCourse from "./AllCourseImage/NoCourseAvaible.png";
import { useNavigate } from "react-router-dom";
import "./allcourse.css";

function AllCourseRenderSection({ courses, structuredData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 8;
  const navigate = useNavigate();

  //-------------------------------------------------------------------------
  // Function to find the category for a given course name
  //-------------------------------------------------------------------------

  const getCategoryByCourseName = (courseName) => {
    for (const category in structuredData) {
      for (const subCategory in structuredData[category]) {
        const courses = structuredData[category][subCategory];
        if (
          Array.isArray(courses) &&
          courses.some((course) => course.CourseName === courseName)
        ) {
          return { category, subCategory };
        }
      }
    }
    return { category: null, subCategory: null }; // return null if not found
  };

  //-------------------------------------------------------------------------
  // card click to Route to the course page
  //-------------------------------------------------------------------------
  const handleCourseClick = (courseName) => {
    const { category, subCategory } = getCategoryByCourseName(courseName);
    if (category && subCategory) {
      navigate(`/course/${category}/${courseName}`);
    } else {
      console.error("Category not found for this course");
    }
  };

  //-------------------------------------------------------------------------
  // Reset to the first page whenever courses array changes
  //-------------------------------------------------------------------------
  useEffect(() => {
    setCurrentPage(1);
  }, [courses]);

  const startIndex = (currentPage - 1) * cardsPerPage;
  const paginatedCourses = courses.slice(startIndex, startIndex + cardsPerPage);

  //-------------------------------------------------------------------------
  // Render course cards
  //-------------------------------------------------------------------------

  const renderCourseCards = () => {
    return paginatedCourses.length > 0 ? (
      paginatedCourses.map((course, index) => (
        <div
          key={index}
          className="course-card-container"
          onClick={() => handleCourseClick(course.CourseName)}
        >
          <CourseCard courseName={course.CourseName} />
        </div>
      ))
    ) : (
      // _________________if no course availbale_________________//
      <div className="no-course-available-container">
        <div className="no-course-avaibale-image-container">
          <img src={NoCourse} alt="No Course Available" />
        </div>
        <div className="no-course-heading-container">
          <h3>We’re working on something amazing!</h3>
          <div>
            New courses will be available soon—stay connected for updates.
          </div>
        </div>
      </div>
    );
  };

  //-------------------------------------------------------------------------
  // Render pagination controls
  //-------------------------------------------------------------------------

  const renderPaginationControls = () => {
    const totalCourses = courses.length;
    const totalPages = Math.ceil(totalCourses / cardsPerPage);

    return (
      <div className="pagination-controls">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`pagination-button ${
              currentPage === index + 1 ? "active" : ""
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      {/* ____________________courses render page_________________ */}
      <div className="course-cards-wrapper">{renderCourseCards()}</div>
      {renderPaginationControls()}
      {/* _______________________End______________________________ */}
    </>
  );
}

export default AllCourseRenderSection;
