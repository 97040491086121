import React, { useState } from "react";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Rating from "@mui/material/Rating";
import GoogleIcon from "@mui/icons-material/Google";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import "./coursetemplate2.css";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import EnquiryFormModal from "../EnquiryForm/EnquiryFormModal";
import axios from "axios";
import {
  getCurrentDateTime,
  getLocationInfo,
  getUtcTime,
} from "../Common/Utilities_Function";
import { baseURL } from "../../http";

function Template2Header({
  selectedCourse,
  image,
  courseDetails,
  selectedCategory,
  files,
}) {
  const [open, setOpen] = useState(false);
  const HTES_user_id =
    localStorage.getItem("HTES_user_id") ||
    localStorage.getItem("Enquiry_Login");

  //--------------------------------------------------------------------------------
  // Form data to send the data
  //--------------------------------------------------------------------------------
  const [formData, setFormData] = useState({
    Name: "",
    Email: HTES_user_id,
    Phone: "",
    EnquiryType: "Download Brochure",
    Message: "Download Brochure Success fully",
    Created_by: localStorage.getItem("HTES_user_id"),
    Modified_by: localStorage.getItem("HTES_user_id"),
    Created_date: getCurrentDateTime(),
    Modified_date: getCurrentDateTime(),
    Latitude: "",
    Longitude: "",
    UTC_Time: getUtcTime(),
    Status: "Not Assigned",
  });

  //-------------------------------------------------------------------------
  //Effect to get location info
  //-------------------------------------------------------------------------

  const fetchLocation = async () => {
    if (!formData.Longitude) {
      const location = await getLocationInfo();
      if (location) {
        setFormData((prevData) => ({
          ...prevData,
          Latitude: location.latitude,
          Longitude: location.longitude,
        }));
      }
    }
  };

  //--------------------------------------------------------------------------------
  // Download the pdf files function
  //--------------------------------------------------------------------------------

  const downloadFiles = () => {
    if (!HTES_user_id) {
      setOpen(true);
    } else {
      if (localStorage.getItem("HTES_user_id")) {
        fetchLocation();
        handleSubmit();
      }

      files.forEach((file) => {
        const base64Data = file.data.split(",")[1]; // Extract base64 data
        const blob = new Blob(
          [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
          { type: "application/pdf" }
        );
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the URL object
      });
    }
  };

  //-------------------------------------------------------------------------
  //Handle the API for sending the enquiry
  //-------------------------------------------------------------------------
  const handleSubmit = async () => {
    const updatedFormData = {
      ...formData,
      UTC_Time: getUtcTime(), // Update the UTC time before submitting
    };
    //localStorage.setItem('Enquiry_Login',formData.Email)

    try {
      const response = await axios.post(
        baseURL + "/createEnquiry",
        updatedFormData
      );
      console.log(response)
      // handleClose()
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };
  return (
    <div className="course-header-section-background-color">
      {/* _________________________Coursename selection_______________________ */}
      <div className="course-name-container">
        <div className="coursetemplate2-course-name">{selectedCourse}</div>

        <div className="coursetemplate2-course-category-name">
          <ClassOutlinedIcon className="category-icon-size" />
          <span className="category-space">Category: {selectedCategory}</span>
        </div>

        <div></div>
      </div>
      {/* _________________________CoursImage change _______________________ */}

      <div className="coursetemplate2-course-image-geninfo-container">
        <div className="coursetemplate2-course-image-conatiner">
          <div className="coursetemplate2-image-container-final-view">
            <img src={image} alt="Course_render_section" />
          </div>
        </div>

        {/* _________________________duration change _______________________ */}
        <div className="coursetemplate2-course-General-info">
          <div className="coursetemplate2-geninfo-div">
            <div className="coursetemplate2-icons-color-alignment">
              <AccessTimeIcon />
            </div>
            &nbsp;
            <div className="general-info-align-top">
              Duration: {courseDetails.duration}
            </div>
          </div>
          {/* _________________________Teaching mode change _______________________ */}
          <div className="coursetemplate2-geninfo-div">
            <div className="coursetemplate2-icons-color-alignment">
              <ModelTrainingIcon />
            </div>
            &nbsp;
            <div className="general-info-align-top">
              Teaching Mode: {courseDetails.teachingMode}
            </div>
          </div>
          {/* _________________________Price change_______________________ */}
          <div className="coursetemplate2-geninfo-div">
            <div className="coursetemplate2-icons-color-alignment">
              <CurrencyRupeeIcon />
            </div>
            &nbsp;
            <div className="general-info-align-top">
              Price: {courseDetails.price}
            </div>
          </div>
          {/* _________________________Student change_______________________ */}
          <div className="coursetemplate2-geninfo-div">
            <div className="coursetemplate2-icons-color-alignment">
              <PersonIcon />
            </div>
            &nbsp;
            <div className="general-info-align-top">
              Students Enrolled: {courseDetails.studentsEnrolled}
            </div>
          </div>
          {/* _________________________Rating change_______________________ */}
          <div className="coursetemplate2-geninfo-div">
            <GoogleIcon className="coursetemplate2-icons-color-alignment" />
            &nbsp;
            <Rating
              precision={0.5}
              value={parseFloat(courseDetails.rating)}
              readOnly
            />
            {/* _________________________end_______________________ */}
          </div>
          {/* _________________________Rating change_______________________ */}
          <div className="coursetemplate2-geninfo-div-download-brochure">
            <div className="course-download-brochure" onClick={downloadFiles}>
              <DownloadForOfflineOutlinedIcon className="buttonicons-common " />
              <span className="color-change-gradient">Download Brochure</span>
            </div>
            {/* _________________________end_______________________ */}

            {/* ________________Enquiry form______________ */}
            <EnquiryFormModal
              open={open}
              setOpen={setOpen}
              EnquiryHeading={"Download Brochure"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template2Header;
