import React from "react";
import "./HireTalent.css";
import { FaIndustry, FaLaptopCode, FaUsers } from "react-icons/fa";
import IndustryReady from "./HireTalentImages/IndustryReady.jpg";
import LatestTechnologies from "./HireTalentImages/LatestTechnologies1.jpg";
import SoftSkills from "./HireTalentImages/SoftSkills.jpg";

function HireBenifits() {
  return (
    <div>
      <div className="Hire-benefits-main">
        <div className="Hire-benefits-container">
          <div className="Hire-benefits-heading">
            <h2>Benefits of Hiring from Us</h2>
          </div>
          <div className="Hire-benefits-content">
            <div className="Hire-benefit">
              <img src={IndustryReady} alt="Talent Pool" />
              <h3>Industry-Ready</h3>
              <p>
                Our candidates are trained and prepared to enter the workforce
                immediately.
              </p>
            </div>
            <div className="Hire-benefit">
              <img src={LatestTechnologies} alt="Talent Pool" />
              <h3>Latest Technologies</h3>
              <p>
                We ensure our candidates are up-to-date with the latest tools
                and technologies.
              </p>
            </div>
            <div className="Hire-benefit">
              <img src={SoftSkills} alt="Talent Pool" />
              <h3>Soft Skills</h3>
              <p>
                Our training includes essential soft skills, making our
                candidates well-rounded professionals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HireBenifits;
