import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faMapMarkerAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Job_MainCard = ({ job, onClick, isSelected }) => {

    //--------To extract only labels--------
    function formatLabels(input) {
        try {
            if (input) {
                const dataArray = JSON.parse(input);
                const labels = dataArray.map(item => item.label).join(', ');
                return labels;
            }
        } catch (error) {
            console.error('Invalid JSON input:', error);
        }
        return 'NA'; // Return 'NA' if input is undefined or invalid
    }

    //----------------------------------------------------------------
    // Calculate the difference in days
    //----------------------------------------------------------------
    function calculateDaysAgo(dateString) {
        const postedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = currentDate - postedDate;
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysAgo;
    }


    return (
        //________________Job card_______________________//

        <div className={`job-main-card ${isSelected ? 'selected' : ''}`} onClick={onClick}>

            {/* //__________________Title and company name____________________// */}

            <div className="job-card-header">
                <h3 className="job-title">{job.Designation}</h3>
                <span className="company-name">{job.CompanyName}</span>
            </div>
            <div className="job-card-body">
                <div className="job-info-line">

                    {/* //_________________Experience_________________// */}

                    <div className="job-info-item">
                        <FontAwesomeIcon icon={faBriefcase} className="job-icon" />
                        <span className="job-experience">{job.RequiredMinExperience}-{job.RequiredMaxExperience} years</span>
                    </div>
                    <div className="job-divider"></div>

                    {/* //_________________Salary_________________// */}

                    <div className="job-info-item">
                        <CurrencyRupeeIcon className="rupee-icon" />
                        <span className="job-salary">{job.DesignationSalaryMin}-{job.DesignationSalaryMax} LPA</span>
                    </div>
                    <div className="job-divider"></div>

                    {/* //_________________Location_________________// */}

                    <div className="job-info-item">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="job-icon" />
                        <span className="job-location">{formatLabels(job.JobLocations)}</span>
                    </div>
                </div>

                {/* //_________________Skills_________________// */}

                <div className="job-info-line">
                    <div className="job-info-item">
                        <FontAwesomeIcon icon={faTools} className="job-icon" />
                        <span className="job-skills">{formatLabels(job.RequiredKeyskills)}</span>
                    </div>
                </div>
                <hr className='Question_FooterLine' />

                {/* //_________________Job posted date_________________// */}

                <div className="job-posted-date">
                    <span>Posted: {calculateDaysAgo(job.ApplicationStartDate)} days ago</span>
                </div>
            </div>
        </div>
    );
};

export default Job_MainCard;
