import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { baseURL } from "../../http";
import InterViewQuestionCard from "./InterViewQuestionCard";
import InterviewQuestionFilterModal from "./InterviewQuestionFilterModal";
import interviewquestion from "../InterViewQuestion/InterViewQuestionImages/interviewquestion.jpg";

//-------------------------------------------------------------------------
// Utility function to format date
//-------------------------------------------------------------------------

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Formats date to 'YYYY-MM-DD'
};

function InterviewQuestionRenderSectionFilter() {
  const searchBarRef = useRef(null);
  const [zIndex, setZIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [interviewQuestions, setInterviewQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]); // Changed to an array to handle multiple skills
  const [selectedCompany, setSelectedCompany] = useState("");
  const [sortOrder, setSortOrder] = useState("latest"); // default sort order

  //-------------------------------------------------------------------------
  // Open and Close the filter modal in the Mobile View
  //-------------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //-------------------------------------------------------------------------
  // Clear all filters
  //-------------------------------------------------------------------------

  function ClearFilter() {
    setSearchQuery("");
    setSelectedDesignation("");
    setSelectedSkills([]);
    setSelectedCompany("");
    setSortOrder("latest");
  }

  //-------------------------------------------------------------------------
  // Call the clear function
  //-------------------------------------------------------------------------

  const handleClickFilter = () => {
    ClearFilter();
  };

  //-------------------------------------------------------------------------
  // Call API to fetch data
  //-------------------------------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Interview_Questions_fetch_all`
        );
        const questionsData = response.data;

        // Step 1: Filter approved questions
        const approvedQuestions = questionsData.filter(
          (question) => question.IsApproved === "1"
        );

        // Step 2: Format InterviewDate to ISO format or ensure it's in the correct format if needed
        const formattedQuestions = approvedQuestions.map((q) => ({
          ...q,
          InterviewDate: formatDate(q.InterviewDate), // Assuming `InterviewDate` is a string date from backend
        }));

        // Step 3: Remove duplicates based on the Question text
        const uniqueQuestionsMap = new Map();
        formattedQuestions.forEach((question) => {
          if (!uniqueQuestionsMap.has(question.Question)) {
            uniqueQuestionsMap.set(question.Question, question);
          }
        });
        const uniqueQuestions = Array.from(uniqueQuestionsMap.values());

        // Step 4: Shuffle the questions to randomize the order
        const shuffledQuestions = uniqueQuestions.sort(
          () => Math.random() - 0.5
        );

        // Step 5: Get top 20 questions
        const top20Questions = shuffledQuestions.slice(0, 20);

        // Set the interview questions state
        setInterviewQuestions(top20Questions);

        // setInterviewQuestions(questionsData);

        // Extract unique values for filters, ignoring null or empty values
        const uniqueDesignations = [
          ...new Set(questionsData.map((q) => q.Designation).filter(Boolean)),
        ];

        // Extract all skills from questions and create unique skills array
        const allSkills = questionsData.flatMap((q) =>
          q.Skills ? q.Skills.split(",").map((skill) => skill.trim()) : []
        );

        const cleanedSkills = allSkills.flatMap((skillString) => {
          // Remove brackets and quotes, then split by commas
          return skillString
            .replace(/[\[\]"]/g, "") // Remove brackets [] and quotes "
            .split(",") // Split into an array by comma
            .map((skill) => skill.trim()) // Trim spaces from each skill
            .filter((skill) => skill.length > 0); // Remove any empty strings
        });

        // Filter out empty strings and get unique skills
        const uniqueSkills = [...new Set(cleanedSkills)];

        const uniqueCompanies = [
          ...new Set(questionsData.map((q) => q.Company).filter(Boolean)),
        ];

        setDesignations(uniqueDesignations);
        setSkills(uniqueSkills); // Set unique skills to state
        setCompanies(uniqueCompanies);
      } catch (error) {
        setInterviewQuestions([]);
        console.error("Failed to fetch interview questions:", error);
      }
    };

    fetchData();
  }, []);

  //-------------------------------------------------------------------------
  // Filter and sort the interview questions
  //-------------------------------------------------------------------------

  useEffect(() => {
    const filtered = interviewQuestions.filter(
      (q) =>
        (!selectedDesignation || q.Designation === selectedDesignation) &&
        (!selectedCompany || q.Company === selectedCompany) &&
        (selectedSkills.length === 0 ||
          selectedSkills.some((skill) => q.Skills.includes(skill))) &&
        (!searchQuery ||
          q.Question.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    // Sort by PostedOn date
    filtered.sort((a, b) => {
      if (sortOrder === "latest") {
        return new Date(b.InterviewDate) - new Date(a.InterviewDate);
      } else {
        return new Date(a.InterviewDate) - new Date(b.InterviewDate);
      }
    });

    setFilteredQuestions(filtered);
  }, [
    interviewQuestions,
    selectedDesignation,
    selectedSkills,
    selectedCompany,
    searchQuery,
    sortOrder,
  ]);

  //-------------------------------------------------------------------------
  // Handle search input change
  //-------------------------------------------------------------------------

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  //-------------------------------------------------------------------------
  // Handle filter changes
  //-------------------------------------------------------------------------

  const handleDesignationChange = (event, value) => {
    setSelectedDesignation(value || "");
  };

  const handleSkillsChange = (event, value) => {
    setSelectedSkills(value || []);
  };

  const handleCompanyChange = (event, value) => {
    setSelectedCompany(value || "");
  };

  // Handle sorting order change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  //-------------------------------------------------------------------------
  // Handle scroll to change z-index
  //-------------------------------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(
        ".Search-and-cleae-filter-section-container"
      );
      if (element) {
        const topPosition = element.getBoundingClientRect().top;
        setZIndex(topPosition <= 10 ? 5 : 0); // Change z-index based on scroll position
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="allcoursesection-background-color">
      <div className="allcourse-section-width-container">
        {/* Heading and Button Section */}
        <div className="all-course-section-main-heading">
          Top Interview Questions
        </div>
        <div
          className="Search-and-cleae-filter-section-container"
          style={{ zIndex }}
        >
          <div
            className="clear-filter-section-more-review-button"
            onClick={handleClickFilter}
          >
            <FilterListIcon className="buttonicons-reset-filter" />
            &nbsp;Reset Filters
          </div>
          <div
            className="clear-filter-section-more-review-button1"
            onClick={handleOpen}
          >
            <FilterListIcon className="buttonicons-reset-filter1" />
            &nbsp;Filter
          </div>

          {/* Search Input */}
          <div className="interview-question-header-main">
            <div className="interview-question-header-content">
              <div
                ref={searchBarRef}
                className={`interview-question-header-search`}
              >
                <SearchIcon className="interview-search-icon" />
                <input
                  type="text"
                  placeholder="Search questions here"
                  className="interview-question-no-border"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Filters Section */}
        <div className="interview-question-all-section-main-flex-container">
          <div className="interview-question-section-filter-section">
            {/* Designation Filter */}
            <div className="all-coures-sub-heading-section">Designation</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                options={designations}
                value={selectedDesignation}
                onChange={handleDesignationChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Designation" />
                )}
              />
            </div>

            {/* Company Filter */}
            <div className="all-coures-sub-heading-section">Company</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                options={companies}
                value={selectedCompany}
                onChange={handleCompanyChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Company" />
                )}
              />
            </div>

            {/* Skills Filter */}
            <div className="all-coures-sub-heading-section">Skills</div>
            <div className="all-course-section-filter-options">
              <Autocomplete
                multiple
                options={skills}
                value={selectedSkills}
                onChange={handleSkillsChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Skills" />
                )}
              />
            </div>

            {/* Sort Order Filter */}
            <div className="all-coures-sub-heading-section">Posted on</div>
            <div className="all-course-section-filter-options">
              <FormControl fullWidth>
                <Select value={sortOrder} onChange={handleSortOrderChange}>
                  <MenuItem value="latest">Latest to Oldest</MenuItem>
                  <MenuItem value="oldest">Oldest to Latest</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Render Interview Questions */}
          <div className="inerviewquestion-all-questions-render-section">
            {filteredQuestions.length > 0 ? (
              filteredQuestions.map((question, index) => (
                <InterViewQuestionCard key={index} question={question} />
              ))
            ) : (
              <div className="no-course-available-container">
                <div className="no-course-avaibale-image-container">
                  <img src={interviewquestion} alt="No Course Available" />
                </div>
                <div className="no-course-heading-container">
                  <h3>No Interview Questions Available</h3>
                  <div>
                    Please check back later for new interview questions, adjust
                    your filters to see available questions, or consider adding
                    a new interview question to help others!
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Filter Modal for Mobile View */}
          <InterviewQuestionFilterModal
            open={open}
            handleClose={handleClose}
            handleClickFilter={handleClickFilter}
            designations={designations}
            setDesignations={setDesignations}
            skills={skills}
            setSkills={setSkills}
            handleDesignationChange={handleDesignationChange}
            companies={companies}
            setCompanies={setCompanies}
            handleSkillsChange={handleSkillsChange}
            selectedDesignation={selectedDesignation}
            selectedCompany={selectedCompany}
            handleCompanyChange={handleCompanyChange}
            selectedSkills={selectedSkills}
            sortOrder={sortOrder}
            handleSortOrderChange={handleSortOrderChange}
          />
        </div>
      </div>
    </div>
  );
}

export default InterviewQuestionRenderSectionFilter;
