import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../http";
import {
  getDeviceInfo,
  getCurrentDateTime,
  getLocationInfo,
} from "./Utilities_Function";
import { customAlert } from "../SweetAlertCommon/Custom";

const SignOut = () => {
  const [logoutData, setLogoutData] = useState(null);
  //console.log(logoutData);
  const HTES_user_id = localStorage.getItem("HTES_user_id");
  //console.log('HTES_user_id',HTES_user_id)

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        // Retrieve user ID, device name, and device time
        
        const deviceName = getDeviceInfo();
        const deviceTime = getCurrentDateTime();

        console.log("deviceTime", deviceTime);

        // Retrieve location information
        const location = await getLocationInfo();
        const { latitude, longitude, pincode, locationName } = location;

        // Construct logout data object
        const data = {
          last_logout_time: deviceTime,
          last_login_device_name: deviceName,
          email: HTES_user_id, // Set the email property
          Longitude: longitude,
          Latitude: latitude,
          PinCode_Login: pincode,
          Current_Location: locationName,
        };

        // Set the logout data state
        setLogoutData(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  console.log(logoutData);

  useEffect(() => {
    if (logoutData) {
      // Send logout request to the server
      axios
        .put(baseURL + "/signout", logoutData) // Change POST to PUT
        .then((response) => {
          if(response){
          // Remove user ID from local storage and redirect to login page
          localStorage.removeItem("HTES_user_id");
          localStorage.removeItem("HTES_user_name");
          window.location.href = "/home";
        }})
        .catch((error) => {
          console.log(error);
          // Show error message if logout fails
          customAlert("", `${error.response.data.error}`, "error");
        });
    }
  }, [logoutData]);

  return null;
};

export default SignOut;
