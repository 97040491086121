import React from "react";
import "./HeaderMain.css";
import HeaderItems from "./HeaderItems";


function HeaderMain({ structuredData, setStructuredData }) {

  //-------------------------------------------------------------------------
  //stick the navbar after 20px from top
  //-------------------------------------------------------------------------
  function makeHeaderSticky() {
    var header = document.querySelector(".header");

    if (header) {
      var stickyPoint = 20; // This is the scroll position (in pixels) where the header should become sticky

      if (window.scrollY > stickyPoint) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    } else {
      console.warn(
        "Header element not found. Check if the element with class 'header' exists in the DOM."
      );
    }
  }

    //-------------------------------------------------------------------------
  //call the fuction when i scroll then fix the
  //-------------------------------------------------------------------------

  window.onscroll = makeHeaderSticky;

  return (
    /*____________________________Header Main_______________________*/
    <div className="header">
      <div className="header-with-control-container">
        <HeaderItems
          structuredData={structuredData}
          setStructuredData={setStructuredData}
        />
      </div>
    </div>
    //___________________________end__________________________________//
  );
}

export default HeaderMain;
