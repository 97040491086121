import React, { useState, useEffect } from "react";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import HeroSection from "./HeroSection";
import AboutInternship from "./AboutInternship";
import Benefits from "./Benefits";
import Teach3InstructorFAQ from "../TeachUSonTPA/Teach3InstructorFAQ";
import BackToTop from "../BacktoTop/BackToTop";
import PageLoading from "../PageLoading/PageLoading";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";

function InternshipMain() {
  const [isLoading, setIsLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);

  //-------------------------------------------------------------------------
  //Page Loading and FAQ data from backend
  //-------------------------------------------------------------------------
  useEffect(() => {
    fetch("http://127.0.0.1:8000/api/FAQ_fetch_all")
      .then((response) => response.json())
      .then((data) => {
        const approvedFaqs = data
          .filter((faq) => faq.IsApproved === "1")
          .filter((faq) => faq.Category.includes("Internship"));

        const mappedFaqData = approvedFaqs.map((faq) => ({
          question: faq.Question,
          answer: faq.Answer,
        }));

        console.log("Mapped FAQ Data:", mappedFaqData);
        setFaqData(mappedFaqData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
          <HeaderSmallBar />
          <HeaderMain />
          <HeroSection />
          <AboutInternship />
          <Benefits />
          <Teach3InstructorFAQ faqData={faqData} headerName={"Interns FAQs"} />
          <FooterMain />
          <BackToTop />
        </>
      )}
    </div>
  );
}

export default InternshipMain;
