import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from './Components/Home/LandingPage';
import SEOComponnet from './Components/SEO/SEOComponnet';
import TeachUsOnTPA from './Components/TeachUSonTPA/TeachUsOnTPAMain';
import SignIn from './Components/Common/SignIn';
import SignUp from './Components/Common/SignUP';
import InternshipMain from './Components/Internship/InternshipMain';
import HireTalentMain from './Components/HireTalent/HireTalentMain';
import FooterMain from './Components/Footer/FooterMain';
import ContactMain from './Components/ContactUs/ContactMain';
import AboutMain from './Components/AboutUs/AboutMain';
import CourseCard from './Components/CourseCard/CourseCard';
import CourseTemplateMainPage from './Components/CoursePage/CourseTemplateMainPage';
import JobMain from './Components/JobPortal/JobMain';
import ReviewCard from './Components/Home/TestimolsSection/ReviewCard';
import CorporateMain from './Components/CorporateTraining/CorporateMain';
import ReviewLandingPage from './Components/Review/ReviewLandingPage';
import AllCourseLandingPage from './Components/AllCoursePage/AllCourseLandingPage';
import SignOut from './Components/Common/SignOut';
import ProfileManagement from './Components/ProfileManagement/Pm_LandingPage';
import JobDetailsPage from './Components/JobPortal/JobDetailsPage';
import ComingSoon from './Components/Blog/ComingSoon';
import InterViewQuestionLandingPage from './Components/InterViewQuestion/InterViewQuestionLandingPage';
import ResumeMain from './Components/ResumeTemplate/ResumeMain';
import TrackWebsiteMain from './Components/TrackWebsite/TrackWebsiteMain';





function Main() {
  return (
    
    <Router>

      <SEOComponnet url="https://talentpool-academy.in/" />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/teachontpa" element={<TeachUsOnTPA />} />
        <Route path="/internship" element={<InternshipMain/>} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/register" element={<SignUp/>} />
        <Route path="/hireTalent" element={<HireTalentMain />} />
        <Route path="/footer" element={<FooterMain/>}/>
        <Route path="/contact" element={<ContactMain/>}/>
        <Route path="/about" element={<AboutMain/>}/>
        <Route path="/card" element={<CourseCard/>}/>
        <Route path="/enquiry" element={<ReviewCard/>}/>
        <Route path="/course/:category/:courseName" element={<CourseTemplateMainPage />} />
        <Route path="/job_portal" element={<JobMain/>}/>
        <Route path="/job/:id" element={<JobDetailsPage/>} />
        <Route path='/Corporate' element={<CorporateMain/>}/>
        <Route path='/AllCourse' element={<AllCourseLandingPage/>}/>
        <Route path='/review' element={<ReviewLandingPage/>}/>
        <Route path='/signout' element={<SignOut/>}/>
        <Route path='/update_profile' element={<ProfileManagement/>}/>
        <Route path='/blog' element={<ComingSoon/>}/>
        <Route path='/interview_questions' element={<InterViewQuestionLandingPage/>} />
        <Route path='/resume' element={<ResumeMain/>}/>
        
      </Routes>
        
      <TrackWebsiteMain/>
    </Router>
  )
}

export default Main;