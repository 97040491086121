import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReviewCard from "./ReviewCard";
import axios from "axios";
import { baseURL } from "../../../http";

function ReviewCardCarousel() {
  const [reviews, setReviews] = useState([]);

  //--------------------------------------------------------------------------------
  // Fetch reviews data when component mounts
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(baseURL + "/review_fetch_all");
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
    };

    fetchReviews();
  }, []);
  //-------------------------------------------------------------------------
  //carousel responsive card render
  //-------------------------------------------------------------------------
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1100 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1100, min: 860 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 860, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="review-card-carousel-background-color">
      <div className="review-card-carousel-width-container">
        {/* _________________carousel component call__________________ */}
        <div className="review-card-carousel-container">
          <Carousel
            showDots={true}
            renderDotsOutside={false}
            itemClass="carousel-item-padding-40-px"
            autoPlaySpeed={1500}
            loop={true}
            autoPlay={true}
            infinite={true}
            responsive={responsive}
          >
            {/* _________________Review card component call___________ */}
            {reviews && reviews.map((review, index) => (
              <ReviewCard
                key={index}
                Image={review.Image}
                Name={review.Name}
                Designation={review.Designation}
                Description={review.Description}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default ReviewCardCarousel;
