import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { baseURL } from "../../http";
import axios from "axios"; // Import Axios
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { customAlert } from "../SweetAlertCommon/Custom";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";

function PmTab2Resume({ callPercentage }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const [resumeFile, setResumeFile] = useState(null);
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [resName, setResName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showSelectedFileName, setShowSelectedFileName] = useState(false); // Loading state

  //--------------------------------------------------------------------------------
  // Resume Drop function initialization - Step 1
  //--------------------------------------------------------------------------------
  const onDrop = (acceptedFiles) => {
    setResumeFile(acceptedFiles[0]);
    setShowSelectedFileName(true);
  };

  //--------------------------------------------------------------------------------
  // Resume On Drop function initialization - Step 2
  //--------------------------------------------------------------------------------
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false, // Ensure only a single file can be selected
  });
  //--------------------------------------------------------------------------------
  // Fetching the Resume Datas
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchResName();
  }, []);

  
  useEffect(() => {
    saveResume();
  }, [resumeFile]);

  const fetchResName = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/getprofile`,
        { email: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (response.data.resumeFileName && response.data.resumeFileExtension) {
          // setResName(`${response.data.resumeFileName}.${response.data.resumeFileExtension}`);
          setResName(`${response.data.resumeFileName}`);
        } else {
          setResName("No Resume");
        }
      } else {
        console.error("Error fetching ResHead");
        setResName("No Resume");
      }
    } catch (error) {
      console.error("Error fetching ResHead:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Save Resume API
  //--------------------------------------------------------------------------------

  const saveResume = async () => {
    try {
      if (resumeFile) {
        setLoading(true); // Start loading
        const formData = new FormData();
        formData.append("document", resumeFile);

        const response = await axios.post(
          `${baseURL}/uploadResume/${userEmail}`,
          formData
        );

        if (response.status === 200) {
          console.log("FE-Document uploaded successfully");
          customAlert("", "Resume Uploaded", "success");
          fetchResName();
          callPercentage();
          setShowSelectedFileName(false);
        } else {
          console.error("Error uploading document:", response.statusText);
        }
      } else {
        console.error("No resume file selected");
      }
    } catch (error) {
      console.error("Network error while uploading document:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  //--------------------------------------------------------------------------------
  // Delete Resume API
  //--------------------------------------------------------------------------------

  const deleteResume = async () => {
    let response = null;
    let sms = null;

    try {
      response = await axios.delete(`${baseURL}/deleteResume/${userEmail}`);

      if (response.status === 200) {
        customAlert("", "Resume Deleted", "success");
        fetchResName();
        callPercentage();
      } else {
        sms = response.data.message;
        customAlert("", sms, "error");
      }
    } catch (error) {
      console.error("Network error while deleting resume:", error);

      if (sms === null) {
        customAlert("", "Resume doesn't exist", "error");
      } else {
        customAlert("", sms, "error");
      }
    }
  };

  //--------------------------------------------------------------------------------
  // Download Resume API
  //--------------------------------------------------------------------------------
  const downloadResume = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/downloadResume/${userEmail}`,
        {
          responseType: "blob", // Important to specify responseType as 'blob'
        }
      );

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;

        // Explicitly set the filename with .docx extension
        const fileName = resName;

        a.setAttribute("download", fileName); // Set the download attribute with the filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Error downloading document:", response.statusText);
      }
    } catch (error) {
      console.error("Network error while downloading document:", error);
    }
  };

  return (
    <>
      {loading && <ProcessProgressCircle RequiredProgressName="Processing" />}{" "}
      {/* Show loading indicator */}
      {/* ___________________________________ Tab Heading _______________________________ */}
     
      <div className="pmTabHeadings2">Resume </div>
      <p className="pmTabtagline">
        Resume is the most important document recruiters look for. Recruiters
        generally do not look at profiles without resumes.
      </p>
      <div className="pmResumeNameContainer">
        {/* ___________________________________ Resume Name _______________________________ */}
          <span className="pmResumeName"> {resName} </span>

        {/* ___________________________________ Resume download Button ____________________ */}

        <div>
          <Tooltip title="Download Resume" arrow>
            <IconButton onClick={downloadResume}>
              <FileDownloadOutlinedIcon className="pmResumeDownload" />
            </IconButton>
          </Tooltip>
         
          {/* ___________________________________ Resume delete  Button ____________________ */}
          <Tooltip title="Delete Resume" arrow>
            <IconButton
              variant="contained"
              color="error"
              onClick={deleteResume}
            >
              <DeleteIcon className="pmResumeDelete" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {/* ___________________________________ Resume drop upload container ______________ */}
      <div className="pmUpdResumeContainer">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <button
            className="pmBtnUpdResume"
            onClick={saveResume}
          >
           {resName ==='No Resume' ? 'Upload Resume':'Update Resume'} 
          </button>

          <div className="pmResumeDim">
            <p>
              Supported Formats: doc, docx, pdf, upto 2 MB <br />
              Drag 'n' drop your resume file here, or click to select a file
            </p>
          </div>

          {resumeFile && showSelectedFileName && (
            <p>Selected file: {resumeFile.name}</p>
          )}
        </div>
      </div>
    </>

  );
}

export default PmTab2Resume;
