import React, { useState } from "react";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";

function InterViewQuestionCard({ question = {}, onEdit, formData }) {
  const [isExpanded, setIsExpanded] = useState(false);

  //-------------------------------------------------------------------------
  // Convert array to a comma-separated string
  //-------------------------------------------------------------------------

  const skillsString = formData.Skills.map((skill) => skill.trim()).join(", ");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={`interview-question-card ${
        isExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div
        className={`interview-question-container ${
          isExpanded ? "expanded" : "collapsed"
        }`}
      >
        {question.Question || ""}
        <Tooltip title="Edit Question" arrow>
          <IconButton>
            <Edit
              onClick={onEdit}
              className="buttoncommon-edit-geninfo"
              color="primary"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div className="interview-answer-container">
        <div
          className={`interview-answer-container-description ${
            isExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: question.Answer || "" }} />
          {/* {question.Answer} */}
        </div>
        <div className="read-more-less-text-alignment">
          <button onClick={toggleExpand} className="expand-button">
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
        <div className="interview-answer-image-container"></div>
        <div className="interview-answer-codesnap-container"></div>
      </div>
      <div className="interview-footer-container">
        {formData.InterviewDate && (
          <div className="footer-each-container1">
            <CalendarMonthOutlinedIcon className="footer-each-conttainer-icons" />
            &nbsp;
            <span className="details-information-span">
              {formData.InterviewDate || ""}
            </span>
          </div>
        )}
        {formData.Designation && (
          <div className="footer-each-container">
            <BadgeOutlinedIcon className="footer-each-conttainer-icons" />
            &nbsp;
            <span className="details-information-span">
              {formData.Designation || ""}
            </span>
          </div>
        )}
        {skillsString && (
          <div className="footer-each-container">
            <WorkspacePremiumOutlinedIcon className="footer-each-conttainer-icons" />
            &nbsp;
            <span className="details-information-span">
              {skillsString || ""}
            </span>
          </div>
        )}
        {formData.Company && (
          <div className="footer-each-container">
            <ApartmentOutlinedIcon className="footer-each-conttainer-icons" />
            &nbsp;
            <span className="details-information-span">
              {formData.Company || ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterViewQuestionCard;
