import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import Autocomplete from "@mui/material/Autocomplete";

import {
  getCurrentDateTime,
  getLocationInfo,
  getUtcTime,
} from "../Common/Utilities_Function";
import "./enquiryform.css";
import { baseURL } from "../../http";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { customAlert } from "../SweetAlertCommon/Custom";


const EnquiryFormModal = ({ open , setOpen, EnquiryHeading }) => {

    //-------------------------------------------------------------------------
  //Handle the Modal close function
  //-------------------------------------------------------------------------

  const handleClose = () => {
    setFormData({
      Name: "",
      Email: "",
      Phone: "",
      EnquiryType: EnquiryHeading || "General",
      Message: "",
      Created_by: localStorage.getItem("HTES_user_id"),
      Modified_by: localStorage.getItem("HTES_user_id"),
      Created_date: getCurrentDateTime(),
      Modified_date: getCurrentDateTime(),
      Latitude: "",
      Longitude: "",
      UTC_Time: getUtcTime(),
      Status: "Not Assigned",
    });
    setPhone("");
    setCountryCode("in");
    setErrors({});
    setOpen(false);
    
  };

  //-------------------------------------------------------------------------
  //States
  //-------------------------------------------------------------------------
  const [courseNames, setCourseNames] = useState([]);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("in");
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    EnquiryType: EnquiryHeading || "General",
    Message: "",
    Created_by: localStorage.getItem("HTES_user_id"),
    Modified_by: localStorage.getItem("HTES_user_id"),
    Created_date: getCurrentDateTime(),
    Modified_date: getCurrentDateTime(),
    Latitude: "",
    Longitude: "",
    UTC_Time: getUtcTime(),
    Status: "Not Assigned",
  });

  const [errors, setErrors] = useState({});

  //-------------------------------------------------------------------------
  //Call the function api to collect the course names
  //-------------------------------------------------------------------------
  useEffect(() => {
    axios.get(`${baseURL}/getcourse`)
      .then((response) => {
        const data = response.data;

        if (data && Array.isArray(data)) {
          // Filter the data for published courses and extract course names
          const publishedCourseNames = data
            .filter(item => item.isPublished === "1")
            .map(item => item.CourseName);

          setCourseNames(publishedCourseNames);
        } else {
          console.error("Unexpected response data:", data);
          setCourseNames([]); // Set to an empty array if data is unexpected
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setCourseNames([]); // Set to an empty array in case of error
      });
  }, [ open]);

  //-------------------------------------------------------------------------
  //Effect to get location info
  //-------------------------------------------------------------------------
  useEffect(() => {
    const fetchLocation = async () => {
      if (!formData.Longitude) {
        const location = await getLocationInfo();
        if (location) {
          setFormData((prevData) => ({
            ...prevData,
            Latitude: location.latitude,
            Longitude: location.longitude,
          }));
        }
      }
    };

    fetchLocation();
  }, []);

  //-------------------------------------------------------------------------
  //Handle the Text fields Changes function
  //-------------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //-------------------------------------------------------------------------
  //Handle the Phone input change
  //-------------------------------------------------------------------------
  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());
    setFormData({
      ...formData,
      Phone: value,
    });
  };

  //-------------------------------------------------------------------------
  //Handle the Enquiry input change
  //-------------------------------------------------------------------------
  const handleEnquiryTypeChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      EnquiryType: newValue,
    }));
  };

  //-------------------------------------------------------------------------
  //Validate form fields
  //-------------------------------------------------------------------------
  const validate = () => {
    let tempErrors = {};
    tempErrors.Name = formData.Name ? "" : "Name is required";
    tempErrors.Email = formData.Email
      ? /\S+@\S+\.\S+/.test(formData.Email)
        ? ""
        : "Email is not valid"
      : "Email is required";

    // Validate phone number based on the selected country
    const phoneNumber = parsePhoneNumberFromString(formData.Phone, countryCode);
    if (phoneNumber) {
      tempErrors.Phone = phoneNumber.isValid()
        ? ""
        : "Phone number is not valid";
    } else {
      tempErrors.Phone = "Phone number is not valid";
    }

    tempErrors.Message = formData.Message ? "" : "Question is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  //-------------------------------------------------------------------------
  //Handle the API for sending the enquiry
  //-------------------------------------------------------------------------
  const handleSubmit = async () => {
    const formattedPhone = formData.Phone.replace(/[^0-9]/g, "").slice(-10);
    if (validate()) {
      const updatedFormData = {
        ...formData,
        UTC_Time: getUtcTime(), // Update the UTC time before submitting
        Phone: formattedPhone,
      };
      localStorage.setItem('Enquiry_Login',formData.Email)

      try {
        const response = await axios.post(
          baseURL + "/createEnquiry",
          updatedFormData
        );
        customAlert('','Enquiry submitted Successfully','success')
        console.log(response)
        handleClose()

      } catch (error) {
        console.error("There was an error submitting the form!", error);
      }
    }
  };

  return (
    
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="enquiry-form-modal"
      aria-describedby="enquiry-form-description"
    >
      {/* ______________________Modal header and x icon_____________________ */}
      
      <div className="enquiry-form-container">
        <div className="enquiry-form-content-container">
          <div>
            <IconButton
              id="button-zoom"
              className="enquiry-form-addeducationalMapmodalcancel"
              onClick={handleClose}
            >
              <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          <div className="enquiry-table-header">
            Enquiry For -{" "}
            { formData.EnquiryType}
          </div>
          {/* ______________________End_____________________ */}
          {/* _________________Grid Container all the Text fields_________________ */}
          <div className="enquiry-table-grid-container">
            <TextField
              label="Name"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Name}
              helperText={errors.Name}
            />
            <TextField
              label="Email"
              name="Email"
              type="email"
              value={formData.Email}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Email}
              helperText={errors.Email}
            />
            {/* _____________Phone input is a Component and it has its own property(inline css)_________ */}
            <div className={errors.Phone ? "phone-input-error" : ""}>
              <PhoneInput
                country={countryCode.toLowerCase()} // Default country
                value={phone}
                onChange={(value, country) => handlePhoneChange(value, country)}
                inputStyle={{
                  width: "100%",
                  height: "55px",
                  fontSize: "16px",
                }}
                buttonStyle={{
                  background: "white",                  
                  border: errors.Phone ? "1px solid red" : "",
                  borderRight: "none",
                }}
                dropdownStyle={{
                  fontSize: "16px",
                }}
                placeholder="Phone"
              />
              {errors.Phone && (
                <div className="helper-text-phone-container">
                  {errors.Phone}
                </div>
              )}
            </div>
            {/* ___________________________________end__________________________________ */}
            <Autocomplete
            disableClearable
              options={["Course","Career","Instructor", "Internship", "Training", "General", ...courseNames]}
              value={formData.EnquiryType}
              onChange={handleEnquiryTypeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enquiry For"
                  fullWidth
                  required
                  error={!!errors.EnquiryType}
                  helperText={errors.EnquiryType}
                />
              )}
            />
            <TextField
              label="Your Question"
              name="Message"
              multiline
              rows={3.7}
              value={formData.Message}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Message}
              helperText={errors.Message}
            />
          </div>
          {/* ________________________end____________________ */}
          {/* _________________Button section_________________ */}
          <div className="enquiry-table-button-alignment">
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              id="button-zoom"
            >
              <NearMeIcon className="buttoniconcommon" /> Submit
            </Button>
          </div>
          {/* ________________________End______________________ */}
        </div>
      </div>
      
    </Modal>
    
  );
};

export default EnquiryFormModal;
