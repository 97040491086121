// ResumeSidebar.js
import React from 'react';
import ProfilePhotoSection from './ProfilePhotoSection';
import ContactSection from './ContactSection';
import EducationSection from './EducationSection';
import SkillsSection from './SkillsSection';
import LanguagesSection from './LanguagesSection';
import '../Resume.css';

const ResumeSidebarMain = ({ profilePhoto,setProfilePhoto, contactInfo, education, skills, languages, cloneEducationItem, handleDeleteEducation }) => {
  return (
    <div className="sidebar">
      <ProfilePhotoSection profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}/>
      <ContactSection contactInfo={contactInfo} />
      <EducationSection
        education={education}
        cloneEducationItem={cloneEducationItem}
        handleDeleteEducation={handleDeleteEducation}
      />
      <SkillsSection skills={skills} />
      <LanguagesSection languages={languages} />
    </div>
  );
};

export default ResumeSidebarMain;
