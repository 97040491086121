import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import '../Resume.css';
import ImageCropperTemplate2 from './../../Templates/ImageCroper/ImageCropperTemplate2';
import { Tooltip } from '@mui/material';

const ProfilePhotoSection = ({ profilePhoto, setProfilePhoto }) => {
  const [photoFile, setPhotoFile] = useState(null);
  const [open, setOpen] = useState(false); // State to control the modal
  const [croppedImageDataUrl, setCroppedImageDataUrl] = useState("");

  // Function to open the modal
  const handleEditClick = () => {
    setOpen(true);
  };

  // Function to handle image save in modal
  const handleSave = () => {
    if (croppedImageDataUrl) {
      setProfilePhoto(croppedImageDataUrl); // Save the cropped image URL
      setOpen(false); // Close the modal
    }
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setPhotoFile(URL.createObjectURL(file)); // Show the selected image preview
      setOpen(true); // Open the modal for cropping
    }
  };

  return (
    <div className="photo-section">
      <img src={photoFile || profilePhoto} alt="" className="profile-photo" />
      
      <div className="edit-icon">
        <Tooltip title="Upload Picture" arrow>
        <EditIcon className="edit-icon" onClick={handleEditClick} />
        </Tooltip>
        <input
          id="photo-upload"
          type="file"
          accept="image/*"
          className="photo-upload"
          onChange={handleFileChange}
        />
      </div>

      {/* Image Cropper Modal */}
      <ImageCropperTemplate2
        open={open}
        setOpen={setOpen}
        croppedImageDataUrl={croppedImageDataUrl}
        setCroppedImageDataUrl={setCroppedImageDataUrl}
        handleSave={handleSave}
      />
    </div>
  );
};

export default ProfilePhotoSection;
