import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { MDBRadio } from "mdb-react-ui-kit";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateIcon from "@mui/icons-material/Update";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function PmTab5EmploymentEditModal({
  editData,
  setEditData,
  selectedOptionsKeySkills,
  keySkillOptions,
  setSelectedOptionsKeySkills,
  saveEditedItem,
  handleItemDelete,
  invalidFields,
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const [displayYear, setDisplayYear] = useState(null);
  const [displayMonth, setDisplayMonth] = useState(null);

  //--------------------------------------------------------------------------------
  //  Calculate the years and month duration (Automatically from datepicker)
  //--------------------------------------------------------------------------------
  useEffect(() => {
    handleCalcDuration(editData.fulltimeWorkFrom, editData.fulltimeWorkTill);
  }, []);

  const handleCalcDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return;

    let start;
    let end;

    if (editData.curEmp === "Yes") {
      start = new Date(startDate);
      end = new Date(); // Set end date to today
    } else {
      start = new Date(startDate);
      end = new Date(endDate);
    }
    // alert('start'+start+'  end='+end)
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    // Adjust the years and months if the end month is earlier than the start month
    if (months < 0) {
      years--;
      months += 12;
    }

    console.log("start =", start);
    console.log("end =", end);
    console.log("years =", years);
    console.log("months =", months);

    setEditData((prevData) => ({
      ...prevData,
      fullExpYears: years,
      fullExpMonths: months,
    }));

    setDisplayMonth(months);
    setDisplayYear(years);
  };

  return (
    <div className="profileModal">
      {/* ___________________________________  Heading       _______________________________ */}
      <div className="pmModHeader2">
        <span className="pmTabHeadings2">
          Edit Employment{" "}
        </span>
        <Tooltip title="Delete">
          <IconButton
            variant="contained"
            color="error"
            onClick={() => handleItemDelete(editData.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className="pmModalContainer pmModalBox2">
        {/* ___________________________________  Current Employment_______________________________ */}
        <div className="EmploymentModal_section">
          <label
            className={`pmLabel ${invalidFields.curEmp ? "error_Pmlabel" : ""}`}
          >
            Is this your current employment ?
          </label>
          <div className="PMradio_group">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={editData.curEmp}
              onChange={(e) =>
                setEditData({ ...editData, curEmp: e.target.value })
              }
              className="PmRadioView"
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
                className="PmRadioItem"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
                className="PmRadioItem"
              />
            </RadioGroup>
          </div>
        </div>
        

        {/* ___________________________________   Employment Type _______________________________ */}
        <div className="EmploymentModal_section">
          <label
            className={`pmLabel ${
              invalidFields.empType ? "error_Pmlabel" : ""
            }`}
          >
            Employment type
          </label>
          <div className="PMradio_group">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={editData.empType}
              onChange={(e) =>
                setEditData({ ...editData, empType: e.target.value })
              }
              className="PmRadioView"
            >
              <FormControlLabel
                value="Full-Time"
                control={<Radio />}
                label="Full-Time"
                className="PmRadioItem"
              />
              <FormControlLabel
                value="Internship"
                control={<Radio />}
                label="Internship"
                className="PmRadioItem"
              />
            </RadioGroup>
          </div>
        </div>
        

        {/* ___________________________________   Current Company _______________________________ */}
        <div className="formProfile">
          <TextField
            label={
              editData.curEmp === "Yes"
                ? "Current Company Name"
                : "Previous Company Name"
            }
            fullWidth
            value={editData.currentCompanyname || ""}
            onChange={(e) =>
              setEditData({
                ...editData,
                currentCompanyname: e.target.value,
              })
            }
            InputProps={{
              className: "input_Custom_Style",
            }}
            error={invalidFields.currentCompanyname}
            helperText={invalidFields.currentCompanyname ? "* mandatory" : ""}
          />
        </div>
        

        {/* ___________________________________   Designation___________________________________ */}
        <div className="formProfile">
          <TextField
            label={
              editData.curEmp === "Yes"
                ? "Current Designation"
                : "Previous Designation"
            }
            fullWidth
            value={editData.currentDesignation || ""}
            onChange={(e) =>
              setEditData({
                ...editData,
                currentDesignation: e.target.value,
              })
            }
            InputProps={{
              className: "input_Custom_Style",
            }}
            error={invalidFields.currentDesignation}
            helperText={invalidFields.currentDesignation ? "* mandatory" : ""}
          />
        </div>
       

        {/* ___________________________________   Employment Experience __________________________ */}
        {editData.empType === "Full-Time" && (
          <div className="experience-container">
            <div className="experience-fields">
              <div className="pmBasFields">
                {/*______________ Years ______________ */}
                <TextField
                  disabled
                  fullWidth
                  value={displayYear}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    className: "input_Custom_Style",
                    endAdornment: (
                      <InputAdornment position="end">
                        Experience Year(s)
                      </InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </div>

              <div className="pmBasFields1">
                <TextField
                  disabled
                  fullWidth
                  value={displayMonth}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    className: "input_Custom_Style",
                    endAdornment: (
                      <InputAdornment position="end">
                        Experience Month(s)
                      </InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </div>
            </div>
           
          </div>
        )}

        {editData.empType === "Full-Time" ? (
          <>
            {/*______________ Joining year -Full time ______________ */}
            <div className="">
             <div>
              <div>
                {/*______________ From ______________ */}
                <div>
                  <TextField
                    required
                    label="From"
                    type="date"
                    fullWidth
                    value={editData.fulltimeWorkFrom}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        fulltimeWorkFrom: e.target.value,
                      });
                      handleCalcDuration(
                        e.target.value,
                        editData.fulltimeWorkTill
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                      inputProps: {
                        style: { textTransform: "uppercase" },
                        max:
                          editData.curEmp === "Yes"
                            ? new Date().toISOString().split("T")[0]
                            : editData.curEmp === "No"
                            ? editData.fulltimeWorkTill
                            : "",
                      }, // Ensure the start date cannot be after the end date
                    }}
                    error={invalidFields.fulltimeWorkFrom}
                    helperText={
                      invalidFields.fulltimeWorkFrom ? "* mandatory" : ""
                    }
                  />
                </div>
                {/*______________ TIll ______________ */}
                {editData.curEmp == "No" && (
                  <div className="from-data-and-to-date">
                   
                    <TextField
                      label="To"
                      required
                      type="date"
                      fullWidth
                      value={editData.fulltimeWorkTill} // YYYY-MM-DD - format required on database
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          fulltimeWorkTill: e.target.value,
                        });
                        handleCalcDuration(
                          editData.fulltimeWorkFrom,
                          e.target.value
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        className: "input_Custom_Style",
                        inputProps: {
                          style: { textTransform: "uppercase" },
                          min: editData.fulltimeWorkFrom,
                          max: new Date().toISOString().split("T")[0],
                        }, // Ensure the start date cannot be after the end date
                      }}
                    />
                  </div>
                )}
              </div>
              </div>
            </div>

            {/*__________________ Current Salary -input __________________ */}
            <div>
              <div>
                <div className="formProfile">
                  <TextField
                  label='Current Salary'
                    fullWidth
                    value={editData.curSal || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEditData({ ...editData, curSal: inputValue });
                      }
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                    }}
                  />
                </div>
              
              </div>
            </div>

            {/*_____________ Skills used -multi select input _____________ */}
            <div>
              <div>
                <CustomMultiSelect
                  label='Skills Used'
                  options={keySkillOptions} // send dropdown options
                  selectedOption={selectedOptionsKeySkills} // for value prop
                  setSelectedOption={setSelectedOptionsKeySkills} // for onChange prop
                  chipClassName="jobPostLocationtag"
                  chipDeleteClassName="jobPostLocationDeleteX"
                  placeholder="Type to Select KeySkill(s)"
                />
                
              </div>
            </div>

            {/*__________________ Job Profile - input __________________ */}
            <div>
              <div>
                <TextField
                  label='Job profile'
                  fullWidth
                  multiline
                  rows={3}
                  value={editData.jobProfile || ""}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      jobProfile: e.target.value,
                    })
                  }
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              </div>
            </div>
         
          </>
        ) : (
          <>
            {/*_____________ Location (internship) - input _____________ */}
            <div>
              <div>
                <div>
                  <TextField
                  label='Location'
                    fullWidth
                    multiline
                    value={editData.internshipLocation || ""}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        internshipLocation: e.target.value,
                      })
                    }
                    InputProps={{
                      className: "input_Custom_Style",
                    }}
                  />
                </div>

            
              </div>
            </div>

            {/*_____________ Department (internship) - input _____________ */}
            <div>
              <div>
                <TextField
                  fullWidth
                  label='Department'
                  multiline
                  value={editData.internshipDepartment || ""}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      internshipDepartment: e.target.value,
                    })
                  }
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
               
              </div>
            </div>

            {/*_____________ WorkingFrom (internship) From & TIll - dropdownt ______ */}
            <div>             
              <div>
                <div>
               
                  <TextField
                  label={'From'}
                    required
                    type="date"
                    fullWidth
                    // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
                    value={editData.internshipWorkFrom}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        internshipWorkFrom: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                      inputProps: {
                        style: { textTransform: "uppercase" },
                        max:
                          editData.curEmp === "Yes"
                            ? new Date().toISOString().split("T")[0]
                            : editData.curEmp === "No"
                            ? editData.internshipWorkFrom
                            : "",
                      },
                    }}
                  />
                </div>
                {editData.curEmp == "No" && (
                  <div className="from-data-and-to-date">
                   
                   
                    <TextField
                      required
                      label={'To'}
                      type="date"
                      fullWidth
                      // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
                      value={editData.internshipWorkTill}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          internshipWorkTill: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        className: "input_Custom_Style",
                        inputProps: {
                          style: { textTransform: "uppercase" },
                          min: editData.internshipWorkFrom,
                          max: new Date().toISOString().split("T")[0],
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
        

            {/*_______________________ Internship - Stipend __________________________ */}
            <div>
              <div>
                <div className="formProfile">
                  <TextField
                      label={'Stipend'}
                    fullWidth
                    value={editData.monthlyStipend || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEditData({
                          ...editData,
                          monthlyStipend: inputValue,
                        });
                      }
                    }}
                    InputProps={{
                      className: "input_Custom_Style",
                    }}
                  />
                </div>
     
              </div>
            </div>
          </>
        )}
    
      </div>

      {/* ________________________________ Save  _________________________________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveEditedItem}
        >
          <UpdateIcon className="buttonicons" />
          Update
        </Button>
      </div>
    </div>
  );
}

export default PmTab5EmploymentEditModal;
