import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DevicesIcon from "@mui/icons-material/Devices";
import BarChartIcon from "@mui/icons-material/BarChart";
import FeatureCard1 from "./FeatureCard1";
import FeatureCard2 from "./FeatureCard2";
import OurVision from "../OurVision/OurVision";
import Elearninicon from "./FeatuesImages/Features1.jpg";
import Skillset from "./FeatuesImages/skills1.png";
import Adapt from "./FeatuesImages/Features2.jpg";
import Mentor from "./FeatuesImages/Features6.png";
import StaticStics from "./FeatuesImages/Features3.png";
import ProfileEnhancement from "./FeatuesImages/Features7.jpg";
import JobPortal from "./FeatuesImages/Features8.jpg";
import MockInterview from "./FeatuesImages/Features9.jpg";
import Support from "./FeatuesImages/Features10.jpg";
import CareerTransition from "./FeatuesImages/Features11.jpg";
import Trends from "./FeatuesImages/Features12.jpg";
import Handsone from "./FeatuesImages/Features4.jpg";
import Networking from "./FeatuesImages/Features5.jpg";
import Profetional from "./FeatuesImages/Profestional_development.png";
import EmpoweringCareers from "./FeatuesImages/Features13.jpg";
import TransformingTalent from "./FeatuesImages/Features14.jpg";
import ConnectingIndustry from "./FeatuesImages/Features15.jpg";
import BuildingSuccess from "./FeatuesImages/Features16.jpg";
import { FaBriefcase, FaChalkboardTeacher, FaUsers, FaDollarSign, FaProjectDiagram, FaUserTie, FaNetworkWired, FaLaptopCode, FaHeadset, FaChartLine, FaSyncAlt, FaComments  } from 'react-icons/fa';


function FeatureLandingPage() {
  return (
    <div className="featurecard-background-container">
      <div className="feature-landing-page-width-container">
        {/* __________________heading________________________ */}
        <div className="feature-heading-conatainer">Our Mission and Vision</div>
        {/* ___________________________Call the feature card(1st section) component_____________ */}
        <div className="feature-content-container-content">
          <FeatureCard2
            Image={EmpoweringCareers}
            Heading="Empowering Careers"
            // Content="Designed to match top job skills"
          />
          <FeatureCard2
            Image={TransformingTalent}
            Heading="Transforming Talent"
            // Content="TPA enhances learning for growth"
          />

          <FeatureCard2
            Image={ConnectingIndustry}
            Heading="Connecting Industry"
            // Content="Choose online, offline, or a mix"
          />
          <FeatureCard2
            Image={BuildingSuccess}
            Heading="Building Success"
            // Content="Get certified upon course completion"
          />
          {/* __________________________end____________________________ */}
        </div>
      </div>
      {/* _______________________our vision component_________________ */}
      <div className="feature-landing-page-width-container1">
        <OurVision />
      </div>
      {/* __________________end__________________________________ */}
      {/* _______________________Main feature section component__________________ */}
      <div className="feature-landing-page-width-container">
        <div className="feature-content-container-content2">
          
          <FeatureCard1
            Icon={FaBriefcase}
            Heading="Job-Oriented"
            Content="Industry-focused content with job guarantee"
          />
          <FeatureCard1
            Icon={FaUserTie}
            Heading="Personal Mentorship"
            Content="One-on-one guidance and lifelong support"
          />
          <FeatureCard1
            Icon={FaChalkboardTeacher}
            Heading="Expert Faculty"
            Content="Learn from top-rated industry professionals"
          />
          <FeatureCard1
            Icon={FaDollarSign}
            Heading="Affordable Excellence"
            Content="High-quality training at a great price"
          />
          <FeatureCard1
            Icon={FaNetworkWired}
            Heading="Networking"
            Content="Expand your industry connections"
          />
          <FeatureCard1
            Icon={FaProjectDiagram}
            Heading="Practical Experience"
            Content="Work on live projects"
          />
          
          <FeatureCard1
            Icon={FaUserTie}
            Heading="Profile Enhancement"
            Content="Optimize Naukri and LinkedIn profiles"
          />
          <FeatureCard1
            Icon={FaLaptopCode}
            Heading="Job Portal"
            Content="Access to a job portal with client requirements (coming soon)"
          />
          <FeatureCard1
            Icon={FaComments}
            Heading="Mock Interviews"
            Content="Practice with mock interviews"
          />
          <FeatureCard1
            Icon={FaHeadset}
            Heading="24/7 Support"
            Content="Continuous coordination with students."
          />
          <FeatureCard1
            Icon={FaChartLine}
            Heading="Current Trends"
            Content="Courses aligned with market demands."
          />
          <FeatureCard1
            Icon={FaSyncAlt}
            Heading="Career Transition"
            Content="Assistance for career or domain switches"
          />
        </div>
        {/* ______________________________________end____________________________ */}
      </div>
    </div>
  );
}

export default FeatureLandingPage;
