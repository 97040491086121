import React from "react";
import RatingSection from "./RatingSection";
import ReviewCardCarousel from "./ReviewCardCarousel";
import { useNavigate } from "react-router-dom";

function TestimolLandingPage() {
  const navigate = useNavigate();

  //-------------------------------------------------------------------------
  //Handle function  to route the Review
  //-------------------------------------------------------------------------
  const handleMoreRevieClick = () => {
    navigate("/review");
  };

  return (
    <div className="testimol-landing-page-background-color">
      <div className="testimol-landing-page-width-container">
        {/* ____________________________Heading____________________ */}
        <div className="testimol-section-heading">Testimonials</div>
        {/* ___________________Rating section________________________ */}
        <RatingSection />
        {/* ___________________Review  carousel component________________ */}
        <ReviewCardCarousel />
        {/* _________________More Review button_____________________ */}
        <div className="testimol-section-more-button-container">
          <div
            className="Testimol-section-more-review-button"
            onClick={handleMoreRevieClick}
          >
            More Reviews
          </div>
        </div>
        {/* _____________________________end__________________________ */}
      </div>
    </div>
  );
}

export default TestimolLandingPage;
