import React from "react";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import StayCurrentPortraitOutlinedIcon from "@mui/icons-material/StayCurrentPortraitOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CourseImage from "./CourseImage/CourseInclude.png";
import MenuBookIcon from '@mui/icons-material/MenuBook';

function CourseInclude({ courseContent }) {
  return (
    <div className="courseinclude-width-aligment">
      {/* __________Course include heading and Image section__________________ */}
      <div className="CourseInclude-title">Course Includes</div>
      <div className="course-include-grid">
        <div className="course-include-image-section">
          <img src={CourseImage} alt="course-include-image" />
        </div>
        <div className="course-include-tabs-section">
          {/* __________General info__________________ */}
          <div className="each-include-element-conatiner">
            <BrowserUpdatedIcon className="coursetemplate2-icons-color-alignment" />
            <span>Downloadable Resources</span>
          </div>
          <div className="each-include-element-conatiner">
            <MenuBookIcon className="coursetemplate2-icons-color-alignment" />
            <span>Hands-on Projects</span>
          </div>
          <div className="each-include-element-conatiner">
            <EmojiEventsOutlinedIcon className="coursetemplate2-icons-color-alignment" />
            <span>Certificate of Accomplishment</span>
          </div>
          <div className="each-include-element-conatiner">
            <AllInclusiveOutlinedIcon className="coursetemplate2-icons-color-alignment" />
            <span>Lifetime Membership</span>
          </div>
          <div className="each-include-element-conatiner">
            <StayCurrentPortraitOutlinedIcon className="coursetemplate2-icons-color-alignment" />
            <span>View on Mobile Devices and TVs</span>
          </div>
          <div className="each-include-element-conatiner">
            <DescriptionOutlinedIcon className="coursetemplate2-icons-color-alignment" />
            <span>{courseContent.length} Articals</span>
          </div>
        </div>
        {/* ________________________end____________________ */}
      </div>
    </div>
  );
}

export default CourseInclude;
