import React from "react";
import "./companylogo.css";
import Airbus from "./CompanyLogo/Airbus.png";
import BMW from "./CompanyLogo/BMW.png";
import Boing from "./CompanyLogo/Boing.png";
import Ford from "./CompanyLogo/ford.png";
import Tata from "./CompanyLogo/tata.png";
import Volvo from "./CompanyLogo/volvo.png";
import Mahindra from "./CompanyLogo/mahindra.png";

function CompanyLogosLandingPage() {
  return (
    <div className="company-logo-background-color">
      <div className="company-logo-width-conatiner">
        {/* __________________Heading_________________ */}
        <div className="company-logo-render-conatainer">
          <div className="comapany-logo-heading-conatainer">
            Our Trusted Partners
          </div>
          {/* ____________________Tagline_______________ */}
          <p className="comapany-render-tagline">
            Proudly Collaborating with Leading Companies Worldwide
          </p>
          {/* _________________company logos________________ */}
          <div className="logos-slides">
            <img src={Airbus} alt="Airbus" />
            <img src={BMW} alt="BMW" />
            <img src={Boing} alt="Boing" />
            <img src={Tata} alt="Tata" />
            <img src={Ford} alt="Ford" />
            <img src={Volvo} alt="Volvo" />
            <img src={Mahindra} alt="Mahindra" />
            <img src={Airbus} alt="Airbus" />
            <img src={BMW} alt="BMW" />
            <img src={Boing} alt="Boing" />
            <img src={Tata} alt="Tata" />
            <img src={Ford} alt="Ford" />
            <img src={Volvo} alt="Volvo" />
            <img src={Mahindra} alt="Mahindra" />
            {/* _____________________end___________________ */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyLogosLandingPage;
