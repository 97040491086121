import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import parse, { domToReact } from "html-react-parser";
import "./coursetemplate2.css";

const CourseTemplate2Description = ({ title, content }) => {
  const [isExpand, setIsExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Function to render the content based on the html text
  //--------------------------------------------------------------------------------

  const renderContent = (content) => {
    return content.map((point, parentIndex) => {
      const parsedContent = parse(point, {
        replace: (domNode) => {
          if (
            domNode.name === "li" &&
            domNode.parent &&
            (domNode.parent.name === "ul" || domNode.parent.name === "ol")
          ) {
            const childIndex = domNode.parent.children.indexOf(domNode);
            const uniqueKey = `${parentIndex}-${childIndex}`;
  
            if (domNode.parent.name === "ul") {
              return (
                <li key={uniqueKey}>
                  {/* <FiberManualRecordIcon className="bulletpoints" /> */}
                  {domToReact(domNode.children)}
                </li>
              );
            } else if (domNode.parent.name === "ol") {
              return <li key={uniqueKey}>{domToReact(domNode.children)}</li>;
            }
          }
        },
      });
      return <div key={parentIndex}>{parsedContent}</div>;
    });
  };
  //--------------------------------------------------------------------------------
  // Expan and Hide the conatiner(content container)
  //--------------------------------------------------------------------------------
  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <div className="coursetemplete2-title">{title}</div>
              <IconButton>
                {isExpand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="course-template-expand-icon-color" />
                )}
              </IconButton>
            </div>
          </div>
          {/* ______________________________Render the Description_____________ */}
          {isExpand && (
            <div className="coursetemplate2-content-render-conatiner">
              <div className="coursetemplate2-checkicon-and-points">
                {renderContent(content)}
              </div>
            </div>
          )}
        </div>
        {/* ______________________________end_____________________________ */}
      </div>
    </>
  );
};

export default CourseTemplate2Description;
