import React from "react";
import {
  TextField,
  Button,
  Autocomplete,
  IconButton,
  Tooltip,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import { languageOptions } from "./PmCommonFunction";
import { customAlert } from "../SweetAlertCommon/Custom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";

function PmTab13PersonalDetailModal({
  gender,
  setGender,
  category,
  setCategory,
  maritalStatus,
  setMaritalStatus,
  moreInfo,
  setMoreInfo,
  dateOfBirth,
  setDateOfBirth,
  differentlyAbled,
  setDifferentlyAbled,
  careerBreak,
  setCareerBreak,
  address,
  setAddress,
  hometown,
  setHometown,
  pincode,
  setPincode,
  addPersDetail,
  languageCount,
  setLanguageCount,
  languageArray,
  setLanguageArray,
}) {
  //--------------------------------------------------------------------------------
  //  Function - handleLanguageNameChange
  //--------------------------------------------------------------------------------

  const handleLanguageNameChange = (index, field, value) => {
    const lowerCaseValue = value?.toLowerCase();

    if (
      lowerCaseValue &&
      languageArray.some(
        (language) => language.language?.toLowerCase() === lowerCaseValue
      )
    ) {
      customAlert("", "Already Exists", "warning");
    } else {
      const updatedLanguages = [...languageArray];
      updatedLanguages[index][field] = value;
      setLanguageArray(updatedLanguages);
    }
  };

  //--------------------------------------------------------------------------------
  //  Function - handleLanguageRadioChange
  //--------------------------------------------------------------------------------

  const handleLanguageRadioChange = (index, field, value) => {
    const updatedLanguages = [...languageArray];
    updatedLanguages[index][field] = value;
    setLanguageArray(updatedLanguages);
  };

  //--------------------------------------------------------------------------------
  //  Function - handleDeleteLanguage
  //--------------------------------------------------------------------------------

  const handleDeleteLanguage = (index) => {
    const updatedLanguages = languageArray.filter((item, idx) => idx !== index);
    setLanguageArray(updatedLanguages);
    setLanguageCount(languageCount - 1);
  };

  //--------------------------------------------------------------------------------
  //  useEffect - Initial Addition
  //--------------------------------------------------------------------------------

  // useEffect(() => {
  //   handleAddLanguage();
  // }, []);

  const handleAddLanguage = () => {
    if (languageCount < 6) {
      // Find the next available ID in the range of 1 to 5
      const existingIds = languageArray.map((language) => language.id);
      let newId = 1;
      while (existingIds.includes(newId) && newId <= 6) {
        newId++;
      }

      if (newId <= 6) {
        const newLanguage = {
          id: newId,
          language: "",
          read: false,
          write: false,
          speak: false,
        };

        setLanguageArray([...languageArray, newLanguage]);
        setLanguageCount(languageCount + 1);
      }
    }
  };

  const selectedLanguages = languageArray.map((lang) => lang.language);

  return (
    <div>
      <div className="pmresume-heading-container">
        <span className="pmTabHeadings2">Personal Details</span>
      </div>
      <div className="pmModalContainer-personal-details">
        <div className="pm-personal-details-container">
          {/*_______________________________ Gender _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">Gender</label>

            <div>
              <button
                onClick={() => setGender("Male")}
                className={
                  gender === "Male"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Male
              </button>
              <button
                onClick={() => setGender("Female")}
                className={
                  gender === "Female"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Female
              </button>
              <button
                onClick={() => setGender("Transgender")}
                className={
                  gender === "Transgender"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Transgender
              </button>
            </div>
          </div>

          {/*_______________________________ More information _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">More information</label>

            <div>
              <button
                onClick={() => setMoreInfo("Single parent +")}
                className={
                  moreInfo === "Single parent +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Single parent +
              </button>
              <button
                onClick={() => setMoreInfo("Working mother +")}
                className={
                  moreInfo === "Working mother +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Working mother +
              </button>
              <button
                onClick={() => setMoreInfo("Served in military +")}
                className={
                  moreInfo === "Served in military +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Served in military +
              </button>
              <button
                onClick={() => setMoreInfo("Retired (60+)")}
                className={
                  moreInfo === "Retired (60+)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Retired (60+)
              </button>
              <button
                onClick={() => setMoreInfo("LGBTQ+")}
                className={
                  moreInfo === "LGBTQ+"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                LGBTQ+
              </button>
            </div>
          </div>

          {/*_______________________________ Marital Status _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">Marital Status</label>

            <div>
              <button
                onClick={() => setMaritalStatus("Single/unmarried")}
                className={
                  maritalStatus === "Single/unmarried"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Single/unmarried
              </button>
              <button
                onClick={() => setMaritalStatus("Married")}
                className={
                  maritalStatus === "Married"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Married
              </button>
              <button
                onClick={() => setMaritalStatus("Widowed")}
                className={
                  maritalStatus === "Widowed"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Widowed
              </button>
              <button
                onClick={() => setMaritalStatus("Divorced")}
                className={
                  maritalStatus === "Divorced"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Divorced
              </button>
              <button
                onClick={() => setMaritalStatus("Separated")}
                className={
                  maritalStatus === "Separated"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Separated
              </button>
              <button
                onClick={() => setMaritalStatus("Other")}
                className={
                  maritalStatus === "Other"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Other
              </button>
            </div>
          </div>

          {/*_______________________________ Category _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">Category</label>

            <div>
              <button
                onClick={() => setCategory("General")}
                className={
                  category === "General"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                General
              </button>
              <button
                onClick={() => setCategory("Scheduled Caste (SC)")}
                className={
                  category === "Scheduled Caste (SC)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Scheduled Caste (SC)
              </button>
              <button
                onClick={() => setCategory("Scheduled Tribe (ST)")}
                className={
                  category === "Scheduled Tribe (ST)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Scheduled Tribe (ST)
              </button>
              <button
                onClick={() => setCategory("OBC - Creamy")}
                className={
                  category === "OBC - Creamy"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                OBC - Creamy
              </button>
              <button
                onClick={() => setCategory("OBC - Non Creamy")}
                className={
                  category === "OBC - Non Creamy"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                OBC - Non Creamy
              </button>
              <button
                onClick={() => setCategory("Other")}
                className={
                  category === "Other"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Other
              </button>
            </div>
          </div>

          {/*_______________________________ Differently Abled? _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">Differently Abled?</label>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={differentlyAbled}
              onChange={(e) => setDifferentlyAbled(e.target.value)}
              className="PmRadioView"
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
                className="PmRadioItem"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
                className="PmRadioItem"
              />
            </RadioGroup>
          </div>

          {/*_______________________________ Career break? _______________________________*/}
          <div className="pmLabel-and-container-gap2">
            <label className="pmLabel">Have you taken a career break?</label>

            <div>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={careerBreak}
                onChange={(e) => setCareerBreak(e.target.value)}
                className="PmRadioView"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                  className="PmRadioItem"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  className="PmRadioItem"
                />
              </RadioGroup>
            </div>
          </div>
          {/*_______________________________ Date of Birth _______________________________*/}
          <div className="pm-personal-details-textfileds-alignment">
            <TextField
              label="Date of Birth"
              required
              type="date"
              fullWidth
              value={dateOfBirth}
              onChange={(event) => setDateOfBirth(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              // InputProps={{
              //   inputProps: { style: { textTransform: "uppercase" } },
              // }}
              InputProps={{
                className: "input_Custom_Style",
                inputProps: {
                  style: { textTransform: "uppercase" },
                }, // Ensure the start date cannot be after the end date
              }}
            />
          </div>

          {/*_______________________________ Permanent Address _______________________________*/}

          <div className="pm-personal-details-textfileds-alignment">
            <TextField
              label="Permanent Address"
              fullWidth
              multiline
              minRows={3}
              maxRows={8}
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              InputProps={{
                className: "input_Custom_Style",
              }}
            />
          </div>

          {/*_______________________________ Hometown _______________________________*/}

          <div className="pm-personal-details-textfileds-alignment">
            <TextField
              label="Hometown"
              fullWidth
              value={hometown || ""}
              onChange={(e) => setHometown(e.target.value)}
              InputProps={{
                className: "input_Custom_Style",
              }}
            />
          </div>

          {/*_______________________________ Pincode _______________________________*/}

          <div className="pm-personal-details-textfileds-alignment">
            <TextField
              label="Pincode"
              fullWidth
              value={pincode || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || /^\d{0,6}$/.test(value)) {
                  setPincode(value);
                }
              }}
              InputProps={{
                className: "input_Custom_Style",
              }}
            />
          </div>

          {/*___________________________________ Language Inputs ___________________________________*/}
          {languageArray.map((language, index) => (
            <div key={index} className="experience-container1">
              <div className="experience-fields1">
                <div className="pmBasFields1">
                  <Autocomplete
                    disableClearable
                    options={languageOptions.filter(
                      (option) =>
                        !selectedLanguages.some(
                          (lang) =>
                            lang && lang.toLowerCase() === option.toLowerCase()
                        )
                    )}
                    getOptionLabel={(option) => option}
                    value={language.language}
                    onChange={(e, newValue) =>
                      handleLanguageNameChange(index, "language", newValue)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        label="Language"
                        {...params}
                        value={language.language || ""}
                        onChange={(e) =>
                          handleLanguageNameChange(
                            index,
                            "language",
                            e.target.value
                          )
                        }
                      />
                    )}
                  />
                </div>

                <div className="pmBasFields2">
                  <div>
                    <Checkbox
                      checked={language.read}
                      onChange={(e) =>
                        handleLanguageRadioChange(
                          index,
                          "read",
                          e.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>Read</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={language.write}
                      onChange={(e) =>
                        handleLanguageRadioChange(
                          index,
                          "write",
                          e.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>Write</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={language.speak}
                      onChange={(e) =>
                        handleLanguageRadioChange(
                          index,
                          "speak",
                          e.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>Speak</span>
                  </div>

                  <Tooltip title="Delete Language" arrow>
                    <IconButton
                      onClick={() => handleDeleteLanguage(index)}
                      className="PmPersonal_DeleteLang"
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
          {/* Add More Language Button */}
          <div className="add-language-alignment-container">
            {languageArray.length < 5 && (
              <span onClick={handleAddLanguage} className="PmPersonalLangAdd">
                Add Language
              </span>
            )}
          </div>
        </div>
      </div>
      {/* _______________________________    Save & Cancel Buttons   _______________________________ */}
      <div className="createteslayoutmodel6-resume-headline">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={addPersDetail}
        >
          <SaveIcon className="buttonicons" />
          Save
        </Button>
      </div>
    </div>
  );
}

export default PmTab13PersonalDetailModal;
