import React, { useEffect } from 'react';
import axios from 'axios';
import { Authentication } from "./Firebase_Config";
import { RecaptchaVerifier, signInWithPhoneNumber, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Swal from "sweetalert2";

// Show error message with pre-confirmation
function MsgErr_PreCfrm(iMessage) {
  Swal.fire({
    title: iMessage,
    icon: "error",
    position: "center",
    showConfirmButton: true,
    preConfirm: () => {
      return true;
    },
  });
}

// Functions with no message confirmation
function MsgErr_NoCfrm(iMessage) { }
function MsgSucces_PreCfrm(iMessage) { }
function MsgSucess_NoCfrm(iMessage) { }

// Get device name based on user agent
const getDeviceName = (userAgent) => {
  if (/Android/i.test(userAgent)) {
    return "Android Device";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS Device";
  } else if (/Windows/i.test(userAgent)) {
    return "Windows PC";
  } else if (/Mac/i.test(userAgent)) {
    return "Mac";
  } else {
    return "Unknown Device";
  }
};

// Get device information
function getDeviceInfo() {
  console.log("Device entered");
  const userAgent = navigator.userAgent;
  const deviceName = getDeviceName(userAgent);
  return `Device Name: ${deviceName}`;
}

// Get device time zone
function getDeviceTimeZone() {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  } catch (error) {
    console.error("Error getting device time zone:", error);
    return null;
  }
}

// Get UTC time
function getUtcTime() {
  const now = new Date();
  const utcTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // Adjust for time zone offset
  return utcTime;
}

// Get current date and time
function getCurrentDateTime() {
  const currentDateTime = new Date();
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  const formattedDateTime = currentDateTime.toLocaleString(undefined, options);
  return formattedDateTime;
}

// Get location information
async function getLocationInfo() {
  console.log("entered");
  if (navigator.geolocation) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject,{ enableHighAccuracy: true });
      });
      const { latitude, longitude } = position.coords;

      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
      );

      console.log('API Response:', response.data);

      const { address } = response.data;

      // Log the address object to see all available properties
      console.log('Address Details:', address);

      // Try to find a more accurate address field
      const locationInfo = {
        latitude: latitude,
        longitude: longitude,
        pincode: address.postcode,
        locationName:  response.data.display_name
      };

      console.log('Location Info:', locationInfo);

      return locationInfo;
    } catch (error) {
      console.error('Error getting location:', error.message);
      return null;
    }
  } else {
    console.error("Geolocation is not supported by this browser.");
    return null;
  }
}


// Get SQL format timestamp
function getSqlTimestamp() {
  const currentTimestamp = new Date();
  const sqlTimestamp = currentTimestamp
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return sqlTimestamp;
}

// Google sign-in function
function GoogleSignin() {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(Authentication, provider)
      .then((result) => {
        const user = result.user;
        localStorage.setItem("HTES_user_id", user.email);
        resolve(user);
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed to Login",
          icon: "error",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        reject(false);
      });
  });
}

export {
  getDeviceInfo,
  getDeviceTimeZone,
  getUtcTime,
  getCurrentDateTime,
  getSqlTimestamp,
  GoogleSignin,
  MsgErr_PreCfrm,
  MsgErr_NoCfrm,
  MsgSucces_PreCfrm,
  MsgSucess_NoCfrm,
  getLocationInfo,
};
